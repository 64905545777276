// Displays invitation information when the invitation email is unclaimed or
// belongs to a different account

import { useEffect, useReducer, useCallback } from 'react';
import {
  useNavigate,
  Navigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import moment from 'moment';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ErrorIcon from '@mui/icons-material/Error';

import { Option, Some, None } from '../../types/Option';
import { Result } from '../../types/Result';
import { Slice } from '../../types/Slice';
import ErrorResponse from '../../types/ErrorResponse';
import { Invitation } from '../../models/Invitation';
import { ProfileSettings } from '../../models/ProfileSettings';

import { useRequiredAuthenticatedClient } from '../../providers/AuthenticatedClientProvider';

import ApiError from '../../components/ApiError';
import ErrorDialog from '../../components/ErrorDialog';
import GatheringInformation from '../../components/GatheringInformation';
import InvitationExpiring from '../../components/InvitationExpiring';

import { buildLoginUrl, buildLogoutUrl } from '../../config';

type InvitationState =
  | {
      invitationType: 'user';
      gatheringId: number;
    }
  | {
      invitationType: 'unclaimed_email';
      invitation: Invitation;
    }
  | {
      invitationType: 'unclaimed_email_new_account';
      invitation: Invitation;
    }
  | {
      invitationType: 'wrong_account';
      invitation: Invitation;
    };

type InvitationDisplayState = {
  loaded: boolean;
  invitationState: Option<InvitationState>;
  error: Option<ErrorResponse>;
  submitError: Option<ErrorResponse>;
};

type InvitationDisplayAction =
  | {
      type: 'load';
      invitationState: InvitationState;
    }
  | {
      type: 'error';
      error: ErrorResponse;
    }
  | {
      type: 'submitError';
      error: ErrorResponse;
    };

function stateReducer(
  state: InvitationDisplayState,
  action: InvitationDisplayAction,
): InvitationDisplayState {
  switch (action.type) {
    case 'load':
      return {
        loaded: true,
        invitationState: Some(action.invitationState),
        error: None(),
        submitError: None(),
      };
    case 'error':
      return {
        loaded: true,
        invitationState: None(),
        error: Some(action.error),
        submitError: None(),
      };
    case 'submitError':
      return {
        loaded: true,
        invitationState: None(),
        error: None(),
        submitError: Some(action.error),
      };
    default:
      return state;
  }
}

const initialState: InvitationDisplayState = {
  loaded: false,
  invitationState: None(),
  error: None(),
  submitError: None(),
};

const profile: ProfileSettings = {
  locale: 'en-US',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export default function InvitationDisplay() {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const client = useRequiredAuthenticatedClient();
  const { invitationIdOrCode } = useParams() as { invitationIdOrCode: string };
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const loadGathering = useCallback(() => {
    async function load() {
      const response = await client.get_invitation(invitationIdOrCode);

      if (response.ok) {
        // Gathering is for this user
        // This will be redirected to GatheringDisplay
        dispatch({
          type: 'load',
          invitationState: {
            invitationType: 'user',
            gatheringId: response.value.data.gathering_id,
          },
        });
        return;
      }

      if (response.error.status_code === 403) {
        // Invite is for a unclaimed email or another account
        // Call unauth endpoint
        const unauthResponse = await client.get_invitation_unauthed(
          invitationIdOrCode.substring(1, invitationIdOrCode.length),
        );

        if (unauthResponse.ok) {
          if (unauthResponse.value.data.email_available) {
            // Email is unclaimed
            if (searchParams.get('new-account')) {
              // User just created an account with a different email
              dispatch({
                type: 'load',
                invitationState: {
                  invitationType: 'unclaimed_email_new_account',
                  invitation: unauthResponse.value.data,
                },
              });
              return;
            }

            // User already has an account and can claim this email
            dispatch({
              type: 'load',
              invitationState: {
                invitationType: 'unclaimed_email',
                invitation: unauthResponse.value.data,
              },
            });
            return;
          }

          // Email belongs to a different account
          dispatch({
            type: 'load',
            invitationState: {
              invitationType: 'wrong_account',
              invitation: unauthResponse.value.data,
            },
          });
          return;
        }

        dispatch({ type: 'error', error: unauthResponse.error });
        return;
      }

      dispatch({ type: 'error', error: response.error });
    }
    load();
  }, [client, invitationIdOrCode, searchParams]);

  useEffect(() => {
    loadGathering();
  }, [client, invitationIdOrCode, searchParams, loadGathering]);

  if (!state.loaded) {
    return <Typography variant="body1">Loading</Typography>;
  }

  if (state.invitationState.some) {
    if (state.invitationState.value.invitationType === 'user') {
      return (
        <Navigate
          to={`/app/gatherings/${state.invitationState.value.gatheringId}`}
        />
      );
    }

    const { invitation } = state.invitationState.value;

    const handleClaimEmail = () => {
      const sending = { emails: [invitation.email] };

      client
        .save_contacts(sending, invitationIdOrCode.substring(1))
        .then((result: Result<Slice<number>, ErrorResponse>) => {
          if (result.ok) {
            loadGathering();
          } else {
            dispatch({ type: 'error', error: result.error });
          }
        })
        .catch(() => {
          dispatch({
            type: 'error',
            error: { status_code: 0, message: 'An unknown error has occurred' },
          });
        });
    };

    if (
      state.invitationState.value.invitationType ===
      'unclaimed_email_new_account'
    ) {
      return (
        <>
          <ErrorDialog
            open={state.submitError.some}
            code={
              state.submitError.some && state.submitError.value.status_code
                ? state.submitError.value.status_code
                : 0
            }
          />
          <Typography variant="h5">You&apos;re invited!</Typography>
          <Box sx={{ mt: 2, mb: 2 }}>
            <GatheringInformation
              variant="pending_invitation"
              title={invitation.title}
              date={moment(invitation.start_time)}
              endDate={invitation.end_time ? moment(invitation.end_time) : null}
              place={invitation.gathering_location}
              description={invitation.description}
              hostName={`${invitation.host_first_name} ${invitation.host_last_name}`}
              profileSettings={profile}
              gatheringStatus={invitation.guest_constraints_satisfied}
              cancelled={invitation.cancelled}
              cancellationNote={invitation.cancellation_note}
            />
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Stack spacing={1}>
            <InvitationExpiring
              createdAt={invitation.created_at}
              inviteDuration={invitation.invite_duration_minutes}
            />
            <Typography variant="h5" component="div">
              Add this email to your account?
            </Typography>
            <Typography variant="h6">{invitation.email}</Typography>
            <Typography variant="body1">
              We see you created an account with a different email than the one
              this invitation went to.
            </Typography>
            <Typography variant="body1">
              Do you want to associate this email with your new account?
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button onClick={() => handleClaimEmail()}>Claim email</Button>
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(
                    buildLogoutUrl(
                      `/invitations/${invitationIdOrCode.substring(
                        1,
                        invitationIdOrCode.length,
                      )}`,
                    ),
                  )
                }
              >
                Sign out
              </Button>
              <Button variant="outlined" onClick={() => navigate('/')}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </>
      );
    }

    if (state.invitationState.value.invitationType === 'unclaimed_email') {
      // User has an existing account and can claim this email
      return (
        <>
          <ErrorDialog
            open={state.submitError.some}
            code={
              state.submitError.some && state.submitError.value.status_code
                ? state.submitError.value.status_code
                : 0
            }
          />
          <Typography variant="h5">Pending invitation</Typography>
          <Box sx={{ mt: 2, mb: 2 }}>
            <GatheringInformation
              variant="pending_invitation"
              title={invitation.title}
              date={moment(invitation.start_time)}
              endDate={invitation.end_time ? moment(invitation.end_time) : null}
              place={invitation.gathering_location}
              description={invitation.description}
              hostName={`${invitation.host_first_name} ${invitation.host_last_name}`}
              profileSettings={profile}
              gatheringStatus={invitation.guest_constraints_satisfied}
              cancelled={invitation.cancelled}
              cancellationNote={invitation.cancellation_note}
            />
          </Box>
          <Divider sx={{ mb: 2 }} />
          <Stack spacing={1}>
            <InvitationExpiring
              createdAt={invitation.created_at}
              inviteDuration={invitation.invite_duration_minutes}
            />
            <Stack spacing={1} direction="row" alignItems="center">
              <ErrorIcon fontSize="medium" />
              <Typography variant="h5" component="div">
                Is this you?
              </Typography>
            </Stack>
            <Typography variant="h6">{invitation.email}</Typography>
            <Typography variant="body1">
              The invitation was sent to the above address, which is not
              currently associated with your account.
            </Typography>
            <Typography variant="body1">
              Do you want to associate this email with your existing account?
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button onClick={() => handleClaimEmail()}>Claim email</Button>
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(
                    buildLogoutUrl(
                      `/invitations/${invitationIdOrCode.substring(
                        1,
                        invitationIdOrCode.length,
                      )}`,
                    ),
                  )
                }
              >
                Sign out
              </Button>
              <Button variant="outlined" onClick={() => navigate('/')}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </>
      );
    }

    if (state.invitationState.value.invitationType === 'wrong_account') {
      return (
        <>
          <ErrorDialog
            open={state.submitError.some}
            code={
              state.submitError.some && state.submitError.value.status_code
                ? state.submitError.value.status_code
                : 0
            }
          />
          <Stack spacing={1}>
            <Stack spacing={1} direction="row" alignItems="center">
              <ErrorIcon fontSize="medium" />
              <Typography variant="h5" component="div">
                Is this you?
              </Typography>
            </Stack>
            <GatheringInformation
              variant="masked"
              title={invitation.title}
              date={moment(invitation.start_time)}
              endDate={invitation.end_time ? moment(invitation.end_time) : null}
              place={invitation.gathering_location}
              description={invitation.description}
              hostName={`${invitation.host_first_name} ${invitation.host_last_name}`}
              profileSettings={profile}
              gatheringStatus={invitation.guest_constraints_satisfied}
              cancelled={invitation.cancelled}
              cancellationNote={invitation.cancellation_note}
            />
            <InvitationExpiring
              createdAt={invitation.created_at}
              inviteDuration={invitation.invite_duration_minutes}
            />
            <Typography variant="h6">{invitation.email}</Typography>
            <Typography variant="body1">
              The invitation was sent to the above address, which is associated
              with a different account.
            </Typography>
            <Typography variant="body1">
              If this is you, sign out and sign back in with the account with
              which this email is associated.
            </Typography>
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                onClick={() =>
                  navigate(
                    buildLogoutUrl(
                      buildLoginUrl(`/app/invitations/${invitationIdOrCode}`),
                    ),
                  )
                }
              >
                Sign out
              </Button>
              <Button variant="outlined" onClick={() => navigate('/')}>
                Cancel
              </Button>
            </Stack>
          </Stack>
        </>
      );
    }
  }

  if (state.error.some) {
    // REST API did not return 200 status
    return (
      <ApiError
        errorCode={state.error.value.status_code}
        variant="invite"
        nav="home"
      />
    );
  }
  // Should never reach this point
  return <ErrorDialog open code={0} />;
}
