import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

import Button from '../components/Button';

export default function PrivacyPolicy() {
  const navigate = useNavigate();

  return (
    <Container>
      <Stack spacing={1}>
        <Typography variant="h6">Place holder for Privacy policy</Typography>
        <Box>
          <Button
            variant="secondary"
            onClick={() => {
              navigate('/');
            }}
          >
            Home
          </Button>
        </Box>
      </Stack>
    </Container>
  );
}
