export interface SessionState {
  accessToken: string | null;
  accessTokenExpiration: string | null;
  refreshToken: string | null;
  refreshTokenExpiration: string | null;
}

export function getSessionState() {
  // Load session from local storage.
  const state = localStorage.getItem('pesky-session');
  if (state !== null) {
    return JSON.parse(state);
  }

  // Check to see if old access/refresh tokens exist instead.
  const accessToken = localStorage.getItem('pesky-access-token');
  const refreshToken = localStorage.getItem('pesky-refresh-token');
  if (accessToken !== null && refreshToken !== null) {
    return {
      accessToken: localStorage.getItem('pesky-access-token'),
      accessTokenExpiration: null,
      refreshToken: localStorage.getItem('pesky-refresh-token'),
      refresgTokenExpiration: null,
    };
  }

  // Default (empty) session.
  return {
    accessToken: null,
    accessTokenExpiration: null,
    refreshToken: null,
    refresgTokenExpiration: null,
  };
}

export function setSessionState(state: SessionState) {
  localStorage.setItem('pesky-session', JSON.stringify(state));
  localStorage.removeItem('pesky-access-token');
  localStorage.removeItem('pesky-refresh-token');
}
