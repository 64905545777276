import { Container, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import logo from '../assets/logo.svg';

export default function LoadingPage() {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <img
        src={logo}
        className="App-logo"
        alt="logo"
        style={{ marginBottom: '10px' }}
      />
      <Typography variant="h5">Loading..</Typography>
      <CircularProgress />
    </Container>
  );
}
