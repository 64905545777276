import { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';

import MailIcon from '@mui/icons-material/Mail';
import WarningIcon from '@mui/icons-material/Warning';
import VerifiedIcon from '@mui/icons-material/Verified';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { UserEmail } from '../../models/UserProfile';
import EmailInput from '../EmailInput';

type Props = {
  primaryEmail: string | null;
  otherEmails: UserEmail[] | null;
  onChange: (updatedEmails: UserEmail[]) => void;
  onRequestContactVerify: (email: string) => void;
  errorMsg: string;
  successMsg: string;
};

export default function ContactInformationContent({
  primaryEmail,
  otherEmails,
  onChange,
  onRequestContactVerify,
  errorMsg,
  successMsg,
}: Props) {
  const [editing, setEditing] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [requestEmail, setRequestEmail] = useState('');

  return (
    <Stack spacing={2} mr="auto" ml="auto">
      <Typography variant="body1" fontWeight="bold">
        Primary Contact
      </Typography>
      {primaryEmail ? (
        <Typography variant="body1">{primaryEmail}</Typography>
      ) : (
        <Skeleton variant="text" width={256} />
      )}
      <Box mt={2} />
      <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontWeight="bold">
          Alternative Emails
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton onClick={() => setEditing(!editing)}>
          {editing ? <CloseIcon /> : <EditIcon />}
        </IconButton>
      </Stack>
      {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      {successMsg && <Alert severity="success">{successMsg}</Alert>}
      <Collapse in={editing}>
        <Stack direction="row" alignItems="center">
          <EmailInput
            id="new-email"
            label="New Email"
            value={newEmail}
            type="email"
            onChange={(val: string) => setNewEmail(val)}
          />
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              if (otherEmails) {
                onChange([
                  ...otherEmails,
                  {
                    email: newEmail.trim().toLowerCase(),
                    available: true,
                    verified: false,
                  },
                ]);
              } else {
                onChange([
                  { email: newEmail, available: true, verified: false },
                ]);
              }
              setNewEmail('');
            }}
            sx={{ marginBottom: '20px', marginLeft: '10px' }}
          >
            <AddIcon />
          </IconButton>
        </Stack>
      </Collapse>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: { sm: 440 },
          zIndex: 1,
        }}
      >
        <Table>
          <TableBody>
            {!otherEmails ? (
              <TableRow
                key="other-email-Skeleton"
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell scope="row">
                  <MailIcon />
                </TableCell>
                <TableCell scope="row">
                  <Skeleton width={256} />
                </TableCell>
              </TableRow>
            ) : null}

            {otherEmails && otherEmails.length === 0 ? (
              <TableRow
                key="no-alt-email"
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell align="center">
                  <Typography variant="body1" justifyContent="center">
                    No alternative emails
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}

            {otherEmails &&
              otherEmails.map((email) => (
                <>
                  <TableRow
                    key={email.email}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    {!editing && (
                      <TableCell scope="row">
                        <MailIcon />
                      </TableCell>
                    )}
                    <TableCell scope="row">
                      <Typography variant="body1" justifyContent="center">
                        {email.email}
                      </Typography>
                    </TableCell>
                    <TableCell scope="row">
                      {email.verified ? (
                        <Tooltip title="verified">
                          <VerifiedIcon />
                        </Tooltip>
                      ) : (
                        <Tooltip title="unverified">
                          <IconButton
                            sx={{ padding: 0 }}
                            onClick={() => {
                              setRequestEmail(email.email);
                            }}
                          >
                            <WarningIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    {editing && (
                      <TableCell scope="row">
                        <IconButton
                          sx={{ padding: 0 }}
                          onClick={() => {
                            onChange(
                              otherEmails.filter(
                                (user_email) =>
                                  user_email.email !== email.email,
                              ),
                            );
                          }}
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                  {requestEmail === email.email && (
                    <TableRow
                      key={`request_${email.email}`}
                      sx={{
                        '&:last-child td, &:last-child th': {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell scope="row" colSpan={4}>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          spacing={1}
                        >
                          <Typography variant="body1" fontWeight="bold">
                            Request a new verification email for {email.email}
                          </Typography>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setRequestEmail('');
                            }}
                            sx={{ height: '35px' }}
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              onRequestContactVerify(email.email);
                              setRequestEmail('');
                            }}
                            sx={{ height: '35px' }}
                          >
                            Confirm
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}
