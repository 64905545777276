import { MouseEvent, ElementType } from 'react';
import Button from '@mui/material/Button';

type Props = {
  children: JSX.Element | JSX.Element[] | string | string[];
  onClick?: (() => void) | ((evt: MouseEvent) => void) | null;
  variant?: 'primary' | 'secondary';
  component?: ElementType;
  to?: string | null;
};

export default function PeskyButton(props: Props) {
  const { children, onClick, variant, component = 'button', to = null } = props;

  if (onClick) {
    return (
      <Button
        variant={variant === 'primary' ? 'contained' : 'outlined'}
        onClick={onClick}
        component={component}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      variant={variant === 'primary' ? 'contained' : 'outlined'}
      component={component}
      to={to}
    >
      {children}
    </Button>
  );
}

PeskyButton.defaultProps = {
  variant: 'primary',
  component: 'button',
  to: null,
  onClick: null,
};
