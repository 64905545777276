import React, { useState } from 'react';

import {
  Card,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Grid,
  Typography,
  Collapse,
  Stack,
  IconButton,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type Props = {
  inviteDuration: string;
  setDuration: (newDuration: string) => void;
  durationUnit: string;
  setDurationUnit: (newDurationUnite: string) => void;
  finalExpiration: string;
  setFinalExpiration: (newExpiration: string) => void;
  expirationUnit: string;
  setExpirationUnit: (newExpirationUnite: string) => void;
};

export default function DurationPicker({
  inviteDuration,
  setDuration,
  durationUnit,
  setDurationUnit,
  finalExpiration,
  setFinalExpiration,
  expirationUnit,
  setExpirationUnit,
}: Props) {
  const [expanded, setExpanded] = useState(false);

  const maxNumDuration = durationUnit === 'Days' ? 100 : 24;
  const maxNumExpiration = expirationUnit === 'Days' ? 100 : 24;

  const handleDurationChange = (event: SelectChangeEvent) => {
    setDuration(event.target.value as string);
  };

  const handleExpirationChange = (event: SelectChangeEvent) => {
    setFinalExpiration(event.target.value as string);
  };

  const handleDurationUnitChange = (event: SelectChangeEvent) => {
    setDurationUnit(event.target.value as string);
  };

  const handleExpirationUnitChange = (event: SelectChangeEvent) => {
    setExpirationUnit(event.target.value as string);
  };

  return (
    <>
      <Stack direction="row" alignItems="center" sx={{ marginTop: 0 }}>
        <Typography variant="body1" color="primary.main">
          Advanced Invitation Settings
        </Typography>
        <IconButton onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Stack>
      <Collapse in={expanded}>
        <Card sx={{ padding: 2, marginBottom: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Invite Duration</Typography>
              <Typography variant="body1">
                Set the response time a guest has for an invitation. Once this
                duration elapses, the invitation expires, and a new guest will
                be invited.
              </Typography>
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={inviteDuration}
                  label="Duration"
                  onChange={handleDurationChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                >
                  {Array.from({ length: maxNumDuration }, (_, index) => (
                    <MenuItem key={index + 1} value={(index + 1).toString()}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={durationUnit}
                  label="Hours"
                  onChange={handleDurationUnitChange}
                >
                  <MenuItem value="Hours">Hours</MenuItem>
                  <MenuItem value="Days">Days</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Final Invite Expiration</Typography>
              <Typography variant="body1">
                Set a deadline for finalizing the guest list by configuring how
                long before the event the last invitation should expire. No
                further invites will be sent out after this time.
              </Typography>
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Duration</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={finalExpiration}
                  label="Duration"
                  onChange={handleExpirationChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                      },
                    },
                  }}
                >
                  {Array.from({ length: maxNumExpiration }, (_, index) => (
                    <MenuItem key={index + 1} value={(index + 1).toString()}>
                      {index + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} md={3} lg={2}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={expirationUnit}
                  label="Hours"
                  onChange={handleExpirationUnitChange}
                >
                  <MenuItem value="Hours">Hours</MenuItem>
                  <MenuItem value="Days">Days</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Card>
      </Collapse>
    </>
  );
}
