import moment from 'moment-timezone';
import { useState, useCallback, SyntheticEvent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import ErrorResponse from '../types/ErrorResponse';
import { Result } from '../types/Result';
import EmailInput from '../components/EmailInput';
import logo from '../assets/logo.svg';
import { useUnauthenticatedClient } from '../providers/UnauthenticatedClientProvider';
import ErrorDialog from '../components/ErrorDialog';
import { LoginToken } from '../models/LoginToken';
import { Data } from '../types/Data';
import { useSession } from '../contexts/Session';
import PasswordInput, {
  validPasswordDescription,
} from '../components/PasswordInput';

export default function Register() {
  const { setAuthTokens } = useSession();
  const [searchParams] = useSearchParams();
  const redirectUri = searchParams.get('redirect_uri');
  const invitationRid = searchParams.get('invitation_rid');
  const [registerEmail, setRegisterEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState('');
  const [errorCode, setErrorCode] = useState(0);
  const [open, setOpen] = useState(false);
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const client = useUnauthenticatedClient();
  const tz = moment.tz.guess();

  const handleRegister = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      setPending(true);

      client
        .register({
          first_name: firstName.trim(),
          last_name: lastName.trim(),
          primary_email: registerEmail.trim().toLowerCase(),
          password,
          timezone: tz,
        })
        .then((result: Result<any, ErrorResponse>) => {
          if (result.ok) {
            setOpen(true);
            setPending(false);
          } else {
            if (
              result.error.status_code === 502 ||
              result.error.status_code === 504
            ) {
              setErrorCode(result.error.status_code);
            }

            setErrorMsg(result.error?.message || '');
            setPending(false);
          }
        })
        .catch(() => {
          setErrorMsg('An unknown error has occurred');
          setPending(false);
        });
    },
    [
      registerEmail,
      client,
      password,
      firstName,
      lastName,
      setOpen,
      setPending,
      setErrorCode,
      tz,
    ],
  );

  const handleRegisterWithCode = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      setPending(true);

      client
        .register(
          {
            first_name: firstName.trim(),
            last_name: lastName.trim(),
            primary_email: registerEmail.trim().toLowerCase(),
            password,
            timezone: tz,
          },
          invitationRid,
        )
        .then((result: Result<Data<LoginToken>, ErrorResponse>) => {
          if (result.ok) {
            setAuthTokens({
              accessToken: result.value.data.access_token,
              accessTokenExpiration: result.value.data.access_token_expiration,
              refreshToken: result.value.data.refresh_token,
              refreshTokenExpiration:
                result.value.data.refresh_token_expiration,
            });
            if (redirectUri) {
              navigate(redirectUri);
            } else {
              navigate('/app/registration_success');
            }
          } else {
            if (
              result.error.status_code === 502 ||
              result.error.status_code === 504
            ) {
              setErrorCode(result.error.status_code);
            }

            setErrorMsg(result.error?.message || '');
            setPending(false);
          }
        })
        .catch(() => {
          setErrorMsg('An unknown error has occurred');
          setPending(false);
        });
    },
    [
      registerEmail,
      client,
      password,
      firstName,
      lastName,
      setPending,
      setErrorCode,
      tz,
      invitationRid,
      navigate,
      setAuthTokens,
      redirectUri,
    ],
  );

  const isRegisterFormComplete = useCallback(
    () =>
      registerEmail.trim() !== '' &&
      firstName.trim() !== '' &&
      lastName.trim() !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      password === confirmPassword,
    [registerEmail, password, firstName, lastName, confirmPassword],
  );

  const closeDialog = () => {
    setOpen(false);
    if (redirectUri) {
      navigate(redirectUri);
    } else {
      navigate('/');
    }
  };

  const renderRegister = (
    <form
      onSubmit={(event) => {
        if (invitationRid) {
          handleRegisterWithCode(event);
        } else {
          handleRegister(event);
        }
      }}
    >
      <Stack spacing={1}>
        <Button onClick={() => navigate('/')} sx={{ textTransform: 'none' }}>
          <img src={logo} className="App-logo" alt="logo" />
        </Button>
        <Typography variant="h5" component="div">
          Create account
        </Typography>
        {errorMsg !== '' && (
          <Alert severity="error">{errorMsg.toString()}</Alert>
        )}
        <TextField
          fullWidth
          label="First Name"
          value={firstName}
          required
          onChange={(event) => setFirstName(event.target.value)}
          sx={{ paddingBottom: '20px' }}
        />
        <TextField
          fullWidth
          label="Last Name"
          value={lastName}
          required
          onChange={(event) => setLastName(event.target.value)}
          sx={{ paddingBottom: '20px' }}
        />
        <EmailInput
          id="sign-in-email"
          label="Email"
          value={registerEmail}
          type="email"
          onChange={(val: string) => setRegisterEmail(val)}
        />
        {validPasswordDescription}
        <PasswordInput
          id="password"
          label="Password"
          value={password}
          onChange={(val: string) => setPassword(val)}
          required
        />
        <PasswordInput
          id="confirm_password"
          label="Confirm Password"
          value={confirmPassword}
          onChange={(val: string) => setConfirmPassword(val)}
          required
          error_string={
            confirmPassword !== password ? 'Passwords must match' : ''
          }
        />
        {!pending && (
          <Button
            type="submit"
            disabled={!isRegisterFormComplete()}
            variant="contained"
          >
            Submit
          </Button>
        )}
        {pending && <CircularProgress />}
        <Button
          onClick={() => navigate('/auth/sign_in')}
          sx={{ textTransform: 'none' }}
        >
          Already have an account? Sign in
        </Button>
      </Stack>
    </form>
  );

  const renderSuccess = (
    <Dialog
      open={open}
      onClose={(event: object, reason: string) => {
        // don't close if backdrop click
        if (reason === 'backdropClick') {
          return;
        }

        setOpen(false);
      }}
      disableEscapeKeyDown // Prevent closing on Escape key press
    >
      <DialogTitle>Registration Successful</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <img src={logo} className="App-logo" alt="logo" />
          <Typography component="div">
            You&apos;re almost there! We&apos;ve sent an email to the address
            you provided for verification. Please check your inbox, and once
            you&apos;ve verified your email, you can sign in to your account.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant="contained">
          Home
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Container>
      <Container maxWidth="xs" sx={{ mt: 4, mb: 4 }}>
        <ErrorDialog open={errorCode !== 0} code={errorCode} home />
        {renderRegister}
        {renderSuccess}
      </Container>
    </Container>
  );
}
