import * as React from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import { AuthClient } from '../api/AuthClient';
import {
  getSessionState,
  setSessionState,
  SessionState,
} from '../session/SessionState';
import ErrorResponse from '../types/ErrorResponse';
import { Result } from '../types/Result';

export interface SessionType {
  readyToRender: String | null;
  setReadyToRender: React.Dispatch<React.SetStateAction<String | null>>;
  session: SessionState;
  setAuthTokens: ({
    accessToken,
    accessTokenExpiration,
    refreshToken,
    refreshTokenExpiration,
  }: {
    accessToken: string;
    accessTokenExpiration: string;
    refreshToken: string;
    refreshTokenExpiration: string;
  }) => void;
  clearAuthTokens: () => void;
}

export const Session = React.createContext<SessionType>({} as SessionType);

export function SessionProvider({ children }: { children: React.ReactNode }) {
  const sessionState = getSessionState();

  const [readyToRender, setReadyToRender] = React.useState<String | null>('');

  const setAuthTokens = useCallback(
    ({
      accessToken,
      accessTokenExpiration,
      refreshToken,
      refreshTokenExpiration,
    }: {
      accessToken: string;
      accessTokenExpiration: string;
      refreshToken: string;
      refreshTokenExpiration: string;
    }) => {
      setSessionState({
        accessToken,
        accessTokenExpiration,
        refreshToken,
        refreshTokenExpiration,
      });
      if (accessToken) {
        setReadyToRender(accessToken);
      } else {
        setReadyToRender(null);
      }
    },
    [setReadyToRender],
  );

  const clearAuthTokens = useCallback(() => {
    setAuthTokens({
      accessToken: '',
      accessTokenExpiration: '',
      refreshToken: '',
      refreshTokenExpiration: '',
    });
  }, [setAuthTokens]);

  const value = React.useMemo(
    () => ({
      readyToRender,
      setReadyToRender,
      session: sessionState,
      setAuthTokens,
      clearAuthTokens,
    }),
    [
      sessionState,
      readyToRender,
      setReadyToRender,
      setAuthTokens,
      clearAuthTokens,
    ],
  );

  return <Session.Provider value={value}>{children}</Session.Provider>;
}

export function useSession() {
  const sessionContext = React.useContext(Session);
  const { session, readyToRender, setReadyToRender } = sessionContext;
  const navigate = useNavigate();

  // The number of minutes from now that if the token is gonna expire within,
  // we will refresh.
  const timeUntilExpiration = 15;

  const client = React.useMemo(
    () =>
      session.accessToken && session.refreshToken
        ? new AuthClient(session.accessToken, session.refreshToken)
        : null,
    [session],
  );

  // Trigger API to get a new token before token gets expired.
  React.useEffect(() => {
    let isActive = true;

    // check if the session is currently expired, refresh if yes
    if (
      client &&
      (moment(session.accessTokenExpiration) <
        moment().add(timeUntilExpiration, 'minutes') ||
        readyToRender === null)
    ) {
      client.refresh_tokens().then((result: Result<any, ErrorResponse>) => {
        if (isActive) {
          isActive = false;
          if (result.ok) {
            setSessionState({
              accessToken: result.value.access_token,
              accessTokenExpiration: result.value.access_token_expiration,
              refreshToken: result.value.refresh_token,
              refreshTokenExpiration: result.value.refresh_token_expiration,
            });
            setReadyToRender(result.value.access_token);
          } else {
            navigate('/auth/sign_out');
          }
        }
      });
    }

    // Check if we should update the access token every 5 min
    const interval = setInterval(() => {
      // Get new token if and only if existing token is available and if it will
      // expire within 15 min
      if (
        client &&
        moment(session.accessTokenExpiration) <
          moment().add(timeUntilExpiration, 'minutes')
      ) {
        client.refresh_tokens().then((result: Result<any, ErrorResponse>) => {
          if (isActive) {
            isActive = false;
            if (result.ok) {
              setSessionState({
                accessToken: result.value.access_token,
                accessTokenExpiration: result.value.access_token_expiration,
                refreshToken: result.value.refresh_token,
                refreshTokenExpiration: result.value.refresh_token_expiration,
              });
              setReadyToRender(result.value.access_token);
            } else {
              navigate('/auth/sign_out');
            }
          }
        });
      }
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(interval);
      isActive = false;
    };
  }, [client, navigate, session, setReadyToRender, readyToRender]);

  return sessionContext;
}
