import {
  Avatar,
  Alert,
  Box,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';

import moment from 'moment-timezone';

type Props = {
  firstName: string;
  lastName: string;
  timezone: string;
  onChange: ({
    firstName,
    lastName,
    timezone,
  }: {
    firstName: string;
    lastName: string;
    timezone: string;
  }) => void;
  errorMsg: string;
  successMsg: string;
};

export default function ProfileContent({
  firstName,
  lastName,
  timezone,
  onChange,
  errorMsg,
  successMsg,
}: Props) {
  const letter = firstName ? firstName.substring(0, 1) : '';

  // Get a list of all time zone names in the US
  const allTimeZones = moment.tz.zonesForCountry('US');

  const displayDate = moment();
  const localized = displayDate.tz(timezone);
  const dayOfWeek = localized.format('dddd');
  const yearMonthDay = localized.format('MMM D, YYYY');
  const hoursMinutes = localized.format('LT');

  return (
    <Stack spacing={2} mr="auto" ml="auto">
      {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      {successMsg && <Alert severity="success">{successMsg}</Alert>}
      <Typography variant="body1" fontWeight="bold">
        Photo
      </Typography>
      <Box>
        <Avatar
          alt={`${firstName} ${lastName}`}
          sx={{
            width: 75,
            height: 75,
            fontSize: '60px',
            marginLeft: '10px',
          }}
        >
          {letter}
        </Avatar>
      </Box>
      <Typography variant="body1" fontWeight="bold">
        Name
      </Typography>
      <Stack direction="row" spacing={2}>
        {firstName !== null ? (
          <TextField
            variant="outlined"
            size="small"
            label="First"
            value={firstName}
            onChange={(e) => {
              onChange({
                firstName: e.target.value,
                lastName,
                timezone,
              });
            }}
          />
        ) : (
          <Skeleton variant="rounded" width={228} height={40} />
        )}
        {lastName !== null ? (
          <TextField
            variant="outlined"
            size="small"
            label="Last"
            value={lastName}
            onChange={(e) => {
              onChange({
                firstName,
                lastName: e.target.value,
                timezone,
              });
            }}
          />
        ) : (
          <Skeleton variant="rounded" width={228} height={40} />
        )}
      </Stack>
      <Grid container>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Timezone</InputLabel>
            <Select
              labelId="timezone"
              id="timezone"
              value={timezone}
              label="Timezone"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200,
                  },
                },
              }}
              onChange={(e) => {
                onChange({
                  firstName,
                  lastName,
                  timezone: e.target.value,
                });
              }}
            >
              {allTimeZones.map((timeZone) => (
                <MenuItem key={timeZone} value={timeZone}>
                  {timeZone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          container
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: { xs: '10px', md: '0px' } }}
        >
          <time dateTime={displayDate.format()}>
            <Stack direction="row" spacing={1} justifyContent="center">
              <Typography variant="body1" component="div">
                {dayOfWeek}
              </Typography>
              <Typography variant="body1" component="div">
                {yearMonthDay}
              </Typography>
              <Typography variant="body1" component="div">
                {hoursMinutes}
              </Typography>
            </Stack>
          </time>
        </Grid>
      </Grid>
    </Stack>
  );
}
