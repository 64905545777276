import { useState, useCallback } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import InvitationClient from '../api/InvitationClient';

import ErrorResponse from '../types/ErrorResponse';

type Props = {
  invitationId: number | string;
  client: InvitationClient;
  onError: (error: ErrorResponse) => void;
  onSuccess: () => void;
  onCancel?: () => void;
};

export default function DeclineControl({
  invitationId,
  client,
  onError,
  onSuccess,
  onCancel = () => {},
}: Props) {
  const [open, setOpen] = useState(false);
  const [declineReason, setDeclineReason] = useState<string | undefined>();

  const handleClose = useCallback(() => {
    setOpen(false);
    setDeclineReason(undefined);
    onCancel();
  }, [onCancel]);
  const handleDecline = useCallback(() => {
    async function executeDecline() {
      try {
        const reason: string | undefined =
          declineReason === '' ? undefined : declineReason;
        const response = await client.save_invitation_response(
          {
            action: 'Decline',
            reason,
          },
          invitationId,
        );

        setOpen(false);
        if (!response.ok) {
          onError(response.error);
        } else {
          onSuccess();
        }
      } catch {
        setOpen(false);
        onError({ status_code: 0, message: 'Unknown error' });
      }
    }

    executeDecline();
  }, [client, invitationId, onError, onSuccess, declineReason]);

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Decline
      </Button>
      <Dialog
        open={open}
        PaperProps={{
          className: 'decline-dialog-paper',
        }}
      >
        <DialogTitle textAlign="center">
          <div>
            <Icon>warning_icon</Icon>
          </div>
          <div>Confirm Decline</div>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" component="p">
            Hey things happen. If you need to decline, consider letting your
            host know why you can&apos;t make it. You won&apos;t be able to
            re-accept this gathering after declining.
          </Typography>
        </DialogContent>
        <Box textAlign="center" sx={{ padding: '8px 16px 16px' }}>
          <TextField
            color="secondary"
            label="Let the host know why you can't attend"
            variant="outlined"
            InputProps={{
              inputProps: {
                style: {
                  borderRadius: '4px',
                },
              },
            }}
            value={declineReason !== undefined ? declineReason : ''}
            onChange={(e) => setDeclineReason(e.target.value)}
            fullWidth
          />
        </Box>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDecline}>Decline</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeclineControl.defaultProps = {
  onCancel: () => {},
};
