export const morrigateUrl =
  process.env.REACT_APP_MORRIGATE_URL || 'https://submit.bitwise.io';

// `redirectUri` is a relative path
export function buildLogoutUrl(redirectUri?: string): string {
  if (redirectUri) {
    return `/auth/sign_out?redirect_uri=${redirectUri}`;
  }
  return '/auth/sign_out';
}

// `redirectUri` is a relative path
export function buildLoginUrl(redirectUri?: string): string {
  if (redirectUri) {
    return `/auth/sign_in?redirect_uri=${redirectUri}`;
  }
  return '/auth/sign_in';
}

// `redirectUri` is a relative path
export function buildRegisterUrl(
  redirectUri?: string,
  invitationRid?: string,
): string {
  let registerUrl = '/auth/register';

  if (redirectUri) {
    registerUrl = `${registerUrl}?redirect_uri=${redirectUri}`;

    // we should only ever have an invitaitonRid if we have a redirect Uri
    if (invitationRid) {
      registerUrl = `${registerUrl}&invitation_rid=${invitationRid}`;
    }
  }

  return registerUrl;
}
