import moment from 'moment';

import {
  DisplayGathering,
  GatheringInvitation,
  GroupedGatherings,
  isGatheringInvitation,
} from './state';

const PLACEHOLDER_COUNT: number = 10;

export function groupGatherings(
  gatheringInvites: GatheringInvitation[],
  mode: 'future' | 'past',
): GroupedGatherings[] {
  const groupedGatherings: GroupedGatherings[] = [];
  let cur: GroupedGatherings = { heading: '', gatherings: [] };
  if (mode === 'future') {
    // Group upcoming gatherings and invitations under headers
    // Groups:
    //   Today
    //   Tomorrow
    //   Weekday - each day with gathering for next 2-5 days
    //   Month Year - each month with gathering for remaining gatherings
    //
    cur = { heading: 'Today', gatherings: [] };
    let i = 0;
    // Gatherings today
    while (i < gatheringInvites.length) {
      if (
        moment(gatheringInvites[i].sortIndex)
          .endOf('day')
          .format('MMMDDYYYY') === moment().endOf('day').format('MMMDDYYYY')
      ) {
        // Gathering is today
        cur.gatherings.push(gatheringInvites[i]);
        i += 1;
      } else {
        if (cur.gatherings.length > 0) {
          groupedGatherings.push(cur);
        }
        break;
      }

      // if we have reached the end of the gatherings add current group
      if (i === gatheringInvites.length) {
        groupedGatherings.push(cur);
      }
    }
    // Gatherings tomorrow
    cur = { heading: 'Tomorrow', gatherings: [] };
    while (i < gatheringInvites.length) {
      if (
        moment(gatheringInvites[i].sortIndex)
          .endOf('day')
          .format('MMMDDYYYY') ===
        moment().add(1, 'day').endOf('day').format('MMMDDYYYY')
      ) {
        cur.gatherings.push(gatheringInvites[i]);
        i += 1;
      } else {
        if (cur.gatherings.length > 0) {
          groupedGatherings.push(cur);
        }
        break;
      }

      // if we have reached the end of the gatherings add current group
      if (i === gatheringInvites.length) {
        groupedGatherings.push(cur);
      }
    }
    // Gatherings in the next 2 to 5 days
    let j: number;
    for (j = 2; j <= 5; j += 1) {
      // Check each of the 2nd to 5th day
      const curDay = moment().add(j, 'day').endOf('day');
      cur = {
        heading: curDay.format('dddd'),
        gatherings: [],
      };
      while (i < gatheringInvites.length) {
        if (
          moment(gatheringInvites[i].sortIndex)
            .endOf('day')
            .format('MMMDDYYYY') === curDay.format('MMMDDYYYY')
        ) {
          // Gathering is on the day being checked
          cur.gatherings.push(gatheringInvites[i]);
          i += 1;
        } else {
          if (cur.gatherings.length > 0) {
            // Add a group if the group has gatherings
            groupedGatherings.push(cur);
          }
          break;
        }

        // if we have reached the end of the gatherings add current group
        if (i === gatheringInvites.length) {
          groupedGatherings.push(cur);
        }
      }
    }
    // Remaining gatherings - group by month and year
    cur = { heading: '', gatherings: [] };
    while (i < gatheringInvites.length) {
      if (cur.heading === '') {
        // Current group has been reset
        // Create a new group
        cur.heading = moment(gatheringInvites[i].sortIndex).format('MMMM YYYY');
      }
      if (
        moment(gatheringInvites[i].sortIndex).format('MMMM YYYY') ===
        cur.heading
      ) {
        // Add to current group if in the same month and year
        cur.gatherings.push(gatheringInvites[i]);
        i += 1;
      } else {
        groupedGatherings.push(cur);
        // Reset current grouped gatherings
        cur = { heading: '', gatherings: [] };
      }

      // if we have reached the end of the gatherings add current group
      if (i === gatheringInvites.length) {
        groupedGatherings.push(cur);
      }
    }
  } else {
    let i = 0;
    cur = { heading: '', gatherings: [] };
    while (i < gatheringInvites.length) {
      if (cur.heading === '') {
        // Current group has been reset
        // Create a new group
        cur.heading = moment(gatheringInvites[i].sortIndex).format('MMMM YYYY');
      }
      if (
        moment(gatheringInvites[i].sortIndex).format('MMMM YYYY') ===
        cur.heading
      ) {
        // Add to current group if in the same month and year
        cur.gatherings.push(gatheringInvites[i]);
        i += 1;
      } else {
        groupedGatherings.push(cur);
        // Reset current grouped gatherings
        cur = { heading: '', gatherings: [] };
      }
      if (i === gatheringInvites.length) {
        groupedGatherings.push(cur);
      }
    }
  }
  return groupedGatherings;
}

export const computeGroupedDisplayValues = (
  groupedGatherings: GroupedGatherings[],
) => {
  const reduction = groupedGatherings.reduce(
    (
      {
        gatherings,
        groups,
        groupCounts,
      }: {
        gatherings: DisplayGathering[];
        groups: string[];
        groupCounts: number[];
      },
      group: GroupedGatherings,
    ) => ({
      gatherings: [...gatherings, ...group.gatherings],
      groups: [...groups, group.heading],
      groupCounts: [...groupCounts, group.gatherings.length],
    }),
    { gatherings: [], groups: [], groupCounts: [] },
  );

  let initialStartIndex: number = reduction.gatherings.findIndex(
    (g) =>
      isGatheringInvitation(g) &&
      moment(g.sortIndex).endOf('day') >= moment().endOf('day'),
  );

  let initialGroupIndex = -1;
  for (let i = 0; i < groupedGatherings.length; i += 1) {
    const group = groupedGatherings[i];
    const groupStartIndex = groupedGatherings
      .slice(0, i)
      .reduce((acc, cur) => acc + cur.gatherings.length, 0);
    const groupEndIndex = groupStartIndex + group.gatherings.length - 1;

    if (
      initialStartIndex >= groupStartIndex &&
      initialStartIndex <= groupEndIndex
    ) {
      initialGroupIndex = i;
      break;
    }
  }

  if (initialStartIndex < 0) {
    initialStartIndex = reduction.gatherings.length;
    initialGroupIndex = reduction.groups.length;
    reduction.gatherings.push('placeholder');
    reduction.groups.push('Upcoming');
    reduction.groupCounts.push(1);
  }

  // placeholder padding
  if (reduction.groupCounts.length > 0) {
    // this place holder count should probably be computed, based on number of
    // items in the last group and the current height of the screen.
    reduction.groupCounts[reduction.groupCounts.length - 1] +=
      PLACEHOLDER_COUNT;
  }

  return { ...reduction, initialStartIndex, initialGroupIndex };
};
