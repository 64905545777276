import { useCallback, useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Card, Container, Grid, Stack, Typography } from '@mui/material/';

import moment from 'moment';

import { useRequiredAuthenticatedClient } from '../../providers/AuthenticatedClientProvider';
import { HostGathering, InvitationGathering } from '../../models/Gathering';
import { ProfileSettings } from '../../models/ProfileSettings';
import { Tag } from '../../models/Tag';
import { Guest } from '../../models/Guest';
import ErrorResponse from '../../types/ErrorResponse';
import { Option, Some, None } from '../../types/Option';
import { Result } from '../../types/Result';

import AcceptedDeclineControl from '../../components/AcceptedDeclineControl';
import ApiError from '../../components/ApiError';
import Button from '../../components/Button';
import DeclineControl from '../../components/DeclineControl';
import ErrorDialog from '../../components/ErrorDialog';
import GatheringInformation from '../../components/GatheringInformation';
import GatheringManager from '../../components/GatheringManager';
import InvitationExpiring from '../../components/InvitationExpiring';

type GatheringState =
  | {
      gatheringType: 'host';
      gathering: HostGathering;
      tags: Tag[];
    }
  | {
      gatheringType: 'guest_pending';
      invitation: InvitationGathering;
    }
  | {
      gatheringType: 'guest_accepted';
      invitation: InvitationGathering;
    }
  | {
      gatheringType: 'guest_declined';
      invitation: InvitationGathering;
    }
  | {
      gatheringType: 'guest_expired';
      invitation: InvitationGathering;
    };

type GatheringDisplayState = {
  loaded: boolean;
  gatheringState: Option<GatheringState>;
  orbit: Guest[];
  error: Option<ErrorResponse>;
  submitError: Option<ErrorResponse>;
};

type GatheringDisplayAction =
  | {
      type: 'load';
      gatheringState: GatheringState;
      orbit: Guest[];
    }
  | {
      type: 'error';
      error: ErrorResponse;
    }
  | {
      type: 'submitError';
      error: ErrorResponse;
    };

function stateReducer(
  state: GatheringDisplayState,
  action: GatheringDisplayAction,
): GatheringDisplayState {
  switch (action.type) {
    case 'load':
      return {
        loaded: true,
        gatheringState: Some(action.gatheringState),
        orbit: action.orbit,
        error: None(),
        submitError: None(),
      };
    case 'error':
      return {
        ...state,
        loaded: true,
        gatheringState: None(),
        error: Some(action.error),
        submitError: None(),
      };
    case 'submitError':
      return {
        ...state,
        loaded: true,
        gatheringState: None(),
        error: None(),
        submitError: Some(action.error),
      };
    default:
      return state;
  }
}

const initialState: GatheringDisplayState = {
  loaded: false,
  gatheringState: None(),
  orbit: [],
  error: None(),
  submitError: None(),
};
const profileSettings: ProfileSettings = {
  locale: 'en-US',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export default function GatheringDisplay() {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const client = useRequiredAuthenticatedClient();
  const { gatheringId } = useParams() as { gatheringId: string };
  const navigate = useNavigate();

  const hostInOrbit = () => {
    if (
      state.gatheringState.some &&
      state.gatheringState.value.gatheringType !== 'host'
    ) {
      const hostId = state.gatheringState.value.invitation.host_id;
      return !state.orbit.some(
        (guest: Guest) => guest.linked_account_id === hostId,
      );
    }
    return false;
  };

  const loadGathering = useCallback(() => {
    async function load() {
      const response = await client.get_gathering(gatheringId);
      if (response.ok) {
        const gathering = response.value.data;
        // Parse the response type
        if (
          'guest_exts_with_tags' in gathering &&
          'pending_invites' in gathering
        ) {
          // If host, fetch user's tags
          const tagResponse = await client.get_tags();
          if (tagResponse.ok) {
            dispatch({
              type: 'load',
              gatheringState: {
                gatheringType: 'host',
                gathering: gathering as HostGathering,
                tags: tagResponse.value.data,
              },
              orbit: [],
            });
          } else {
            dispatch({ type: 'error', error: tagResponse.error });
          }
          return;
        }
        if ('invitation' in gathering && 'host_info' in gathering) {
          const orbitResult = await client.get_guests();
          if (!orbitResult.ok) {
            dispatch({ type: 'error', error: orbitResult.error });
            return;
          }

          // User is guest
          const invitation = gathering as InvitationGathering;
          switch (invitation.invitation.status) {
            case 'Pending':
              dispatch({
                type: 'load',
                gatheringState: {
                  gatheringType: 'guest_pending',
                  invitation,
                },
                orbit: orbitResult.value.data,
              });
              return;
            case 'Accepted':
              dispatch({
                type: 'load',
                gatheringState: {
                  gatheringType: 'guest_accepted',
                  invitation,
                },
                orbit: orbitResult.value.data,
              });
              return;
            case 'Declined':
              dispatch({
                type: 'load',
                gatheringState: {
                  gatheringType: 'guest_declined',
                  invitation,
                },
                orbit: orbitResult.value.data,
              });
              return;
            case 'Expired':
              dispatch({
                type: 'load',
                gatheringState: {
                  gatheringType: 'guest_expired',
                  invitation,
                },
                orbit: orbitResult.value.data,
              });
              return;
            default:
              dispatch({
                type: 'error',
                error: {
                  status_code: 0,
                  message: 'Missing case error. Should never happen.',
                },
              });
              return;
          }
        }

        // Unrecognized type
        dispatch({
          type: 'error',
          error: { status_code: 0, message: 'Unrecognized gathering type' },
        });
        return;
      }
      // REST API error
      dispatch({ type: 'error', error: response.error });
    }
    load();
  }, [client, dispatch, gatheringId]);

  useEffect(() => {
    loadGathering();
  }, [client, dispatch, gatheringId, loadGathering]);

  const submitAcceptResponse = useCallback(() => {
    if (
      state.loaded &&
      state.gatheringState.some &&
      state.gatheringState.value.gatheringType !== 'host'
    ) {
      client
        .save_invitation_response(
          { action: 'Accept', reason: undefined },
          state.gatheringState.value.invitation.invitation.invitation_id,
        )
        .then((result: Result<any, ErrorResponse>) => {
          if (result.ok) {
            // Reload page; should fetch accepted invite
            loadGathering();
          } else {
            dispatch({ type: 'submitError', error: result.error });
          }
        })
        .catch(() => {
          dispatch({
            type: 'submitError',
            error: { status_code: 0, message: 'Unknown error' },
          });
        });
    }
  }, [client, state, loadGathering]);

  const submitAddHost = useCallback(() => {
    if (
      state.loaded &&
      state.gatheringState.some &&
      state.gatheringState.value.gatheringType !== 'host'
    ) {
      client
        .save_new_guest({
          linked_account_id: state.gatheringState.value.invitation.host_id,
          tags: [],
        })
        .then((result: Result<any, ErrorResponse>) => {
          if (result.ok) {
            // Reload page; should fetch accepted invite
            loadGathering();
          } else {
            dispatch({ type: 'submitError', error: result.error });
          }
        })
        .catch(() => {
          dispatch({
            type: 'submitError',
            error: { status_code: 0, message: 'Unknown error' },
          });
        });
    }
  }, [client, state, loadGathering]);

  const AddHostCard = (
    <Card
      sx={{
        px: 2,
        py: 1,
        mb: 2,
        width: 'fit-content',
      }}
    >
      <Grid container>
        <Grid item mr={4}>
          <Stack direction="row" spacing={2} mt={1} mb={1}>
            <Button onClick={submitAddHost}>Add Host to Contacts</Button>
          </Stack>
        </Grid>
        <Grid item>
          <Typography variant="h6">Add the host to your contacts!</Typography>
          <Typography variant="body2">
            Adding this user to your contacts will allow you to invite them to a
            gathering in the future.
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );

  const onDeclineSuccess = useCallback(() => navigate(-1), [navigate]);
  const onDeclineError = (err: ErrorResponse) =>
    dispatch({ type: 'submitError', error: err });

  if (!state.loaded) {
    // Gathering hasn't loaded yet
    return (
      <Container>
        <Typography variant="h5">Loading</Typography>
      </Container>
    );
  }

  if (state.gatheringState.some) {
    // Gathering has successfully loaded

    if (state.gatheringState.value.gatheringType === 'host') {
      return (
        <GatheringManager
          gathering={state.gatheringState.value.gathering}
          profile={profileSettings}
          client={client}
          onCancel={loadGathering}
        />
      );
    }

    const { invitation } = state.gatheringState.value;

    switch (state.gatheringState.value.gatheringType) {
      case 'guest_pending':
        return (
          <>
            <ErrorDialog
              open={state.submitError.some}
              code={
                state.submitError.some ? state.submitError.value.status_code : 0
              }
            />
            {!invitation.cancelled && (
              <Card
                sx={{
                  px: 2,
                  py: 1,
                  mb: 2,
                  width: 'fit-content',
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <InvitationExpiring
                      createdAt={invitation.invitation.created_at}
                      inviteDuration={invitation.invite_duration_minutes}
                    />
                  </Grid>
                  <Grid item mr={4}>
                    <Stack direction="row" spacing={2} mt={1} mb={1}>
                      <Button onClick={submitAcceptResponse}>Accept</Button>
                      <DeclineControl
                        client={client}
                        invitationId={invitation.invitation.invitation_id}
                        onSuccess={onDeclineSuccess}
                        onError={onDeclineError}
                      />
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6">
                      Reply to this invitation soon! After a while, it will
                      expire.
                    </Typography>
                    <Typography variant="body2">
                      We won&apos;t notify the host that you&apos;ve received an
                      invitation unless you accept.
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            )}
            {hostInOrbit() && AddHostCard}
            <GatheringInformation
              variant="pending_invitation"
              title={invitation.title}
              date={moment(invitation.start_time)}
              endDate={invitation.end_time ? moment(invitation.end_time) : null}
              place={invitation.gathering_location}
              description={invitation.description}
              hostName={`${invitation.host_info.first_name} ${invitation.host_info.last_name}`}
              profileSettings={profileSettings}
              gatheringStatus={invitation.guest_constraints_satisfied}
              cancelled={invitation.cancelled}
              cancellationNote={invitation.cancellation_note}
              guests={invitation.invitation.guests}
            />
          </>
        );

      case 'guest_accepted':
        return (
          <>
            <ErrorDialog
              open={state.submitError.some}
              code={
                state.submitError.some ? state.submitError.value.status_code : 0
              }
            />
            <Typography variant="h5">You&apos;ve accepted...</Typography>
            {hostInOrbit() && AddHostCard}
            <GatheringInformation
              variant="accepted_invitation"
              title={invitation.title}
              date={moment(invitation.start_time)}
              endDate={invitation.end_time ? moment(invitation.end_time) : null}
              place={invitation.gathering_location}
              description={invitation.description}
              hostName={`${invitation.host_info.first_name} ${invitation.host_info.last_name}`}
              profileSettings={profileSettings}
              gatheringStatus={invitation.guest_constraints_satisfied}
              cancelled={invitation.cancelled}
              cancellationNote={invitation.cancellation_note}
              guests={invitation.invitation.guests}
            />
            {!invitation.cancelled && (
              <Box textAlign="left">
                <AcceptedDeclineControl
                  client={client}
                  invitationId={invitation.invitation.invitation_id}
                  onSuccess={onDeclineSuccess}
                  onError={onDeclineError}
                />
              </Box>
            )}
          </>
        );

      case 'guest_declined':
        return (
          <>
            <ErrorDialog
              open={state.submitError.some}
              code={
                state.submitError.some ? state.submitError.value.status_code : 0
              }
            />
            <Typography variant="h5">You&apos;ve declined...</Typography>
            {hostInOrbit() && AddHostCard}
            <GatheringInformation
              variant="masked"
              title={invitation.title}
              date={moment(invitation.start_time)}
              endDate={invitation.end_time ? moment(invitation.end_time) : null}
              place={invitation.gathering_location}
              description={invitation.description}
              hostName={`${invitation.host_info.first_name} ${invitation.host_info.last_name}`}
              profileSettings={profileSettings}
              gatheringStatus={invitation.guest_constraints_satisfied}
              cancelled={invitation.cancelled}
              cancellationNote={invitation.cancellation_note}
            />
            <Typography variant="body1">
              You&apos;ve declined this gathering.
            </Typography>
          </>
        );

      case 'guest_expired':
        return (
          <>
            <Typography variant="h5">This invitation has expired</Typography>
            {hostInOrbit() && AddHostCard}
            <GatheringInformation
              variant="masked"
              title={invitation.title}
              date={moment(invitation.start_time)}
              endDate={invitation.end_time ? moment(invitation.end_time) : null}
              place={invitation.gathering_location}
              description={invitation.description}
              hostName={`${invitation.host_info.first_name} ${invitation.host_info.last_name}`}
              profileSettings={profileSettings}
              gatheringStatus={invitation.guest_constraints_satisfied}
              cancelled={invitation.cancelled}
              cancellationNote={invitation.cancellation_note}
            />
          </>
        );

      default:
        // Should never get to this point
        return <ErrorDialog open code={0} />;
    }
  }

  // REST API did not return 200 status
  if (state.error.some) {
    return (
      <ApiError
        errorCode={state.error.value.status_code}
        variant="invite"
        nav="back"
      />
    );
  }

  // Should never get to this point
  return <ErrorDialog open code={0} />;
}
