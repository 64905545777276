import { useState, useCallback, SyntheticEvent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Container,
  CircularProgress,
  Stack,
  Alert,
  Typography,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import ErrorResponse from '../types/ErrorResponse';
import { Result } from '../types/Result';
import { Data } from '../types/Data';
import { useSession } from '../contexts/Session';
import EmailInput from '../components/EmailInput';
import logo from '../assets/logo.svg';
import { useUnauthenticatedClient } from '../providers/UnauthenticatedClientProvider';
import { LoginToken } from '../models/LoginToken';
import ErrorDialog from '../components/ErrorDialog';

export default function SignIn() {
  const { setAuthTokens } = useSession();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const redirectUri = searchParams.get('redirect_uri');
  const [signInEmail, setSignInEmail] = useState<string>(email || '');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [pending, setPending] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorCode, setErrorCode] = useState(0);
  const navigate = useNavigate();
  const client = useUnauthenticatedClient();

  const handleSignIn = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      setPending(true);

      client
        .login({
          primary_email: signInEmail.trim().toLowerCase(),
          password,
        })
        .then((result: Result<Data<LoginToken>, ErrorResponse>) => {
          if (result.ok) {
            setAuthTokens({
              accessToken: result.value.data.access_token,
              accessTokenExpiration: result.value.data.access_token_expiration,
              refreshToken: result.value.data.refresh_token,
              refreshTokenExpiration:
                result.value.data.refresh_token_expiration,
            });

            if (redirectUri) {
              navigate(redirectUri);
            } else {
              navigate('/app');
            }
          } else {
            setErrorMsg(result.error?.message || '');
            setPending(false);

            if (
              result.error.status_code === 403 ||
              result.error.status_code === 401
            ) {
              setErrorMsg('Email or password is incorrect');
            } else if (
              result.error.status_code === 502 ||
              result.error.status_code === 504
            ) {
              setErrorCode(result.error.status_code);
            }
          }
        })
        .catch(() => {
          setErrorMsg('An unknown error has occurred');
        });
    },
    [
      signInEmail,
      redirectUri,
      navigate,
      client,
      password,
      setAuthTokens,
      setPending,
      setErrorCode,
    ],
  );

  const isSignInFormComplete = useCallback(
    () => signInEmail.trim() !== '' && password !== '',
    [signInEmail, password],
  );

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const renderSignIn = (
    <form onSubmit={handleSignIn}>
      <Stack spacing={1}>
        <Button onClick={() => navigate('/')} sx={{ textTransform: 'none' }}>
          <img src={logo} className="App-logo" alt="logo" />
        </Button>

        <Typography variant="h5" component="div">
          Sign In
        </Typography>
        {errorMsg !== '' && (
          <Alert severity="error">{errorMsg.toString()}</Alert>
        )}
        <EmailInput
          id="sign-in-email"
          label="Email"
          value={signInEmail}
          type="email"
          onChange={(val: string) => setSignInEmail(val)}
        />
        <TextField
          fullWidth
          label="Password"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handlePasswordChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={togglePasswordVisibility}>
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Stack direction="row">
          <Box sx={{ flexGrow: 1 }} />
          <Button
            onClick={() => navigate('/auth/forgot_pwd')}
            sx={{ textTransform: 'none' }}
          >
            Forgot your password?
          </Button>
        </Stack>
        {!pending && (
          <Button
            type="submit"
            disabled={!isSignInFormComplete()}
            variant="contained"
          >
            Sign In
          </Button>
        )}
        {pending && <CircularProgress />}
        <Button
          onClick={() => navigate('/auth/register')}
          sx={{ textTransform: 'none' }}
        >
          Don&apos;t have an account? Sign up
        </Button>
      </Stack>
    </form>
  );

  return (
    <Container>
      <Container maxWidth="xs" sx={{ mt: 4, mb: 4 }}>
        <ErrorDialog open={errorCode !== 0} code={errorCode} home />
        {renderSignIn}
      </Container>
    </Container>
  );
}
