import { Invitation } from '../../../models/Invitation';
import { ListedGathering } from '../../../models/ListedGathering';
import ErrorResponse from '../../../types/ErrorResponse';
import { Option, None } from '../../../types/Option';

export type GatheringInvitation =
  | { hosting: true; sortIndex: string; gathering: ListedGathering }
  | { hosting: false; sortIndex: string; invitation: Invitation };

export type GroupedGatherings = {
  heading: string;
  gatherings: GatheringInvitation[];
};

export type DisplayGathering = GatheringInvitation | 'placeholder' | 'blank';

export function isGatheringInvitation(
  gathering: DisplayGathering,
): gathering is GatheringInvitation {
  return (gathering as GatheringInvitation).sortIndex !== undefined;
}

export type FilterKeys =
  | 'pendingGatherings'
  | 'happeningGatherings'
  | 'cancelledGatherings'
  | 'acceptedInvitations'
  | 'declinedInvitations'
  | 'hostingGatherings';

export type FilterToggles = Record<FilterKeys, boolean>;
export type FilterState = {
  search: string;
} & FilterToggles;

export type MyGatheringsState = {
  loaded: boolean;
  error: Option<ErrorResponse>;
  pendingInvitesNum: number;
  gatheringInvites: GatheringInvitation[];
  groupedGatherings: GroupedGatherings[];
  computedGroupedDisplayGatherings: {
    groups: string[];
    groupCounts: number[];
    gatherings: DisplayGathering[];
    initialStartIndex: number;
    initialGroupIndex: number;
  };
  filter: FilterState;
};

export const INITIAL_STATE: MyGatheringsState = {
  loaded: false,
  error: None<ErrorResponse>(),
  pendingInvitesNum: 0,
  gatheringInvites: [],
  groupedGatherings: [],
  computedGroupedDisplayGatherings: {
    groups: [],
    groupCounts: [],
    gatherings: [],
    initialStartIndex: 0,
    initialGroupIndex: 0,
  },
  filter: {
    search: '',
    pendingGatherings: true,
    happeningGatherings: true,
    cancelledGatherings: false,
    acceptedInvitations: true,
    declinedInvitations: false,
    hostingGatherings: true,
  },
};
