import moment from 'moment';

import ErrorResponse from '../../../types/ErrorResponse';
import { Some, None } from '../../../types/Option';
import { Invitation } from '../../../models/Invitation';

import { FilterUpdate, MyHomeAction } from './action';
import futureGroupGatherings from './grouping';
import { FilterState, GatheringInvitation, MyHomeState } from './state';

const sortGatheringsInvitations = (
  a: GatheringInvitation,
  b: GatheringInvitation,
) => {
  if (a.sortIndex < b.sortIndex) {
    return 1;
  }
  if (a.sortIndex > b.sortIndex) {
    return -1;
  }
  return 0;
};

function searchMatch(sourceText: string, search: string): boolean {
  if (search === '') {
    return true;
  }

  return (
    sourceText
      .split(' ')
      .find((part) => part.toLowerCase().startsWith(search.toLowerCase())) !==
    undefined
  );
}

function applyFilter(
  invitationSet: Invitation[],
  filterState: FilterState,
): Invitation[] {
  return invitationSet.filter((invitation) =>
    searchMatch(invitation.title, filterState.search),
  );
}

function updateFilterState(
  filterState: FilterState,
  update: FilterUpdate,
): FilterState {
  return { ...filterState, search: update.search };
}

export default function stateReducer(
  state: MyHomeState,
  action: MyHomeAction,
): MyHomeState {
  switch (action.type) {
    case 'loaded': {
      const invitations = action.invitations.filter(
        (invitation) => invitation.invitation_status === 'Pending',
      );

      const gatheringInvites: GatheringInvitation[] = [];
      action.invitations.forEach((invitation) => {
        if (invitation.invitation_status === 'Accepted') {
          gatheringInvites.push({
            hosting: false,
            sortIndex: invitation.start_time,
            invitation,
          });
        }
      });

      action.gatherings.forEach((gathering) =>
        gatheringInvites.push({
          hosting: true,
          sortIndex: gathering.start_time,
          gathering,
        }),
      );
      gatheringInvites.sort(sortGatheringsInvitations);

      const gatheringInvitesUpcoming = gatheringInvites.filter(
        (g) => moment(g.sortIndex).endOf('day') >= moment().endOf('day'),
      );
      gatheringInvitesUpcoming.reverse();

      const groupedGatherings = futureGroupGatherings(gatheringInvitesUpcoming);

      const nextHostedGathering = groupedGatherings.find((group) =>
        group.gatherings.some(
          (gathering) => gathering.hosting && !gathering.gathering.cancelled,
        ),
      );
      const nextAttendingGathering = groupedGatherings.find((group) =>
        group.gatherings.some(
          (gathering) => !gathering.hosting && !gathering.invitation.cancelled,
        ),
      );

      return {
        ...state,
        loaded: true,
        invitations,
        nextHostedGathering,
        nextAttendingGathering,
        filteredInvitations: invitations,
        error: None<ErrorResponse>(),
      };
    }
    case 'filter': {
      const { invitations, filter } = state;
      const updatedFilter = updateFilterState(filter, action.update);

      const filteredInvitations = applyFilter(invitations, updatedFilter);

      return {
        ...state,
        filter: updatedFilter,
        filteredInvitations,
      };
    }
    case 'error':
      return { ...state, loaded: true, error: Some(action.error) };
    default:
      return { ...state };
  }
}
