import { useNavigate, useParams } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';

import logoLightMode from '../../assets/logo-light-mode.svg';
import { useSession } from '../../contexts/Session';

import { buildLoginUrl } from '../../config';

function VistorGatheringDisplay() {
  const { gatheringId } = useParams() as { gatheringId: string };
  const navigate = useNavigate();
  const { session } = useSession();

  if (session.accessToken) {
    // gathering is for a user
    navigate(`/app/gatherings/${gatheringId}`);
  }

  const renderTopBar = (
    <Toolbar sx={{ backgroundColor: '#E68C40', width: '100%' }}>
      <Box sx={{ flexGrow: 1 }} />
      <Button
        onClick={() => navigate('/')}
        sx={{ ml: 2, textTransform: 'none' }}
      >
        <img src={logoLightMode} className="App-logo" alt="logo" />
        <Box sx={{ mr: 2 }} />
        <Typography
          variant="h5"
          noWrap
          color="white"
          fontFamily={['Concert One', 'sans-serif'].join(',')}
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          Pesky Planet
        </Typography>
      </Button>
      <Box sx={{ flexGrow: 1 }} />
    </Toolbar>
  );

  const renderExplainerBox = (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Box mb={1}>
        <Typography variant="h5">Welcome!</Typography>
      </Box>
      <Typography variant="body1">
        Pesky Planet is the easiest way to get people together. It&apos;s an
        automated platform that organizes gatherings for you so you can get
        together more often and more easily.
      </Typography>
      <Box mt={2}>
        <Typography variant="h5">How it works</Typography>
      </Box>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                height: '32px',
                width: '32px',
              }}
            >
              <Typography variant="h6" color="primary.contrastText">
                1
              </Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="body1">
              You create a gathering with a potential guest list.
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                height: '32px',
                width: '32px',
              }}
            >
              <Typography variant="h6" color="primary.contrastText">
                2
              </Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="body1">
              Pesky Planet organizes it by sending out invitations randomly
              until the gathering has enough guests to proceed.
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                height: '32px',
                width: '32px',
              }}
            >
              <Typography variant="h6" color="primary.contrastText">
                3
              </Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="body1">You all have a great time.</Typography>
          </ListItemText>
        </ListItem>
      </List>
      <Box mt={2} mb={1}>
        <Typography variant="h5">What to do next</Typography>
      </Box>
      <Typography variant="body1">
        <Link href={buildLoginUrl(`/app/gatherings/${gatheringId}`)}>
          Sign in
        </Link>{' '}
        to get started with Pesky Planet! You&apos;ll be able to easily manage
        your invitations and host your own gatherings.
      </Typography>
    </Paper>
  );

  // Invitation landing view
  const renderLanding = (
    <>
      {renderTopBar}
      <Container sx={{ py: 2 }}>
        <Grid container>
          <Grid item xs={12} md={6} pr={1}>
            <Typography variant="h4">Pesky Planet</Typography>
            <Stack spacing={2} sx={{ mt: 2, mb: 2 }}>
              <Typography variant="h6" component="div">
                It looks like you&apos;re trying to access an invitation. Sign
                In to your account.
              </Typography>
              <Box>
                <Button
                  variant="outlined"
                  onClick={() =>
                    navigate(buildLoginUrl(`/app/gatherings/${gatheringId}`))
                  }
                >
                  Sign in
                </Button>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} pl={1}>
            {renderExplainerBox}
          </Grid>
        </Grid>
      </Container>
    </>
  );

  return (
    <>
      {renderLanding}
      );
    </>
  );
}

export default VistorGatheringDisplay;
