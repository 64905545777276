import React from 'react';
import { useLocation, Navigate, useParams } from 'react-router-dom';

import { useSession } from '../contexts/Session';
import UnauthenticatedClient from '../api/UnauthenticatedClient';

interface UnauthenticatedClientType {
  client: UnauthenticatedClient;
}

const UnauthenticatedClientContext =
  React.createContext<UnauthenticatedClientType>({
    client: {} as UnauthenticatedClient,
  });

export function UnauthenticatedClientProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  // Re-route authed users
  const { session } = useSession();
  const { pathname } = useLocation();
  const { invitationCode } = useParams() as { invitationCode: string };
  const { gatheringId } = useParams() as { gatheringId: string };

  const value = React.useMemo(
    () => ({ client: new UnauthenticatedClient() }),
    [],
  );

  if (
    session.accessToken &&
    !(
      pathname.startsWith('/auth/sign_out') ||
      pathname.startsWith('/auth/register_verify')
    )
  ) {
    // If on invitations, route to /app/invitations
    if (pathname.startsWith('/invitations')) {
      return <Navigate to={`/app/invitations/:${invitationCode}`} />;
    }

    // If on invitations, route to /app/gatherings
    if (pathname.startsWith('/gatherings')) {
      return <Navigate to={`/app/gatherings/${gatheringId}`} />;
    }
    // If on any other page, route to /app/
    return <Navigate to="/app/" />;
  }

  return (
    <UnauthenticatedClientContext.Provider value={value}>
      {children}
    </UnauthenticatedClientContext.Provider>
  );
}

export function useUnauthenticatedClient() {
  const { client } = React.useContext(UnauthenticatedClientContext);
  return client;
}
