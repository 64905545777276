export const paletteBase = {
  cadmiumOrange: '#E68C40',
  lightOrange: '#f2ba98',
  snow: '#FBF5F3',
};

export const paletteLightMode = {
  blue: '#154881',
  frenchRaspberry: '#CF2847',
  veridian: '#3F826D',
  // extended colors
  heliotropeGray: '#9E90A2',
};

export const paletteDarkMode = {
  jet: '#333232',
  starCommandBlue: '#197BBD',
  cambridgeBlue: '#9ABCA7',
};

// Placeholder colors
export const definedTagColors = [
  // Saturate
  '#d772a8',
  '#e67a7e',
  '#e68c40',
  '#e3bd2f',
  '#99bf3d',
  '#4595d7',
  '#846dde',
  // Pastel
  '#e8acca',
  '#f1b0b2',
  '#f2ba98',
  '#efd796',
  '#c1d899',
  '#bad4f3',
  '#cdc6f5',
];
