import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useMemo,
} from 'react';

import moment from 'moment';

import { useRequiredAuthenticatedClient } from '../providers/AuthenticatedClientProvider';
import { Option, Some, None } from '../types/Option';
import ErrorResponse from '../types/ErrorResponse';
import { UserAccount } from '../models/Account';

import ErrorDialog from '../components/ErrorDialog';

type AccountContextState = {
  loaded: boolean;
  account: Option<UserAccount>;
  timezone: string;
  error: Option<ErrorResponse>;
};

type AccountContextAction =
  | {
      type: 'loaded';
      account: UserAccount;
    }
  | { type: 'error'; error: ErrorResponse };

function stateReducer(
  state: AccountContextState,
  action: AccountContextAction,
): AccountContextState {
  switch (action.type) {
    case 'loaded':
      moment.tz.setDefault(action.account.timezone);

      return {
        loaded: true,
        account: Some(action.account),
        timezone: action.account.timezone,
        error: None(),
      };
    case 'error':
      return {
        ...state,
        loaded: true,
        error: Some(action.error),
      };
    default:
      return { ...state };
  }
}

export interface AccountType {
  account: Option<UserAccount>;
  timezone: string;
  updateAccount: () => void;
}

export const AccountContext = createContext<AccountType>({
  account: None(),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  updateAccount: () => {},
} as AccountType);

const initialState: AccountContextState = {
  loaded: false,
  account: None(),
  error: None(),
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export function AccountProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const client = useRequiredAuthenticatedClient();

  const getAccount = useCallback(async () => {
    const response = await client.get_account();

    if (response.ok) {
      dispatch({
        type: 'loaded',
        account: response.value.data,
      });
    } else {
      dispatch({
        type: 'error',
        error: response.error,
      });
    }
  }, [client]);

  useEffect(() => {
    getAccount();
  }, [client, getAccount]);

  const value = useMemo(
    () => ({
      account: state.account,
      timezone: state.timezone,
      updateAccount: getAccount,
    }),
    [state, getAccount],
  );

  return (
    <AccountContext.Provider value={value}>
      <ErrorDialog
        open={state.error.some}
        code={
          state.error.some && state.error.value.status_code
            ? state.error.value.status_code
            : 0
        }
      />
      {children}
    </AccountContext.Provider>
  );
}

export function useAccount() {
  const account = useContext(AccountContext);
  return account;
}
