import { useState, useCallback, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Alert,
  Button,
  TextField,
  Container,
  Stack,
  Typography,
  CircularProgress,
  Paper,
  Box,
  Grid,
} from '@mui/material';

import logo from '../assets/logo.svg';
import EmailInput from '../components/EmailInput';
import { morrigateUrl } from '../config';

export default function ContactUs() {
  const [errorMsg, setErrorMsg] = useState('');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [pending, setPending] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const isFormComplete = useCallback(
    () => email.trim() !== '' && name !== '' && message !== '',
    [email, name, message],
  );

  const submitForm = useCallback(
    (event: SyntheticEvent) => {
      const submit = async () => {
        const formData = new URLSearchParams();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('_gotcha', ''); // Assuming this is a form field you want to include

        try {
          const response = await fetch(
            `${morrigateUrl}/submit?submission_type=pesky_contact`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: formData.toString(),
            },
          );
          if (!response.ok) {
            setErrorMsg('Unable to submit, try again later');
          }

          setSuccess(true);
          setPending(false);
        } catch (error) {
          setErrorMsg('Unable to submit');
          setPending(false);
        }
      };

      event.preventDefault();
      setPending(true);
      submit();
    },
    [name, email, message],
  );

  const renderExplainerBox = (
    <Paper variant="outlined" sx={{ p: 2, marginTop: '40px' }}>
      <Box mb={1}>
        <Typography variant="h4">Get in touch with us</Typography>
      </Box>
      <Typography variant="h6" sx={{ marginTop: '30px' }}>
        Do you have a question, encountered an issue, or need to report user?
        Please fill out the form, and a member of our team will promptly get
        back to you.
      </Typography>
      <Typography variant="h6" sx={{ marginTop: '30px' }}>
        We are here to help.
      </Typography>
    </Paper>
  );

  const renderSignIn = (
    <Grid container spacing={2}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: '5px' }}
      >
        <Button onClick={() => navigate('/')} sx={{ textTransform: 'none' }}>
          <img src={logo} className="App-logo" alt="logo" />
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <form onSubmit={submitForm}>
          <Stack spacing={1} maxWidth="xs">
            <Typography variant="h5" component="div">
              Contact Us
            </Typography>
            {errorMsg !== '' && (
              <Alert severity="error">{errorMsg.toString()}</Alert>
            )}
            <TextField
              fullWidth
              label="Name"
              value={name}
              required
              onChange={(event) => setName(event.target.value)}
              sx={{ paddingBottom: '20px' }}
            />
            <EmailInput
              id="sign-in-email"
              label="Email"
              value={email}
              type="email"
              onChange={(val: string) => setEmail(val)}
            />
            <TextField
              fullWidth
              label="Message"
              value={message}
              required
              onChange={(event) => setMessage(event.target.value)}
              sx={{ paddingBottom: '20px' }}
              rows={4}
              multiline
            />
            {!pending && (
              <Button
                type="submit"
                disabled={!isFormComplete()}
                variant="contained"
              >
                Submit
              </Button>
            )}
            {pending && <CircularProgress />}
          </Stack>
        </form>
      </Grid>
      <Grid item xs={12} md={6}>
        {renderExplainerBox}
      </Grid>
    </Grid>
  );

  const successfulySent = (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ marginTop: '10px' }}
    >
      <Button onClick={() => navigate('/')} sx={{ textTransform: 'none' }}>
        <img src={logo} className="App-logo" alt="logo" />
      </Button>
      <Typography variant="h4" sx={{ marginTop: '30px' }}>
        Thank you
      </Typography>
      <Typography variant="h6" sx={{ margin: '30px' }}>
        Submission successfull
      </Typography>
      <Button variant="contained" onClick={() => navigate('/')}>
        Home
      </Button>
    </Stack>
  );

  return (
    <Container>
      <Container sx={{ mt: 4, mb: 4 }}>
        {!success ? renderSignIn : successfulySent}
      </Container>
    </Container>
  );
}
