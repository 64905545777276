import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { useSession } from '../contexts/Session';
import ErrorResponse from '../types/ErrorResponse';
import { Result } from '../types/Result';
import { useAuthenticatedClient } from '../providers/AuthenticatedClientProvider';

import { buildLoginUrl } from '../config';
import logo from '../assets/logo.svg';

export default function SignOut() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { session, clearAuthTokens } = useSession();
  const client = useAuthenticatedClient();
  const [message, setMessage] = useState<string>('Logging out...');

  const redirectUri = searchParams.get('redirect_uri');

  useEffect(() => {
    // remove cached gathering if it exists
    sessionStorage.removeItem('pesky_cached_new_gathering');
    if (session.accessToken) {
      if (client) {
        client
          .logout()
          .then((result: Result<any, ErrorResponse>) => {
            if (result.ok) {
              clearAuthTokens();

              if (redirectUri) {
                navigate(redirectUri);
              }

              setMessage('You have been logged out');
            } else {
              clearAuthTokens();
              setMessage(
                'An unknown error has occurred and you have been logged out.',
              );

              if (result.error.status_code === 401) {
                clearAuthTokens();
                setMessage('You have been logged out');
              }
            }
          })
          .catch(() => {
            clearAuthTokens();
            setMessage(
              'An unknown error has occurred and you have been logged out.',
            );
          });
      } else {
        // no client, so act as if already signed out
        clearAuthTokens();

        if (redirectUri) {
          navigate(redirectUri);
        }

        setMessage('You have been logged out');
      }
    }
  }, [client, clearAuthTokens, navigate, redirectUri, setMessage, session]);

  return (
    <Container maxWidth="xs" sx={{ mt: 4, mb: 4 }}>
      <Stack spacing={1}>
        <Button onClick={() => navigate('/')} sx={{ textTransform: 'none' }}>
          <img src={logo} className="App-logo" alt="logo" />
        </Button>
        <Typography variant="h4" align="center">
          {message}
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          my={2}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button variant="contained" onClick={() => navigate(buildLoginUrl())}>
            Sign IN
          </Button>
          <Button variant="contained" onClick={() => navigate('/')}>
            Home
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}
