import { useState, ChangeEventHandler, FocusEventHandler } from 'react';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Default min length in HTML
const DefaultMinLength = 8;
const DefaultMaxLength = 64;

export type Props = {
  id: string;
  name?: string;
  label: string;
  value: string;
  required?: boolean;
  onChange: (val: string) => void;
  error_string?: string;
};

type FieldState = 'new' | 'edited' | 'edited-empty' | 'edited-invalid';

export const validPasswordDescription = (
  <>
    <Typography variant="body1" component="div">
      Your password must be at least 8 characters long and contain atleast one
      number and special character:
    </Typography>
    <Typography variant="body1" component="div">
      {'\'"-!#$%&()*,./:;?@[]^_`{|}~+<=>.'}
    </Typography>
  </>
);

export default function PasswordInput({
  id,
  name,
  label,
  value,
  required,
  onChange,
  error_string,
}: Props) {
  const [fieldState, setFieldState] = useState<FieldState>('new');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value);
    if (required) {
      setFieldState(e.target.value.trim() === '' ? 'edited-empty' : 'edited');
    } else {
      setFieldState('edited');
    }
  };

  const handleBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (
      e.target.value.trim() !== '' &&
      (DefaultMaxLength < e.target.value.length ||
        e.target.value.length < DefaultMinLength ||
        !e.target.value
          .split('')
          .some((char) => '\'"-!#$%&()*,./:;?@[]^_`{|}~+<=>'.includes(char)) ||
        !e.target.value.split('').some((char) => '0123456789'.includes(char)))
    ) {
      setFieldState('edited-invalid');
    }
  };

  const isError =
    fieldState === 'edited-empty' || fieldState === 'edited-invalid';

  return (
    <TextField
      id={id}
      name={name || id}
      variant="outlined"
      label={label}
      value={value}
      type={showPassword ? 'text' : 'password'}
      required={required}
      onChange={handleChange}
      onBlur={handleBlur}
      error={isError || Boolean(error_string)}
      helperText={
        isError || error_string
          ? `${error_string || `Please enter a valid password.`}`
          : ' '
      }
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={togglePasswordVisibility}>
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onKeyDown={(e: any) => {
        e.stopPropagation();
      }}
    />
  );
}

PasswordInput.defaultProps = {
  required: true,
  name: undefined,
  error_string: undefined,
};
