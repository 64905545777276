import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import moment from 'moment';

import AuthenticatedClient from '../api/AuthenticatedClient';
import { HostGathering } from '../models/Gathering';
import { GatheringGuest } from '../models/GatheringGuest';
import { ProfileSettings } from '../models/ProfileSettings';

import CancelGatheringControl from './CancelGatheringControl';
import GatheringGuestCard from './GatheringGuestCard';
import GatheringInformation from './GatheringInformation';

export type Props = {
  gathering: HostGathering;
  profile: ProfileSettings;
  client: AuthenticatedClient;
  onCancel: () => void;
  disabled?: boolean;
};

export default function GatheringManager({
  gathering,
  profile,
  client,
  onCancel,
  disabled,
}: Props) {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showPotentialsList, setShowPotentialsList] = useState(false);
  const [showDeclineNotes, setShowDeclineNotes] = useState(false);

  // Setup
  const acceptedGuests = gathering.guest_exts_with_tags.filter(
    (guest: GatheringGuest) => guest.invitation_is_accepted,
  );
  const potentialGuests = gathering.guest_exts_with_tags.filter(
    (guest: GatheringGuest) =>
      !guest.invitation_is_accepted && !guest.decline_note,
  );
  const guestsWithDeclineNotes = gathering.guest_exts_with_tags.filter(
    (guest: GatheringGuest) => Boolean(guest.decline_note),
  );

  // Renders

  const renderPotentialGuestsDialog = (
    <Dialog
      onClose={() => setShowPotentialsList(false)}
      open={showPotentialsList}
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
    >
      <IconButton
        aria-label="close"
        onClick={() => setShowPotentialsList(false)}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        {`Potential guests (${potentialGuests.length})`}
      </DialogTitle>
      <Box
        p={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '600px',
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <Grid container my={1}>
          {potentialGuests.map((guest: GatheringGuest) => (
            <Grid item key={guest.gathering_guest_id} mb={1} xs={12}>
              <Box sx={{ position: 'relative' }}>
                <GatheringGuestCard guest={guest} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Dialog>
  );

  const renderDeclineNotesDialog = (
    <Dialog
      onClose={() => setShowDeclineNotes(false)}
      open={showDeclineNotes}
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
    >
      <IconButton
        aria-label="close"
        onClick={() => setShowDeclineNotes(false)}
        sx={{ position: 'absolute', right: 8, top: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>Messages from guests who declined</DialogTitle>
      <Box
        p={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '600px',
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <Grid container my={1}>
          {guestsWithDeclineNotes.map((guest: GatheringGuest) => (
            <Grid item key={guest.gathering_guest_id} mb={1} xs={12}>
              <Box sx={{ position: 'relative' }}>
                <Card>
                  <CardHeader
                    title={`${guest.effective_first_name} ${guest.effective_last_name}`}
                    titleTypographyProps={{ variant: 'h6' }}
                    sx={{ py: 1 }}
                  />
                  <CardContent sx={{ py: 1 }}>
                    <Typography variant="body2">
                      {guest.decline_note}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Dialog>
  );

  // Display gathering information
  return (
    <Stack alignItems="flexStart">
      <Stack direction="row" alignItems="flexStart">
        <GatheringInformation
          variant="host"
          title={gathering.title}
          date={moment(gathering.start_time)}
          endDate={gathering.end_time ? moment(gathering.end_time) : null}
          place={gathering.gathering_location}
          description={gathering.description}
          hostName="" // Not displayed for hosts
          profileSettings={profile}
          gatheringStatus={gathering.guest_constraints_satisfied}
          cancelled={gathering.cancelled}
          cancellationNote={gathering.cancellation_note}
          inviteDurationMin={gathering.invite_duration_minutes}
          finalInviteExpirationMinutes={
            gathering.final_invite_expiration_minutes
          }
        />
        <Box sx={{ flexGrow: 1 }} />
        {!disabled && (
          <Box>
            {!gathering.cancelled && (
              <IconButton
                onClick={() => navigate(`/app/gatherings/${gathering.id}/edit`)}
              >
                <EditIcon style={{ fontSize: 24 }} />
              </IconButton>
            )}
            {!gathering.cancelled && (
              <CancelGatheringControl
                gatheringId={gathering.id}
                gatheringTitle={gathering.title}
                client={client}
                onError={() => {}}
                onSuccess={onCancel}
              />
            )}
          </Box>
        )}
      </Stack>
      <Typography
        variant="h6"
        mt={1}
      >{`Accepted guests (${acceptedGuests.length})`}</Typography>
      <Grid container my={1} sx={{ maxWidth: '600px' }}>
        {acceptedGuests.map((guest: GatheringGuest) => (
          <Grid item key={guest.gathering_guest_id} mb={1} xs={12}>
            <Box sx={{ position: 'relative' }}>
              {!guest.contactable && (
                <PriorityHighIcon
                  color="warning"
                  sx={{ position: 'absolute', fontSize: 16, ml: -0.5, mt: 0.5 }}
                />
              )}
              <Stack direction="row" spacing={2} ml={1.5} alignItems="center">
                {guest.linked_account_id ? (
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <CheckCircleIcon
                        style={{ fontSize: 12 }}
                        color="success"
                        sx={{
                          backgroundColor: theme.palette.background.default,
                          borderRadius: '50%',
                        }}
                      />
                    }
                  >
                    <Avatar
                      alt={`${guest.effective_first_name} ${guest.effective_last_name}`}
                      sx={{ fontSize: 14, height: 24, width: 24 }}
                    >
                      {guest.effective_first_name.substring(0, 1)}
                    </Avatar>
                  </Badge>
                ) : (
                  <Avatar
                    alt={`${guest.effective_first_name} ${guest.effective_last_name}`}
                    sx={{ fontSize: 14, height: 24, width: 24 }}
                  >
                    {guest.effective_first_name.substring(0, 1)}
                  </Avatar>
                )}
                <Typography variant="body1">
                  {`${guest.effective_first_name} ${guest.effective_last_name}`}
                </Typography>
              </Stack>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box>
        {!disabled ? (
          <Button
            onClick={() => setShowPotentialsList(true)}
            sx={{ textTransform: 'None' }}
          >{`Potential guests (${potentialGuests.length})`}</Button>
        ) : (
          <Typography variant="body2">
            {`Potential guests (${potentialGuests.length})`}
          </Typography>
        )}
      </Box>
      {renderPotentialGuestsDialog}
      {!disabled && guestsWithDeclineNotes.length > 0 && (
        <Box>
          <Button
            onClick={() => setShowDeclineNotes(true)}
            sx={{ textTransform: 'None' }}
          >{`Messages from guests who declined (${guestsWithDeclineNotes.length})`}</Button>
        </Box>
      )}
      {renderDeclineNotesDialog}
    </Stack>
  );
}

GatheringManager.defaultProps = {
  disabled: false,
};
