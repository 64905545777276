import React from 'react';

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { paletteBase, paletteLightMode } from './palletes';

export default function LandingThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  let theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: '#fff',
          },
        },
      },
    },
    palette: {
      mode: 'light',
      primary: {
        main: paletteBase.cadmiumOrange,
        light: paletteBase.lightOrange,
        contrastText: paletteBase.snow,
      },
      secondary: {
        main: paletteLightMode.blue,
        contrastText: paletteBase.snow,
      },
      error: {
        main: paletteLightMode.frenchRaspberry,
        contrastText: paletteBase.snow,
      },
      success: {
        main: paletteLightMode.veridian,
      },
      background: {
        default: paletteBase.snow,
      },
    },

    typography: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
  });

  theme = responsiveFontSizes(theme);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      {children}
    </MuiThemeProvider>
  );
}
