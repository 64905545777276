// This component is meant to display a gathering or invitation when there are
// multiple gatherings or invitations on a screen.
// For displaying a gathering or invitation when there is only one on the screen, use
// Gathering.

import moment from 'moment-timezone';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { CardActionArea } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlaceIcon from '@mui/icons-material/Place';

import { ProfileSettings } from '../models/ProfileSettings';
import GatheringStatus from './GatheringStatus';
import GuestConstraintsSatisfied from '../models/GuestConstraintsSatisfied';
import { useAccount } from '../contexts/AccountContext';
import InvitationExpiring from './InvitationExpiring';

export type Props = {
  variant: 'host' | 'pendingGuest' | 'acceptedGuest';
  date: moment.Moment | null | undefined;
  title: string;
  place?: string;
  hostName: string;
  guestCount?: number | undefined;
  gatheringStatus?: GuestConstraintsSatisfied;
  cancelled?: boolean;
  createdAt?: string;
  inviteDuration?: number;
  profileSettings: ProfileSettings;
  onClick?: () => void;
};

export default function GatheringCard(props: Props) {
  const {
    variant,
    date,
    title,
    place = '',
    hostName,
    guestCount,
    gatheringStatus = GuestConstraintsSatisfied.NotMet,
    cancelled = false,
    createdAt,
    inviteDuration,
    profileSettings,
    onClick,
  } = props;

  const { timezone } = useAccount();

  const displayDate = date || moment().add(1, 'day').startOf('hour');
  const localized = displayDate.locale(profileSettings.locale).tz(timezone);
  const dayOfWeek = localized.format('dddd');
  const yearMonthDay = localized.format('MMM D, YYYY');
  const hoursMinutes = localized.format('LT');

  function displayGuestCount() {
    if (guestCount === undefined || variant === 'pendingGuest') {
      return null;
    }

    if (variant === 'host') {
      return (
        <Typography variant="caption">
          {`${guestCount} accepted {guestCount === 1 ? "guest" : "guests"}`}
        </Typography>
      );
    }

    return (
      <Typography variant="caption">
        {`${guestCount} {guestCount === 1 ? "guest" : "guests"}`}
      </Typography>
    );
  }

  return (
    <Card sx={{ width: '100%' }}>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Stack>
            {variant === 'pendingGuest' && (
              <Stack direction="row">
                <Typography variant="h6" component="div">
                  You&apos;re invited!{' '}
                  <span role="img" aria-label="celebrate">
                    🎉
                  </span>
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
                {createdAt && inviteDuration && (
                  <InvitationExpiring
                    createdAt={createdAt}
                    inviteDuration={inviteDuration}
                  />
                )}
              </Stack>
            )}
            <Typography variant="h5" component="div">
              {title}
            </Typography>
            <time dateTime={displayDate.format()}>
              <Stack direction="row" spacing={1}>
                <Typography variant="body1" component="div">
                  {dayOfWeek}
                </Typography>
                <Typography variant="body1" component="div">
                  {yearMonthDay}
                </Typography>
                <Typography variant="body1" component="div">
                  {hoursMinutes}
                </Typography>
              </Stack>
            </time>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 0.5, sm: 4 }}
            >
              <>
                {variant !== 'pendingGuest' && (
                  <GatheringStatus
                    status={gatheringStatus}
                    short
                    cancelled={cancelled}
                  />
                )}
                <Stack direction="row" spacing={0.5}>
                  <AccountCircleIcon fontSize="small" />
                  <Typography variant="body1" component="div" fontWeight="bold">
                    {variant === 'host' ? "You're hosting" : hostName}
                  </Typography>
                </Stack>
                {place && (
                  <Stack
                    direction="row"
                    spacing={0.5}
                    width={{ xs: 256, sm: 500, md: 800, lg: 1100 }}
                  >
                    <PlaceIcon fontSize="small" />
                    <Typography variant="body1" component="div" noWrap>
                      {place}
                    </Typography>
                  </Stack>
                )}
                {displayGuestCount()}
              </>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

GatheringCard.defaultProps = {
  place: '',
  guestCount: undefined,
  gatheringStatus: GuestConstraintsSatisfied.NotMet,
  cancelled: false,
  createdAt: '',
  inviteDuration: 0,
  onClick: () => {},
};

export function GatheringCardPlaceholder() {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">
          <Skeleton width={128} />
        </Typography>
        <Typography variant="h5">
          <Skeleton />
        </Typography>
        <Typography variant="body1" component="div">
          <Skeleton width={256} />
        </Typography>
        <Typography variant="body1" component="div">
          <Skeleton width={256} />
        </Typography>
      </CardContent>
    </Card>
  );
}
