import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Container,
  Stack,
  Alert,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';

import ErrorResponse from '../types/ErrorResponse';
import { Result } from '../types/Result';
import { Data } from '../types/Data';
import { useSession } from '../contexts/Session';
import logo from '../assets/logo.svg';
import { useUnauthenticatedClient } from '../providers/UnauthenticatedClientProvider';
import { useAuthenticatedClient } from '../providers/AuthenticatedClientProvider';
import { LoginToken } from '../models/LoginToken';

export default function RegisterVerify() {
  const { setAuthTokens } = useSession();
  const [pending, setPending] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [title, setTitle] = useState('Verifying Registration');
  const { code } = useParams() as { code: string };
  const navigate = useNavigate();
  const client = useUnauthenticatedClient();
  const authClient = useAuthenticatedClient();

  useEffect(() => {
    async function registerVerify() {
      client
        .register_verify(code)
        .then((result: Result<Data<LoginToken>, ErrorResponse>) => {
          if (result.ok) {
            setAuthTokens({
              accessToken: result.value.data.access_token,
              accessTokenExpiration: result.value.data.access_token_expiration,
              refreshToken: result.value.data.refresh_token,
              refreshTokenExpiration:
                result.value.data.refresh_token_expiration,
            });
            navigate('/app/registration_success');
          } else if (result.error.status_code === 500) {
            setTitle('Something went wrong. Try Again');
            setPending(false);
          } else {
            setTitle(result.error?.message || '');
            setPending(false);
          }
        });
    }
    registerVerify();
  }, [
    client,
    authClient,
    setTitle,
    setErrorMsg,
    code,
    setAuthTokens,
    navigate,
  ]);

  const renderRegistrationSuccess = (
    <Stack
      spacing={1}
      sx={{
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Button onClick={() => navigate('/')} sx={{ textTransform: 'none' }}>
        <img src={logo} className="App-logo" alt="logo" />
      </Button>
      <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
        {title}
      </Typography>
      {errorMsg !== '' && <Alert severity="error">{errorMsg.toString()}</Alert>}
      {pending && <CircularProgress />}
      {!pending && (
        <Button variant="contained" onClick={() => navigate('/')}>
          Home
        </Button>
      )}
    </Stack>
  );

  return (
    <Container>
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        {renderRegistrationSuccess}
      </Container>
    </Container>
  );
}
