import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useReducer,
  useState,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { GroupedVirtuoso } from 'react-virtuoso';

import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Stack,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import GroupAdd from '@mui/icons-material/GroupAdd';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';

import moment from 'moment';

import { useRequiredAuthenticatedClient } from '../../../providers/AuthenticatedClientProvider';
import { ProfileSettings } from '../../../models/ProfileSettings';

import GatheringCard, {
  GatheringCardPlaceholder,
} from '../../../components/GatheringCard';
import ErrorDialog from '../../../components/ErrorDialog';

import { MyGatheringsFilterSearch, MyGatheringsFilterToggle } from './action';
import stateReducer from './reducer';
import { FilterKeys, FilterState, FilterToggles, INITIAL_STATE } from './state';

const profileSettings: ProfileSettings = {
  locale: 'en-US',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

/* eslint-disable react/jsx-props-no-spreading */
const MuiComponents: any = {
  List: React.forwardRef(({ style, children }: any, listRef: any) => (
    <List
      style={{ padding: 0, margin: 0, ...style }}
      disablePadding
      ref={listRef}
      component="div"
    >
      {children}
    </List>
  )),

  Item: ({ children, ...props }: any) => (
    <ListItem
      {...props}
      style={{ margin: 0, paddingLeft: 0, paddingRight: 0 }}
      component="div"
    >
      {children}
    </ListItem>
  ),

  Group: ({ children, style, ...props }: any) => {
    const theme = useTheme();
    return (
      <ListSubheader
        {...props}
        style={{
          ...style,
          margin: 0,
          background: theme.palette.background.default,
        }}
        component="div"
      >
        {children}
      </ListSubheader>
    );
  },

  ScrollSeekPlaceholder: () => <GatheringCardPlaceholder />,
};
/* eslint-enable react/jsx-props-no-spreading */

function MyGatherings() {
  const navigate = useNavigate();
  const client = useRequiredAuthenticatedClient();
  const [state, dispatch] = useReducer(stateReducer, INITIAL_STATE);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const filterOpen = Boolean(anchorEl);
  const theme = useTheme();
  const virtuoso = useRef<any>(null);

  const { filter } = state;

  useEffect(() => {
    async function load() {
      const invitations = await client.get_invitations();

      if (!invitations.ok) {
        dispatch({ type: 'error', error: invitations.error });
        return;
      }

      const gatherings = await client.get_gatherings();
      if (!gatherings.ok) {
        dispatch({ type: 'error', error: gatherings.error });
        return;
      }

      dispatch({
        type: 'loaded',
        invitations: invitations.value.data,
        gatherings: gatherings.value.data,
      });
    }
    load();
  }, [client, dispatch]);

  const loadPastValues = useCallback(() => {
    async function load() {
      // placeholder for loading additional past values
      dispatch({
        type: 'past-loaded',
        invitations: [],
        gatherings: [],
      });
    }
    load();
  }, [dispatch]);

  const loadFutureValues = useCallback(() => {
    async function load() {
      // placeholder for loading additional future values
      dispatch({
        type: 'future-loaded',
        invitations: [],
        gatherings: [],
      });
    }
    load();
  }, [dispatch]);

  const {
    groups,
    groupCounts,
    gatherings,
    initialStartIndex,
    initialGroupIndex,
  } = state.computedGroupedDisplayGatherings;
  const groupContent = useCallback(
    (index: number) => (
      <Typography key={index} variant="h5">
        {groups[index]}
      </Typography>
    ),
    [groups],
  );

  const PendingInvitesCard = (
    <Card
      sx={{
        px: 2,
        py: 1,
        mb: 2,
        width: '100%',
        background: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        cursor: 'pointer',
      }}
      onClick={() => navigate('/app/home')}
    >
      <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Grid item>
          <Typography variant="h6">You have pending invitations</Typography>
        </Grid>
      </Grid>
    </Card>
  );

  const itemContent = useCallback(
    (index: number) => {
      const gatheringInvite = gatherings[index];
      if (!gatheringInvite || gatheringInvite === 'blank') {
        // this is a blank spacer, to enable content to be pushed to the top in
        // the scroll window.
        return <div style={{ width: '100%', height: 110 }} />;
      }

      if (gatheringInvite === 'placeholder') {
        return (
          <Box mx="auto">
            <Stack alignItems="center">
              <Typography variant="h6">
                You have no upcoming gatherings.
              </Typography>
              <Button
                onClick={() => navigate('/app/gatherings/add')}
                size="small"
                sx={{ textTransform: 'none' }}
              >
                <Typography variant="body1">Host your own!</Typography>
              </Button>
            </Stack>
          </Box>
        );
      }

      if (gatheringInvite.hosting) {
        return (
          <GatheringCard
            variant="host"
            key={gatheringInvite.gathering.id}
            title={gatheringInvite.gathering.title}
            date={moment(gatheringInvite.gathering.start_time)}
            place={gatheringInvite.gathering.gathering_location}
            hostName={`${gatheringInvite.gathering.host_first_name} ${gatheringInvite.gathering.host_last_name}`}
            gatheringStatus={
              gatheringInvite.gathering.guest_constraints_satisfied
            }
            cancelled={gatheringInvite.gathering.cancelled}
            profileSettings={profileSettings}
            onClick={() =>
              navigate(`/app/gatherings/${gatheringInvite.gathering.id}`)
            }
          />
        );
      }
      return (
        <GatheringCard
          variant={
            gatheringInvite.invitation.invitation_status === 'Pending'
              ? 'pendingGuest'
              : 'acceptedGuest'
          }
          key={gatheringInvite.invitation.id}
          title={gatheringInvite.invitation.title}
          date={moment(gatheringInvite.invitation.start_time)}
          place={gatheringInvite.invitation.gathering_location}
          hostName={`${gatheringInvite.invitation.host_first_name} ${gatheringInvite.invitation.host_last_name}`}
          gatheringStatus={
            gatheringInvite.invitation.guest_constraints_satisfied
          }
          cancelled={gatheringInvite.invitation.cancelled}
          createdAt={
            gatheringInvite.invitation.invitation_status === 'Pending'
              ? gatheringInvite.invitation.created_at
              : undefined
          }
          inviteDuration={
            gatheringInvite.invitation.invitation_status === 'Pending'
              ? gatheringInvite.invitation.invite_duration_minutes
              : undefined
          }
          profileSettings={profileSettings}
          onClick={() =>
            navigate(
              `/app/gatherings/${gatheringInvite.invitation.gathering_id}`,
            )
          }
        />
      );
    },
    [navigate, gatherings],
  );

  if (!state.loaded) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" component="div">
              Your Gatherings
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip title="Add Gathering">
              <span>
                <IconButton aria-label="Add Gathering" color="primary" disabled>
                  <GroupAdd style={{ fontSize: 32 }} />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <TextField
              hiddenLabel
              fullWidth
              variant="outlined"
              placeholder="Search"
              value={filter.search}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              disabled
            />

            <IconButton color="primary" disabled>
              <TuneIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box component="div" height="100%" style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Stack spacing={2}>
                {[0, 1, 2, 3, 4].map((n) => (
                  <GatheringCardPlaceholder key={n} />
                ))}
              </Stack>
            </div>
            <Skeleton
              variant="rounded"
              width={150}
              height={400}
              sx={{ display: { xs: 'none', sm: 'block' } }}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }

  if (state.error.some) {
    return (
      <ErrorDialog
        open={state.error.some}
        code={
          state.error.some && state.error.value.status_code
            ? state.error.value.status_code
            : 0
        }
      />
    );
  }

  const handleFilterMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterMenuClose = () => {
    setAnchorEl(null);
  };

  const filterChanged = (filterState: FilterState): boolean =>
    // all the keys that should be true
    !(
      [
        'pendingGatherings',
        'happeningGatherings',
        'hostingGatherings',
        'acceptedInvitations',
      ] as FilterKeys[]
    ).reduce((acc, k) => acc && filterState[k], true) ||
    // all the keys that should be false
    (['cancelledGatherings', 'declinedInvitations'] as FilterKeys[]).reduce(
      (acc, k) => acc || filterState[k],
      false,
    );

  const activeFilterMessage = (filterState: FilterState): React.ReactNode => {
    let gatheringState = '';
    if (
      filterState.pendingGatherings ||
      filterState.happeningGatherings ||
      filterState.cancelledGatherings
    ) {
      const states = [];
      if (filterState.pendingGatherings) {
        states.push('pending');
      }
      if (filterState.happeningGatherings) {
        states.push('happening');
      }
      if (filterState.cancelledGatherings) {
        states.push('cancelled');
      }
      const last = states.pop();
      let tail = states.join(', ');
      if (states.length > 1) {
        tail += ', or ';
      } else if (states.length === 1) {
        tail += ' or ';
      }
      tail += last;
      gatheringState = ` that are ${tail}`;
    }
    let gatheringOwnership = '';
    if (
      filterState.hostingGatherings ||
      filterState.acceptedInvitations ||
      filterState.declinedInvitations
    ) {
      const states = [];
      if (filterState.hostingGatherings) {
        states.push('are hosting');
      }
      if (filterState.acceptedInvitations) {
        states.push('have accepted');
      }
      if (filterState.declinedInvitations) {
        states.push('have declined');
      }
      const last = states.pop();
      let tail = states.join(', ');
      if (states.length > 1) {
        tail += ', or ';
      } else if (states.length === 1) {
        tail += ' or ';
      }
      tail += last;
      gatheringOwnership = ` that you ${tail}`;
    }

    if (!gatheringState && !gatheringOwnership) {
      return (
        <Typography variant="body1">
          You&apos;ve filtered out all gatherings.
        </Typography>
      );
    }

    return (
      <Typography variant="body1">
        {`You're viewing gatherings${gatheringState}${
          gatheringState && gatheringOwnership ? ' and ' : ''
        }${gatheringOwnership}.`}
      </Typography>
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        {state.pendingInvitesNum > 0 && (
          <Grid item xs={12}>
            {PendingInvitesCard}
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" component="div">
              Your Gatherings
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip title="Add Gathering">
              <IconButton
                aria-label="Add Gathering"
                onClick={() => navigate('/app/gatherings/add')}
                color="primary"
              >
                <GroupAdd style={{ fontSize: 32 }} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <TextField
              hiddenLabel
              fullWidth
              value={filter.search}
              variant="outlined"
              placeholder="Search"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                dispatch(MyGatheringsFilterSearch(e.currentTarget.value))
              }
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
            <IconButton color="primary" onClick={handleFilterMenuOpen}>
              <TuneIcon />
            </IconButton>
            <Menu
              id="filter-menu"
              anchorEl={anchorEl}
              open={filterOpen}
              onClose={handleFilterMenuClose}
            >
              {(
                [
                  'Gatherings that are ...',
                  ['pendingGatherings', 'Pending'],
                  ['happeningGatherings', 'Happening'],
                  ['cancelledGatherings', 'Cancelled'],
                  'divider',
                  'Gatherings that I ...',
                  ['hostingGatherings', ' am Hosting'],
                  ['acceptedInvitations', 'have Accepted'],
                  ['declinedInvitations', 'have Declined'],
                ] as ([key: string, label: string] | 'divider' | string)[]
              ).map(
                (item: [key: string, label: string] | 'divider' | string) => {
                  if (item === 'divider') {
                    return (
                      <div key="divider">
                        <Divider />
                      </div>
                    );
                  }

                  if (typeof item === 'string') {
                    return (
                      <MenuItem key={item} disableRipple>
                        <Typography variant="body1">{item}</Typography>
                      </MenuItem>
                    );
                  }

                  const [key, label] = item;
                  return (
                    <MenuItem
                      key={key}
                      onClick={() =>
                        dispatch(
                          MyGatheringsFilterToggle({
                            [key]: !(filter as any)[key],
                          }),
                        )
                      }
                    >
                      <ListItem component="div">{label}</ListItem>
                      <Checkbox
                        checked={(filter as any)[key]}
                        sx={{ p: { xs: 1, md: 0 } }}
                      />
                    </MenuItem>
                  );
                },
              )}
            </Menu>
          </Stack>
        </Grid>
        {filterChanged(filter) ? (
          <Grid item xs={12}>
            {activeFilterMessage(filter)}
            <Button
              onClick={() =>
                dispatch(
                  MyGatheringsFilterToggle(
                    INITIAL_STATE.filter as FilterToggles,
                  ),
                )
              }
            >
              Reset Filter
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Box component="div" height="100%" style={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <GroupedVirtuoso
            style={{ height: '100%' }}
            ref={virtuoso}
            groupCounts={groupCounts}
            initialTopMostItemIndex={initialStartIndex}
            components={MuiComponents}
            groupContent={groupContent}
            itemContent={itemContent}
            startReached={loadPastValues}
            endReached={loadFutureValues}
          />
        </div>
        <List
          sx={{
            marginTop: '20px',
            maxHeight: '400px',
            overflow: 'auto',
            display: { xs: 'none', sm: 'block' },
          }}
        >
          {groupCounts
            .reduce(
              (
                {
                  firstItemsIndexes,
                  offset,
                }: { firstItemsIndexes: number[]; offset: number },
                count,
              ) => ({
                firstItemsIndexes: [...firstItemsIndexes, offset],
                offset: offset + count,
              }),
              { firstItemsIndexes: [], offset: 0 },
            )
            .firstItemsIndexes.map((itemIndex, index) => (
              // todo add keys
              <ListItemButton
                key={groups[index]}
                sx={{
                  paddingBottom: '0px',
                  textTransform: 'none',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
                autoFocus={index === initialGroupIndex}
                onClick={(e) => {
                  e.preventDefault();
                  if (virtuoso.current) {
                    virtuoso.current.scrollToIndex({
                      index: itemIndex,
                    });
                  }
                }}
                style={{
                  textTransform: 'none',
                  width: '100%',
                  justifyContent: 'flex-start',
                }}
              >
                <ListItemText primary={groups[index]} />
              </ListItemButton>
            ))}
        </List>
      </Box>
    </>
  );
}

export default MyGatherings;
