import { useCallback, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';

import { useUnauthenticatedClient } from '../../providers/UnauthenticatedClientProvider';
import ErrorResponse from '../../types/ErrorResponse';
import { Option, Some, None } from '../../types/Option';
import { Result } from '../../types/Result';
import { Invitation } from '../../models/Invitation';
import { ProfileSettings } from '../../models/ProfileSettings';

import logoLightMode from '../../assets/logo-light-mode.svg';
import AcceptedDeclineControl from '../../components/AcceptedDeclineControl';
import ApiError from '../../components/ApiError';
import DeclineControl from '../../components/DeclineControl';
import GatheringInformation from '../../components/GatheringInformation';
import InvitationExpiring from '../../components/InvitationExpiring';

import { buildLoginUrl, buildRegisterUrl } from '../../config';

const profileSettings: ProfileSettings = {
  locale: 'en-US',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

type InvitationState =
  | { type: 'pending'; invitation: Invitation }
  | { type: 'accepted'; invitation: Invitation }
  | { type: 'declined'; invitation: Invitation };

type VisitorInvState = {
  loaded: boolean;
  invitationState: Option<InvitationState>;
  error: Option<ErrorResponse>;
  submitError: Option<ErrorResponse>;
};

type VisitorDisplayAction =
  | { type: 'load'; invitationState: InvitationState }
  | { type: 'error'; error: ErrorResponse }
  | { type: 'submitError'; error: ErrorResponse };

function stateReducer(
  state: VisitorInvState,
  action: VisitorDisplayAction,
): VisitorInvState {
  switch (action.type) {
    case 'load':
      return {
        loaded: true,
        invitationState: Some(action.invitationState),
        error: None(),
        submitError: None(),
      };
    case 'error':
      return {
        loaded: true,
        invitationState: None(),
        error: Some(action.error),
        submitError: None(),
      };
    case 'submitError':
      return {
        loaded: true,
        invitationState: None(),
        error: None(),
        submitError: Some(action.error),
      };
    default:
      return state;
  }
}

const initialState: VisitorInvState = {
  loaded: false,
  invitationState: None(),
  error: None(),
  submitError: None(),
};

function InvitationDisplay() {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const client = useUnauthenticatedClient();
  const { invitationCode } = useParams() as { invitationCode: string };
  const [showInviteLanding, setShowInviteLanding] = useState<boolean>(true);
  const navigate = useNavigate();

  const loadInvitation = useCallback(() => {
    async function load() {
      const response = await client.get_invitation(invitationCode);
      if (response.ok) {
        const invitation = response.value.data;
        switch (invitation.invitation_status) {
          case 'Pending':
            dispatch({
              type: 'load',
              invitationState: { type: 'pending', invitation },
            });
            return;
          case 'Accepted':
            dispatch({
              type: 'load',
              invitationState: { type: 'accepted', invitation },
            });
            return;
          default:
            dispatch({
              type: 'load',
              invitationState: { type: 'declined', invitation },
            });
            return;
        }
      }
      dispatch({ type: 'error', error: response.error });
    }
    load();
  }, [client, invitationCode]);

  useEffect(() => {
    loadInvitation();
  }, [client, invitationCode, loadInvitation]);

  const submitAcceptResponse = useCallback(() => {
    client
      .save_invitation_response(
        { action: 'Accept', reason: undefined },
        invitationCode,
      )
      .then((result: Result<any, ErrorResponse>) => {
        if (result.ok) {
          // Reload page; should fetch accepted invite
          loadInvitation();
        } else {
          dispatch({ type: 'submitError', error: result.error });
        }
      })
      .catch(() => {
        dispatch({
          type: 'submitError',
          error: { status_code: 0, message: 'Unknown error' },
        });
      });
  }, [client, invitationCode, loadInvitation]);

  const onDeclineError = (err: ErrorResponse) =>
    dispatch({ type: 'submitError', error: err });

  const renderTopBar = (
    <Toolbar sx={{ backgroundColor: '#E68C40', width: '100%' }}>
      <Box sx={{ flexGrow: 1 }} />
      <Button
        onClick={() => navigate('/')}
        sx={{ ml: 2, textTransform: 'none' }}
      >
        <img src={logoLightMode} className="App-logo" alt="logo" />
        <Box sx={{ mr: 2 }} />
        <Typography
          variant="h5"
          noWrap
          color="white"
          fontFamily={['Concert One', 'sans-serif'].join(',')}
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          Pesky Planet
        </Typography>
      </Button>
      <Box sx={{ flexGrow: 1 }} />
    </Toolbar>
  );

  if (!state.loaded) {
    return (
      <Container>
        <Typography variant="h5">Loading</Typography>
      </Container>
    );
  }

  if (state.invitationState.some) {
    const { invitation } = state.invitationState.value;

    const renderExplainerBox = (
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Box mb={1}>
          <Typography variant="h5">Welcome!</Typography>
        </Box>
        <Typography variant="body1">
          Pesky Planet is the easiest way to get people together. It&apos;s an
          automated platform that organizes gatherings for you so you can get
          together more often and more easily.
        </Typography>
        <Box mt={2}>
          <Typography variant="h5">How it works</Typography>
        </Box>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                sx={{
                  backgroundColor: 'primary.main',
                  height: '32px',
                  width: '32px',
                }}
              >
                <Typography variant="h6" color="primary.contrastText">
                  1
                </Typography>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="body1">
                Your host creates a gathering with a potential guest list.
              </Typography>
              {!invitation.cancelled && (
                <Typography variant="body1" fontStyle="italic">
                  They put you on the list!
                </Typography>
              )}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                sx={{
                  backgroundColor: 'primary.main',
                  height: '32px',
                  width: '32px',
                }}
              >
                <Typography variant="h6" color="primary.contrastText">
                  2
                </Typography>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="body1">
                Pesky Planet organizes it by sending out invitations randomly
                until the gathering has enough guests to proceed.
              </Typography>
              {!invitation.cancelled && (
                <Typography variant="body1" fontStyle="italic">
                  You got an invitation!
                </Typography>
              )}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                sx={{
                  backgroundColor: 'primary.main',
                  height: '32px',
                  width: '32px',
                }}
              >
                <Typography variant="h6" color="primary.contrastText">
                  3
                </Typography>
              </Avatar>
            </ListItemAvatar>
            <ListItemText>
              <Typography variant="body1">
                You all have a great time.
              </Typography>
              {!invitation.cancelled && (
                <Typography variant="body1" fontStyle="italic">
                  Of course, you have to accept...
                </Typography>
              )}
            </ListItemText>
          </ListItem>
        </List>
        <Box mt={2} mb={1}>
          <Typography variant="h5">What to do next</Typography>
        </Box>
        <Typography variant="body1">
          <Link
            href={buildRegisterUrl(
              `/app/invitations/:${invitationCode}`,
              invitationCode,
            )}
          >
            Create an account
          </Link>{' '}
          to get started with Pesky Planet! You&apos;ll be able to easily manage
          your invitations and host your own gatherings.
        </Typography>
      </Paper>
    );

    let gatheringVariant:
      | 'pending_invitation'
      | 'accepted_invitation'
      | 'masked' = 'pending_invitation';
    if (state.invitationState.value.type === 'accepted')
      gatheringVariant = 'accepted_invitation';
    if (state.invitationState.value.type === 'declined')
      gatheringVariant = 'masked';

    const renderGathering = (
      <GatheringInformation
        variant={gatheringVariant}
        title={invitation.title}
        date={moment(invitation.start_time)}
        endDate={invitation.end_time ? moment(invitation.end_time) : null}
        place={invitation.gathering_location}
        description={invitation.description}
        hostName={`${invitation.host_first_name} ${invitation.host_last_name}`}
        profileSettings={profileSettings}
        gatheringStatus={invitation.guest_constraints_satisfied}
        cancelled={invitation.cancelled}
        cancellationNote={invitation.cancellation_note}
      />
    );

    const EmailAvailableLanding = (
      <>
        <Typography variant="h6" component="div">
          It looks like you&apos;re trying to access an invitation. Create an
          account to get started!
        </Typography>
        <Box>
          <Button
            variant="contained"
            onClick={() =>
              navigate(
                buildRegisterUrl(
                  `/app/invitations/:${invitationCode}`,
                  invitationCode,
                ),
              )
            }
          >
            Create account
          </Button>
        </Box>
        <Stack spacing={1}>
          <Typography variant="body1" component="div">
            Already have an account?
          </Typography>
          <Box>
            <Button
              variant="outlined"
              onClick={() =>
                navigate(buildLoginUrl(`/app/invitations/:${invitationCode}`))
              }
            >
              Sign in
            </Button>
          </Box>
        </Stack>
        <Stack spacing={1}>
          <Typography variant="body1" component="div">
            Don&apos;t want to create an account?
          </Typography>
          <Box>
            <Button
              variant="outlined"
              onClick={() => setShowInviteLanding(false)}
            >
              Proceed to invitation
            </Button>
          </Box>
        </Stack>
      </>
    );

    const EmailNotAvailableLanding = (
      <>
        <Typography variant="h6" component="div">
          It looks like you&apos;re trying to access an invitation for{' '}
          {invitation.email}. Sign in to the associated account.
        </Typography>
        <Stack spacing={1}>
          <Box>
            <Button
              variant="contained"
              onClick={() =>
                navigate(buildLoginUrl(`/app/invitations/:${invitationCode}`))
              }
            >
              Sign in
            </Button>
          </Box>
        </Stack>
      </>
    );

    // Cancelled invitation view
    if (invitation.cancelled) {
      return (
        <>
          {renderTopBar}
          <Container sx={{ py: 2 }}>
            <Grid container>
              <Grid item xs={12} md={6} pr={1}>
                <Typography variant="h4">Pesky Planet</Typography>
                {renderGathering}
              </Grid>
              <Grid item xs={12} md={6} pl={1}>
                {renderExplainerBox}
              </Grid>
            </Grid>
          </Container>
        </>
      );
    }

    // Invitation landing view
    const renderLanding = (
      <>
        {renderTopBar}
        <Container sx={{ py: 2 }}>
          <Grid container>
            <Grid item xs={12} md={6} pr={1}>
              <Typography variant="h4">Pesky Planet</Typography>
              <Stack spacing={2} sx={{ mt: 2, mb: 2 }}>
                {renderGathering}
                <InvitationExpiring
                  createdAt={invitation.created_at}
                  inviteDuration={invitation.invite_duration_minutes}
                />
                {invitation.email_available && EmailAvailableLanding}
                {!invitation.email_available && EmailNotAvailableLanding}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} pl={1}>
              {renderExplainerBox}
            </Grid>
          </Grid>
        </Container>
      </>
    );

    switch (state.invitationState.value.type) {
      case 'pending':
        if (showInviteLanding) {
          return renderLanding;
        }
        return (
          <>
            {renderTopBar}
            <Container sx={{ py: 2 }}>
              <Card
                sx={{
                  backgroundColor: 'background.primary',
                  px: 2,
                  py: 1,
                  width: 'fit-content',
                }}
              >
                <Grid container>
                  <Grid item mr={4}>
                    <Stack direction="row" spacing={2} mt={1} mb={1}>
                      <Button
                        variant="contained"
                        onClick={submitAcceptResponse}
                      >
                        Accept
                      </Button>
                      <DeclineControl
                        client={client}
                        invitationId={invitationCode}
                        onSuccess={loadInvitation}
                        onError={onDeclineError}
                      />
                    </Stack>
                  </Grid>
                  <Grid item pl={1}>
                    <Typography variant="h6">
                      Reply to this invitation soon! After a while, it will
                      expire.
                    </Typography>
                    <Typography variant="body2">
                      We won&apos;t notify the host that you&apos;ve received an
                      invitation unless you accept.
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
              <Box sx={{ mt: 2, mb: 2 }}>{renderGathering}</Box>
              <Typography>
                Want to more easily manage this invitation?{' '}
                <Link
                  href={buildRegisterUrl(
                    `/app/invitations/:${invitationCode}`,
                    invitationCode,
                  )}
                >
                  Create an account!
                </Link>
              </Typography>
            </Container>
          </>
        );
      case 'accepted':
        if (showInviteLanding) {
          return renderLanding;
        }
        return (
          <>
            {renderTopBar}
            <Container sx={{ py: 2 }}>
              <Typography variant="h4">Pesky Planet</Typography>
              <Typography variant="h5">You&apos;ve accepted...</Typography>
              <Box sx={{ mt: 2, mb: 2 }}>{renderGathering}</Box>
              <Box textAlign="left">
                <AcceptedDeclineControl
                  client={client}
                  invitationId={invitationCode}
                  onSuccess={loadInvitation}
                  onError={onDeclineError}
                />
              </Box>
            </Container>
          </>
        );
      default:
        if (showInviteLanding) {
          return renderLanding;
        }
        return (
          <>
            {renderTopBar}
            <Container sx={{ py: 2 }}>
              <Typography variant="h4">Pesky Planet</Typography>
              <Typography variant="h5">You&apos;ve declined...</Typography>
              <Box sx={{ mt: 2, mb: 2 }}>{renderGathering}</Box>
              <Typography variant="body1" component="div">
                This gathering has already been declined.
              </Typography>
            </Container>
          </>
        );
    }
  }

  if (state.error.some) {
    return (
      <>
        {renderTopBar}
        <ApiError
          errorCode={state.error.value.status_code}
          variant="invite"
          nav="back"
        />
      </>
    );
  }

  // Should never get to this point
  return (
    <>
      {renderTopBar}
      <ApiError errorCode={400} variant="invite" nav="back" />
      );
    </>
  );
}

export default InvitationDisplay;
