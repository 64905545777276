import { useState, useCallback } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import InvitationClient from '../api/InvitationClient';

import ErrorResponse from '../types/ErrorResponse';

type Props = {
  invitationId: number | string;
  client: InvitationClient;
  onError: (error: ErrorResponse) => void;
  onSuccess: () => void;
  onCancel?: () => void;
};

export default function DeclineControl({
  invitationId,
  client,
  onError,
  onSuccess,
  onCancel = () => {},
}: Props) {
  const [open, setOpen] = useState(false);
  const [declineReason, setDeclineReason] = useState<string | undefined>();

  const handleClose = useCallback(() => {
    setOpen(false);
    setDeclineReason(undefined);
    onCancel();
  }, [onCancel]);
  const handleDecline = useCallback(() => {
    async function executeDecline() {
      try {
        const reason: string | undefined =
          declineReason === '' ? undefined : declineReason;
        const response = await client.save_invitation_response(
          {
            action: 'Decline',
            reason,
          },
          invitationId,
        );

        setOpen(false);
        if (!response.ok) {
          onError(response.error);
        } else {
          onSuccess();
        }
      } catch {
        setOpen(false);
        onError({ status_code: 0, message: 'Unknown error' });
      }
    }

    executeDecline();
  }, [client, invitationId, onError, onSuccess, declineReason]);

  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Decline
      </Button>
      <Dialog
        open={open}
        PaperProps={{
          className: 'decline-dialog-paper',
        }}
      >
        <DialogTitle textAlign="center">
          <div>
            <Icon>warning_icon</Icon>
          </div>
          <div>Confirm Decline</div>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body1" component="p">
              If you decline, your host won’t know that you received an
              invitation. You won’t be able to accept this gathering after
              declining.
            </Typography>
            <Typography variant="body2" component="p">
              Optionally, you can give your host a reason for declining. This is
              not required, and your host will know you received an invitation.
            </Typography>
          </Stack>
        </DialogContent>
        <Accordion
          onChange={(e, expanded) => {
            if (!expanded) setDeclineReason(undefined);
          }}
          sx={{
            boxShadow: 'none',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            expandIcon={<ExpandMoreIcon />}
            sx={{ boxShadow: 'none', flexDirection: 'row-reverse' }}
          >
            <Typography variant="body2" sx={{ marginLeft: '0.5rem' }}>
              Provide a reason for declining
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              color="secondary"
              label="Let the host know why you can't attend"
              variant="outlined"
              InputProps={{
                inputProps: {
                  style: {
                    borderRadius: '4px',
                  },
                },
              }}
              value={declineReason !== undefined ? declineReason : ''}
              onChange={(e) => setDeclineReason(e.target.value)}
              fullWidth
            />
          </AccordionDetails>
        </Accordion>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDecline}>Decline</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

DeclineControl.defaultProps = {
  onCancel: () => {},
};
