import { useState, useCallback, SyntheticEvent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  Alert,
  Button,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from '@mui/material';

import ErrorResponse from '../types/ErrorResponse';
import { Result } from '../types/Result';
import EmailInput from '../components/EmailInput';
import PasswordInput, {
  validPasswordDescription,
} from '../components/PasswordInput';
import logo from '../assets/logo.svg';
import { useUnauthenticatedClient } from '../providers/UnauthenticatedClientProvider';
import ErrorDialog from '../components/ErrorDialog';
import { LoginToken } from '../models/LoginToken';
import { Data } from '../types/Data';
import { useSession } from '../contexts/Session';

export default function ChangePassword() {
  const { setAuthTokens } = useSession();
  const [searchParams] = useSearchParams();
  const notificationRid = searchParams.get('notification_rid');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errorMsg, setErrorMsg] = useState('');
  const [errorCode, setErrorCode] = useState(0);
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  const client = useUnauthenticatedClient();

  const handleUpdatePassword = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();

      setPending(true);

      client
        .ch_pwd(
          {
            primary_email: email.trim().toLowerCase(),
            new_password: password,
          },
          notificationRid || '',
        )
        .then((result: Result<Data<LoginToken>, ErrorResponse>) => {
          if (result.ok) {
            setAuthTokens({
              accessToken: result.value.data.access_token,
              accessTokenExpiration: result.value.data.access_token_expiration,
              refreshToken: result.value.data.refresh_token,
              refreshTokenExpiration:
                result.value.data.refresh_token_expiration,
            });
            setPending(false);
            navigate('/app');
          } else {
            setErrorMsg(result.error?.message || '');
            setPending(false);
            if (result.error.status_code === 403) {
              setErrorMsg('Reset has timed out, please request new reset link');
            }

            if (
              result.error.status_code === 502 ||
              result.error.status_code === 504
            ) {
              setErrorCode(result.error.status_code);
            }
          }
        })
        .catch(() => {
          setErrorMsg('An unknown error has occurred');
          setPending(false);
        });
    },
    [
      email,
      client,
      password,
      setPending,
      setErrorCode,
      navigate,
      notificationRid,
      setAuthTokens,
    ],
  );

  const isRegisterFormComplete = useCallback(
    () =>
      email.trim() !== '' &&
      password !== '' &&
      confirmPassword !== '' &&
      password === confirmPassword,
    [email, password, confirmPassword],
  );

  const renderUpdatePassword = (
    <form
      onSubmit={(event) => {
        handleUpdatePassword(event);
      }}
    >
      <Stack spacing={1}>
        <Button onClick={() => navigate('/')} sx={{ textTransform: 'none' }}>
          <img src={logo} className="App-logo" alt="logo" />
        </Button>
        <Typography variant="h5" component="div">
          Update password
        </Typography>
        {errorMsg !== '' && (
          <Alert severity="error">{errorMsg.toString()}</Alert>
        )}
        {validPasswordDescription}
        <EmailInput
          id="sign-in-email"
          label="Email"
          value={email}
          type="email"
          onChange={(val: string) => setEmail(val)}
        />
        <PasswordInput
          id="password"
          label="Password"
          value={password}
          onChange={(val: string) => setPassword(val)}
          required
        />
        <PasswordInput
          id="confirm_password"
          label="Confirm Password"
          value={confirmPassword}
          onChange={(val: string) => setConfirmPassword(val)}
          required
          error_string={
            confirmPassword !== password ? 'Passwords must match' : ''
          }
        />
        {!pending && (
          <Button
            type="submit"
            disabled={!isRegisterFormComplete()}
            variant="contained"
          >
            Submit
          </Button>
        )}
        {pending && <CircularProgress />}
        <Button
          onClick={() => navigate('/auth/sign_in')}
          sx={{ textTransform: 'none' }}
        >
          Already have an account? Sign in
        </Button>
      </Stack>
    </form>
  );

  return (
    <Container>
      <Container maxWidth="xs" sx={{ mt: 4, mb: 4 }}>
        <ErrorDialog open={errorCode !== 0} code={errorCode} home />
        {renderUpdatePassword}
      </Container>
    </Container>
  );
}
