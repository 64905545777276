import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
  useReducer,
  useRef,
  Dispatch,
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Alert,
  Box,
  Button,
  Container,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  Collapse,
  IconButton,
} from '@mui/material';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import moment from 'moment';

import { useRequiredAuthenticatedClient } from '../../providers/AuthenticatedClientProvider';
import ErrorResponse from '../../types/ErrorResponse';
import { Option, Some, None } from '../../types/Option';
import { Result } from '../../types/Result';

import { NewGatheringId, NewGathering } from '../../models/NewGathering';
import { Guest } from '../../models/Guest';
import { Tag } from '../../models/Tag';

import ErrorDialog from '../../components/ErrorDialog';
import RequiredTextField from '../../components/RequiredTextField';
import UsersAndTags, { SelectableGuest } from '../../components/UsersAndTags';
import { Action, Selectable } from '../../components/SearchableMultiSelect';
import DurationPicker from '../../components/DurationPicker';

type DateTimePickerValue = moment.Moment | null | undefined;

type AddGatheringState = {
  orbit: Guest[];
  tags: Tag[];
  gathering: NewGathering;
  guests: Guest[];
  startDate: DateTimePickerValue;
  startTime: DateTimePickerValue;
  endDate: DateTimePickerValue;
  endTime: DateTimePickerValue;

  // remote-call related state
  loaded: boolean;
  error: Option<ErrorResponse>;
};

const initialState: AddGatheringState = {
  orbit: [],
  tags: [],
  gathering: {
    title: '',
    start_time: '',
    end_time: '',
    description: '',
    gathering_location: '',
    min_guests: 5,
    max_guests: 8,
    invite_duration_minutes: 1440,
    final_invite_expiration_minutes: undefined,
    guests: [],
  },
  guests: [],
  startDate: moment().add(1, 'week').startOf('hour'),
  startTime: moment().add(1, 'week').startOf('hour'),
  endDate: moment().add(1, 'week').add(3, 'hours').startOf('hour'),
  endTime: moment().add(1, 'week').add(3, 'hours').startOf('hour'),
  loaded: false,
  error: None(),
};

type AddGatheringAction =
  | {
      type: 'loaded';
      orbit: Guest[];
      tags: Tag[];
      gathering: NewGathering;
      guests: Guest[];
    }
  | { type: 'update_gathering'; gathering: NewGathering }
  | {
      type: 'update_date';
      startDate: DateTimePickerValue;
      endDate: DateTimePickerValue;
    }
  | {
      type: 'update_time';
      startTime: DateTimePickerValue;
      endTime: DateTimePickerValue;
    }
  | { type: 'update_guests'; guests: Guest[] }
  | { type: 'clear_gathering' }
  | { type: 'error'; error: ErrorResponse };

function stateReducer(
  state: AddGatheringState,
  action: AddGatheringAction,
): AddGatheringState {
  switch (action.type) {
    case 'loaded': {
      return {
        loaded: true,
        orbit: action.orbit,
        tags: action.tags,
        gathering: action.gathering,
        guests: action.guests,
        startDate: moment(action.gathering.start_time),
        startTime: moment(action.gathering.start_time),
        endDate: action.gathering.end_time
          ? moment(action.gathering.end_time)
          : moment(action.gathering.start_time).add(3, 'hour'),
        endTime: action.gathering.end_time
          ? moment(action.gathering.end_time)
          : moment(action.gathering.start_time).add(3, 'hour'),
        error: None(),
      };
    }
    case 'update_gathering': {
      sessionStorage.setItem(
        'pesky_cached_new_gathering',
        JSON.stringify(action.gathering),
      );
      return { ...state, gathering: action.gathering };
    }
    case 'update_date': {
      let startTime = null;
      if (action.startDate && state.startTime) {
        startTime = action.startDate
          .clone()
          .hour(state.startTime.hour())
          .minutes(state.startTime.minutes());
      }

      let endTime = null;
      if (action.endDate && state.endTime) {
        endTime = action.endDate
          .clone()
          .hour(state.endTime.hour())
          .minutes(state.endTime.minutes());
      }

      const gathering = {
        ...state.gathering,
        start_time: startTime,
        end_time: endTime,
      };

      sessionStorage.setItem(
        'pesky_cached_new_gathering',
        JSON.stringify(gathering),
      );
      return { ...state, startDate: action.startDate, endDate: action.endDate };
    }
    case 'update_time': {
      let startTime = null;
      if (state.startDate && action.startTime) {
        startTime = state.startDate
          .clone()
          .hour(action.startTime.hour())
          .minutes(action.startTime.minutes());
      }

      let endTime = null;
      if (state.endDate && action.endTime) {
        endTime = state.endDate
          .clone()
          .hour(action.endTime.hour())
          .minutes(action.endTime.minutes());
      }

      const gathering = {
        ...state.gathering,
        start_time: startTime,
        end_time: endTime,
      };

      sessionStorage.setItem(
        'pesky_cached_new_gathering',
        JSON.stringify(gathering),
      );
      return { ...state, startTime: action.startTime, endTime: action.endTime };
    }
    case 'update_guests': {
      const guestIds = action.guests.map((guest) => guest.id);
      const gathering = {
        ...state.gathering,
        guests: guestIds,
      };

      sessionStorage.setItem(
        'pesky_cached_new_gathering',
        JSON.stringify(gathering),
      );

      return { ...state, gathering, guests: action.guests };
    }
    case 'clear_gathering':
      return {
        ...state,
        gathering: initialState.gathering,
        guests: initialState.guests,
        startDate: initialState.startDate,
        startTime: initialState.startTime,
        endDate: initialState.endDate,
        endTime: initialState.endTime,
      };
    case 'error':
      return { ...state, loaded: true, error: Some(action.error) };
    default:
      return { ...state };
  }
}

function AddGathering() {
  const navigate = useNavigate();
  const client = useRequiredAuthenticatedClient();
  const [state, dispatch] = useReducer(stateReducer, initialState);

  const [requiredNumberOfGuestsType, setRequiredNumberOfGuestsType] =
    useState('atLeast');
  const [nonContactableSelectedGuests, setNonContactableSelectedGuests] =
    useState<Guest[]>([]);

  const [newGuestTags, setNewGuestTags] = useState<Tag[]>([]);
  const [duration, setDuration] = useState('24');
  const [durationUnit, setDurationUnit] = useState('Hours');
  const [finalExpiration, setExpiration] = useState('');
  const [expirationUnit, setExpirationUnit] = useState('Hours');

  const dispatchGuestRef = useRef<Dispatch<Action<Selectable>> | null>(null);

  const [expanded, setExpanded] = useState(false);
  const badEndTime =
    state
      .endDate!.clone()
      .hour(state.endTime!.hour())
      .minutes(state.endTime!.minutes()) <
    state
      .startDate!.clone()
      .hour(state.startTime!.hour())
      .minutes(state.startTime!.minutes());

  useEffect(() => {
    async function load() {
      const orbitResult = await client.get_guests();
      if (!orbitResult.ok) {
        dispatch({ type: 'error', error: orbitResult.error });
        return;
      }

      const tagsResult = await client.get_tags();
      if (!tagsResult.ok) {
        dispatch({ type: 'error', error: tagsResult.error });
        return;
      }

      let newGatheringJson = sessionStorage.getItem(
        'pesky_cached_new_gathering',
      );

      if (!newGatheringJson) {
        newGatheringJson = JSON.stringify(initialState.gathering);
        sessionStorage.setItem('pesky_cached_new_gathering', newGatheringJson);
      }

      let newGathering = JSON.parse(newGatheringJson);

      const tempGuests = orbitResult.value.data.filter((guest1: Guest) =>
        newGathering.guests.some((guest2: number) => guest2 === guest1.id),
      );

      if (!newGathering.start_time) {
        if (initialState.startDate && initialState.startTime) {
          const startTime = initialState.startDate
            .clone()
            .hour(initialState.startTime.hour())
            .minutes(initialState.startTime.minutes());

          newGathering = {
            ...newGathering,
            start_time: startTime.format(),
          };
        }
      }

      if (!newGathering.end_time) {
        if (initialState.endDate && initialState.endTime) {
          const endTime = initialState.endDate
            .clone()
            .hour(initialState.endTime.hour())
            .minutes(initialState.endTime.minutes());

          newGathering = {
            ...newGathering,
            end_time: endTime.format(),
          };
        }
      }

      setDuration(
        newGathering.invite_duration_minutes > 1440
          ? (newGathering.invite_duration_minutes / 1440).toString()
          : (newGathering.invite_duration_minutes / 60).toString(),
      );

      setDurationUnit(
        newGathering.invite_duration_minutes > 1440 ? 'Days' : 'Hours',
      );

      if (newGathering.final_invite_expiration_minutes) {
        setExpiration(
          newGathering.final_invite_expiration_minutes > 1440
            ? (newGathering.final_invite_expiration_minutes / 1440).toString()
            : (newGathering.final_invite_expiration_minutes / 60).toString(),
        );

        setExpirationUnit(
          newGathering.final_invite_expiration_minutes > 1440
            ? 'Days'
            : 'Hours',
        );
      }

      dispatch({
        type: 'loaded',
        orbit: orbitResult.value.data,
        tags: tagsResult.value.data,
        gathering: newGathering,
        guests: tempGuests,
      });
    }
    load();
  }, [client, dispatch]);

  const reloadTags = useCallback(
    (tags: number[]) => {
      async function reload() {
        const tagsResult = await client.get_tags();
        if (!tagsResult.ok) {
          dispatch({ type: 'error', error: tagsResult.error });
          return;
        }

        dispatch({
          type: 'loaded',
          orbit: state.orbit,
          tags: tagsResult.value.data,
          gathering: state.gathering,
          guests: state.guests,
        });
        setNewGuestTags(
          tagsResult.value.data.filter((tag) => tags.includes(tag.id)),
        );
      }

      reload();
    },
    [state, dispatch, client],
  );

  const reloadGuests = useCallback(
    (newGuest: Guest) => {
      async function reload(guest: Guest) {
        const orbitResult = await client.get_guests();
        if (!orbitResult.ok) {
          dispatch({ type: 'error', error: orbitResult.error });
          return;
        }

        const tagsResult = await client.get_tags();
        if (!tagsResult.ok) {
          dispatch({ type: 'error', error: tagsResult.error });
          return;
        }

        let newGatheringJson = sessionStorage.getItem(
          'pesky_cached_new_gathering',
        );

        if (!newGatheringJson) {
          newGatheringJson = JSON.stringify(initialState.gathering);
          sessionStorage.setItem(
            'pesky_cached_new_gathering',
            newGatheringJson,
          );
        }

        let newGathering = JSON.parse(newGatheringJson);

        const tempGuests = [
          ...orbitResult.value.data.filter((guest1: Guest) =>
            newGathering.guests.some((guest2: number) => guest2 === guest1.id),
          ),
          guest,
        ];

        if (!newGathering.start_time) {
          if (initialState.startDate && initialState.startTime) {
            const startTime = initialState.startDate
              .clone()
              .hour(initialState.startTime.hour())
              .minutes(initialState.startTime.minutes());

            newGathering = {
              ...newGathering,
              start_time: startTime.format(),
            };
          }
        }

        if (!newGathering.end_time) {
          if (initialState.endDate && initialState.endTime) {
            const endTime = initialState.endDate
              .clone()
              .hour(initialState.endTime.hour())
              .minutes(initialState.endTime.minutes());

            newGathering = {
              ...newGathering,
              end_time: endTime.format(),
            };
          }
        }

        const guestIds = tempGuests.map((g) => g.id);
        newGathering = {
          ...state.gathering,
          guests: guestIds,
        };

        sessionStorage.setItem(
          'pesky_cached_new_gathering',
          JSON.stringify(newGathering),
        );

        dispatch({
          type: 'loaded',
          orbit: orbitResult.value.data,
          tags: tagsResult.value.data,
          gathering: newGathering,
          guests: tempGuests,
        });

        dispatchGuestRef.current?.({
          type: 'refreshOptions',
          options: orbitResult.value.data.map((t) => new SelectableGuest(t)),
        });

        dispatchGuestRef.current?.({
          type: 'refreshSelected',
          selectedOptions: tempGuests.map((t) => new SelectableGuest(t)),
        });
      }
      reload(newGuest);
    },
    [client, state, dispatch],
  );

  const requiredGuestsDisplayString = useCallback(() => {
    let str = '';
    if (requiredNumberOfGuestsType === 'exactly') {
      str = `${
        state.gathering.min_guests !== undefined
          ? state.gathering.min_guests
          : '0'
      } guests`;
    } else {
      str = `${
        state.gathering.min_guests !== undefined
          ? state.gathering.min_guests
          : '0'
      }-${
        state.gathering.max_guests !== undefined
          ? state.gathering.max_guests
          : '0'
      } guests`;
    }
    return str;
  }, [state, requiredNumberOfGuestsType]);

  const submitForm = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      const guestIds = state.guests.map((guest) => guest.id);
      if (state.startDate && state.startTime) {
        const datetime = state.startDate
          .clone()
          .hour(state.startTime.hour())
          .minutes(state.startTime.minutes());

        let endtime = null;
        if (state.endDate && state.endTime) {
          endtime = state.endDate
            .clone()
            .hour(state.endTime.hour())
            .minutes(state.endTime.minutes());
        }

        client
          .save_new_gathering({
            title: state.gathering.title,
            start_time: datetime.format(),
            end_time: expanded && endtime ? endtime.format() : undefined,
            description: state.gathering.description,
            gathering_location: state.gathering.gathering_location,
            min_guests: state.gathering.min_guests || 0,
            max_guests:
              requiredNumberOfGuestsType === 'exactly'
                ? state.gathering.min_guests || 0
                : state.gathering.max_guests || 0,
            // set to 24 hours
            invite_duration_minutes: state.gathering.invite_duration_minutes,
            final_invite_expiration_minutes:
              state.gathering.final_invite_expiration_minutes,
            guests: guestIds,
          })
          .then((result: Result<NewGatheringId, ErrorResponse>) => {
            if (result.ok) {
              sessionStorage.removeItem('pesky_cached_new_gathering');
              navigate('/app/congratulations', {
                state: {
                  requiredGuests: requiredGuestsDisplayString(),
                  gatheringId: result.value.data.gathering_id,
                },
              });
            } else {
              dispatch({ type: 'error', error: result.error });
            }
          })
          .catch(() => {
            dispatch({
              type: 'error',
              error: { status_code: 0, message: 'Unknown error' },
            });
          });
      }
    },
    [
      state,
      requiredNumberOfGuestsType,
      client,
      navigate,
      requiredGuestsDisplayString,
      expanded,
    ],
  );

  const isSendable = useCallback(() => {
    const sendableGuests =
      state.guests.length - nonContactableSelectedGuests.length;

    if (state.gathering.title.trim() === '') {
      return false;
    }
    if (requiredNumberOfGuestsType === 'exactly') {
      return (
        state.gathering.min_guests !== undefined &&
        state.gathering.min_guests >= 1 &&
        state.gathering.min_guests <= sendableGuests
      );
    }

    if (expanded && badEndTime) {
      return false;
    }

    return (
      state.gathering.min_guests !== undefined &&
      state.gathering.max_guests !== undefined &&
      state.gathering.min_guests >= 1 &&
      state.gathering.min_guests <= sendableGuests &&
      state.gathering.min_guests < state.gathering.max_guests
    );
  }, [
    state,
    nonContactableSelectedGuests,
    requiredNumberOfGuestsType,
    badEndTime,
    expanded,
  ]);

  const remainingGuests = useCallback(() => {
    const sendableGuests =
      state.guests.length - nonContactableSelectedGuests.length;

    if (
      state.gathering.min_guests === undefined ||
      state.gathering.min_guests <= 1
    ) {
      return 0;
    }

    return state.gathering.min_guests - sendableGuests;
  }, [nonContactableSelectedGuests, state]);

  // clear gatherings and saved values
  const clearForm = () => {
    // clear the session storage
    sessionStorage.removeItem('pesky_cached_new_gathering');
    // reset initial state
    dispatch({ type: 'clear_gathering' });
    // remove selected guest options
    dispatchGuestRef.current?.({
      type: 'refreshSelected',
      selectedOptions: [],
    });
  };

  const handleDurationChange = (newDuration: string) => {
    setDuration(newDuration);
    // convert duration time to minutes
    const durationMinutes =
      parseInt(newDuration, 10) * (durationUnit === 'Hours' ? 60 : 1440);
    const gathering = {
      ...state.gathering,
      invite_duration_minutes: durationMinutes,
    };
    dispatch({ type: 'update_gathering', gathering });
  };

  const handleDurationUnitChange = (newUnit: string) => {
    setDurationUnit(newUnit);

    // convert duration time to minutes
    const durationMinutes =
      parseInt(duration, 10) * (newUnit === 'Hours' ? 60 : 1440);
    const gathering = {
      ...state.gathering,
      invite_duration_minutes: durationMinutes,
    };
    dispatch({ type: 'update_gathering', gathering });
  };

  const handleExpirationChange = (newExpiration: string) => {
    setExpiration(newExpiration);
    // convert duration time to minutes
    const expirationMinutes =
      parseInt(newExpiration, 10) * (expirationUnit === 'Hours' ? 60 : 1440);
    const gathering = {
      ...state.gathering,
      final_invite_expiration_minutes: expirationMinutes,
    };
    dispatch({ type: 'update_gathering', gathering });
  };

  const handleExpirationUnitChange = (newUnit: string) => {
    setExpirationUnit(newUnit);

    // convert duration time to minutes
    const expirationMinutes =
      parseInt(finalExpiration, 10) * (newUnit === 'Hours' ? 60 : 1440);
    const gathering = {
      ...state.gathering,
      final_invite_expiration_minutes: expirationMinutes,
    };
    dispatch({ type: 'update_gathering', gathering });
  };

  if (!state.loaded) {
    // Gathering hasn't loaded yet
    return (
      <Container>
        <Typography variant="h5">Loading</Typography>
      </Container>
    );
  }

  return (
    <form onSubmit={submitForm}>
      <Container>
        <ErrorDialog
          open={state.error.some}
          code={
            state.error.some && state.error.value.status_code
              ? state.error.value.status_code
              : 0
          }
        />
        <Typography variant="h5">New Gathering</Typography>
        <Stack spacing={3}>
          <RequiredTextField
            id="title"
            label="Title"
            value={state.gathering.title}
            onChange={(val: string) => {
              const gathering = { ...state.gathering, title: val };
              dispatch({ type: 'update_gathering', gathering });
            }}
          />
          <Stack spacing={0}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {/* eslint-disable react/jsx-props-no-spreading */}
              <Stack direction="row" spacing={1}>
                <DatePicker
                  label="Start Date"
                  value={state.startDate}
                  onChange={(date: moment.Moment | null | undefined) => {
                    dispatch({
                      type: 'update_date',
                      startDate: date,
                      endDate: expanded ? date : state.endDate,
                    });
                  }}
                />
                <TimePicker
                  label="Start Time"
                  value={state.startTime}
                  minutesStep={5}
                  onChange={(time: moment.Moment | null | undefined) => {
                    dispatch({
                      type: 'update_time',
                      startTime: time,
                      endTime: !expanded
                        ? time!.clone().add(3, 'hours').startOf('hour')
                        : state.endTime,
                    });
                  }}
                />
              </Stack>
              <Stack direction="row" alignItems="center" sx={{ marginTop: 0 }}>
                <Typography variant="body1" color="primary.main">
                  Add end time (optional)
                </Typography>
                <IconButton onClick={() => setExpanded(!expanded)}>
                  {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Stack>
              {/* eslint-enable react/jsx-props-no-spreading */}
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {/* eslint-disable react/jsx-props-no-spreading */}

              <Stack direction="column" spacing={1}>
                <Collapse in={expanded}>
                  <Stack direction="row" spacing={1}>
                    <DatePicker
                      label="End Date"
                      value={state.endDate}
                      onChange={(date: moment.Moment | null | undefined) =>
                        dispatch({
                          type: 'update_date',
                          startDate: state.startDate,
                          endDate: date,
                        })
                      }
                    />
                    <TimePicker
                      label="End Time"
                      value={state.endTime}
                      minutesStep={5}
                      onChange={(time) =>
                        dispatch({
                          type: 'update_time',
                          startTime: state.startTime,
                          endTime: time,
                        })
                      }
                    />
                  </Stack>
                  {badEndTime && (
                    <Alert severity="warning">
                      End time must be after start time
                    </Alert>
                  )}
                </Collapse>
              </Stack>
              {/* eslint-enable react/jsx-props-no-spreading */}
            </LocalizationProvider>
          </Stack>
          <TextField
            variant="outlined"
            label="Location"
            value={state.gathering.gathering_location}
            onChange={(e) => {
              const gathering = {
                ...state.gathering,
                gathering_location: e.target.value,
              };
              dispatch({ type: 'update_gathering', gathering });
            }}
            multiline
          />
          <TextField
            variant="outlined"
            label="Description"
            value={state.gathering.description}
            onChange={(e) => {
              const gathering = {
                ...state.gathering,
                description: e.target.value,
              };
              dispatch({ type: 'update_gathering', gathering });
            }}
            multiline
          />
          <Stack spacing={0.7}>
            <DurationPicker
              inviteDuration={duration}
              setDuration={handleDurationChange}
              durationUnit={durationUnit}
              setDurationUnit={handleDurationUnitChange}
              finalExpiration={finalExpiration}
              setFinalExpiration={handleExpirationChange}
              expirationUnit={expirationUnit}
              setExpirationUnit={handleExpirationUnitChange}
            />
            <Typography variant="body1">
              How many guests would you like to attend this gathering?
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="atLeast"
              name="radio-buttons-group"
              onChange={(e) => setRequiredNumberOfGuestsType(e.target.value)}
            >
              <Stack spacing={1}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <FormControlLabel
                      value="atLeast"
                      control={<Radio />}
                      label="At least"
                      sx={{ mr: 1 }}
                    />
                    <OutlinedInput
                      size="small"
                      type="number"
                      value={
                        state.gathering.min_guests !== undefined
                          ? state.gathering.min_guests
                          : ''
                      }
                      onChange={(e) => {
                        const val = e.target.value;

                        if (val) {
                          const gathering = {
                            ...state.gathering,
                            min_guests: parseInt(val, 10),
                          };
                          dispatch({ type: 'update_gathering', gathering });
                        } else {
                          const gathering = {
                            ...state.gathering,
                            min_guests: 0,
                          };
                          dispatch({ type: 'update_gathering', gathering });
                        }
                      }}
                      sx={{
                        width: '90px',
                        m: 0,
                      }}
                      disabled={requiredNumberOfGuestsType === 'exactly'}
                      inputProps={{
                        min: 1,
                        max:
                          state.gathering.max_guests !== undefined
                            ? state.gathering.max_guests - 1
                            : null,
                      }}
                      error={
                        state.gathering.min_guests === undefined ||
                        state.gathering.min_guests < 1
                      }
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    pl={{ xs: 6, sm: 0 }}
                  >
                    <InputLabel htmlFor="max-guests-input">
                      but no more than
                    </InputLabel>
                    <OutlinedInput
                      id="max-guests-input"
                      size="small"
                      type="number"
                      value={
                        state.gathering.max_guests !== undefined
                          ? state.gathering.max_guests
                          : ''
                      }
                      onChange={(e) => {
                        const max = parseInt(e.target.value, 10);
                        if (max) {
                          const gathering = {
                            ...state.gathering,
                            max_guests: max,
                          };
                          dispatch({ type: 'update_gathering', gathering });
                        } else {
                          const gathering = {
                            ...state.gathering,
                            max_guests: 0,
                          };
                          dispatch({ type: 'update_gathering', gathering });
                        }
                      }}
                      sx={{
                        width: '90px',
                      }}
                      error={
                        (state.gathering.min_guests !== undefined &&
                          state.gathering.max_guests !== undefined &&
                          (state.gathering.min_guests < 1 ||
                            state.gathering.max_guests <
                              state.gathering.min_guests)) ||
                        state.gathering.min_guests === undefined ||
                        state.gathering.max_guests === undefined
                      }
                      disabled={requiredNumberOfGuestsType === 'exactly'}
                      inputProps={{
                        min:
                          state.gathering.min_guests !== undefined
                            ? state.gathering.min_guests + 1
                            : 2,
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <FormControlLabel
                    value="exactly"
                    control={<Radio />}
                    label="Exactly"
                    sx={{ mr: 1 }}
                  />
                  <OutlinedInput
                    size="small"
                    type="number"
                    inputProps={{ className: 'digitsOnly', min: 1 }}
                    value={
                      state.gathering.min_guests !== undefined
                        ? state.gathering.min_guests
                        : ''
                    }
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val) {
                        const gathering = {
                          ...state.gathering,
                          min_guests: parseInt(val, 10),
                          max_guests: parseInt(val, 10) + 1,
                        };
                        dispatch({ type: 'update_gathering', gathering });
                      } else {
                        const gathering = {
                          ...state.gathering,
                          min_guests: 0,
                          max_guests: 0,
                        };
                        dispatch({ type: 'update_gathering', gathering });
                      }
                    }}
                    sx={{
                      width: '90px',
                    }}
                    disabled={requiredNumberOfGuestsType === 'atLeast'}
                    error={
                      state.gathering.min_guests === undefined ||
                      state.gathering.min_guests < 1
                    }
                  />
                </Stack>
              </Stack>
            </RadioGroup>
          </Stack>
          {state.loaded && (
            <UsersAndTags
              orbit={state.orbit}
              orbitTags={state.tags}
              setSelectedGuests={(val: Guest[]) =>
                dispatch({ type: 'update_guests', guests: val })
              }
              selectedGuests={state.guests}
              nonContactableSelectedGuests={nonContactableSelectedGuests}
              setNonContactableSelectedGuests={setNonContactableSelectedGuests}
              customDispatchGuestRef={dispatchGuestRef}
              onReload={reloadGuests}
              onReloadTags={reloadTags}
              newGuestTags={newGuestTags}
            />
          )}

          {remainingGuests() > 1 && (
            <Typography variant="body1">
              You still need to add at least {remainingGuests()} more guests or
              lower the gathering minimum to create this gathering.
            </Typography>
          )}

          {remainingGuests() === 1 && (
            <Typography variant="body1">
              You still need to add at least {remainingGuests()} more guest or
              lower the gathering minimum to create this gathering.
            </Typography>
          )}

          <Box textAlign="center" sx={{ pb: 4 }}>
            <Button
              variant="contained"
              type="submit"
              fullWidth={false}
              disabled={!isSendable()}
            >
              Send Invitations
            </Button>
            <Button
              variant="contained"
              fullWidth={false}
              sx={{ ml: '10px' }}
              onClick={clearForm}
            >
              Clear
            </Button>
          </Box>
        </Stack>
      </Container>
    </form>
  );
}

export default AddGathering;
