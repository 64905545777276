import { useCallback, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import WarningIcon from '@mui/icons-material/Warning';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import AuthenticatedClient from '../api/AuthenticatedClient';

export type Props = {
  gatheringId: number;
  gatheringTitle: string;
  client: AuthenticatedClient;
  onError: (error: string) => void;
  onSuccess: () => void;
  onAbort?: () => void;
};

export default function CancelGatheringControl(props: Props) {
  const {
    gatheringId,
    gatheringTitle,
    client,
    onError,
    onSuccess,
    onAbort = () => {},
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [cancellReason, setCancellReason] = useState<string | undefined>();

  const handleClose = () => {
    setOpen(false);
    onAbort();
  };

  const handleCancelGathering = useCallback(() => {
    async function executeCancelGathering() {
      try {
        const response = await client.save_gathering(gatheringId, {
          cancelled: true,
          cancellation_note: cancellReason,
        });

        setOpen(false);
        if (!response.ok) {
          onError(
            response.error?.message ||
              'Unable to decline gathering: an unknown error occurred',
          );
        } else {
          onSuccess();
        }
      } catch {
        setOpen(false);
        onError('Unable to decline gathering: an unknown error occurred.');
      }
    }

    executeCancelGathering();
  }, [client, gatheringId, cancellReason, onError, onSuccess]);

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <DeleteIcon style={{ fontSize: 24 }} />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle textAlign="center">
          <div>
            <WarningIcon />
          </div>
          <div>Cancel your gathering?</div>
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Typography variant="body1">
              {`Are you sure you want to cancel ${gatheringTitle}?`}
            </Typography>
            <Typography variant="body2">
              Guests who have accepted or who have pending invites will be
              notified.
            </Typography>
          </Stack>
        </DialogContent>
        <Accordion
          onChange={(e, expanded) => {
            if (!expanded) setCancellReason(undefined);
          }}
          sx={{
            boxShadow: 'none',
            '&:before': {
              display: 'none',
            },
          }}
        >
          <AccordionSummary
            aria-controls="panel2d-content"
            id="panel2d-header"
            expandIcon={<ExpandMoreIcon />}
            sx={{ boxShadow: 'none', flexDirection: 'row-reverse' }}
          >
            <Typography variant="body2" sx={{ marginLeft: '0.5rem' }}>
              Provide a reason for cancelling
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              color="secondary"
              label="Let your guests know why you are cancelling"
              variant="outlined"
              InputProps={{
                inputProps: {
                  style: {
                    borderRadius: '4px',
                  },
                },
              }}
              value={cancellReason !== undefined ? cancellReason : ''}
              onChange={(e) => setCancellReason(e.target.value)}
              multiline
              fullWidth
            />
          </AccordionDetails>
        </Accordion>
        <DialogActions>
          <Button onClick={handleClose}>Go back</Button>
          <Button onClick={handleCancelGathering}>Cancel gathering</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

CancelGatheringControl.defaultProps = {
  onAbort: () => {},
};
