import moment from 'moment';

import { Stack, Typography } from '@mui/material';

import AvTimerIcon from '@mui/icons-material/AvTimer';

type Props = {
  createdAt: string;
  inviteDuration: number;
};

export default function InvitationExpiring({
  createdAt,
  inviteDuration,
}: Props) {
  const calculateRemainingTime = (expirationTime: moment.Moment) => {
    const now = moment();

    const duration = moment.duration(expirationTime.diff(now));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;

    if (hours < 0 || minutes < 0) {
      return 'Expiring';
    }

    if (hours !== 0) {
      return `Expiring in ${hours} hours`;
    }

    return `Expiring in ${minutes} minutes`;
  };

  const remainingTime = calculateRemainingTime(
    moment(createdAt).add(inviteDuration, 'minutes'),
  );

  return (
    <Stack direction="row" spacing={0.5}>
      <AvTimerIcon />
      <Typography variant="body2" component="div" fontWeight="bold">
        {remainingTime}{' '}
      </Typography>
    </Stack>
  );
}
