import { Data } from '../types/Data';
import ErrorResponse from '../types/ErrorResponse';
import { Ok, Err, Result } from '../types/Result';
import { Slice } from '../types/Slice';

import { EditedGathering } from '../models/EditedGathering';
import { EditedGuest } from '../models/EditedGuest';
import { Gathering } from '../models/Gathering';
import { Invitation } from '../models/Invitation';
import { ListedGathering } from '../models/ListedGathering';
import { NewGathering, NewGatheringId } from '../models/NewGathering';
import { NewGuest, NewGuestId } from '../models/NewGuest';
import { Guest } from '../models/Guest';
import { UserAccount } from '../models/Account';
import { EditUserAccount } from '../models/EditAccount';
import { Tag, NewTag, NewTagId, EditedTag } from '../models/Tag';
import { UserProfile } from '../models/UserProfile';
import { EditGatheringGuests } from '../models/EditGatheringGuests';
import { UpdatePassword } from '../models/UpdatePassword';
import { LoginToken } from '../models/LoginToken';

import { GuestInvitationResponse } from './GuestInvitationResponse';
import { GuestResponse } from './GuestResponse';
import InvitationClient from './InvitationClient';
import { UserContact, UserContactsData } from './UserContact';

/// Gets us a type-safe json parse response
async function json<T>(res: Response): Promise<T> {
  const val: T = await res.json();
  return val;
}

/// API Client
export default class AuthenticatedClient implements InvitationClient {
  access_token: string;

  protocol_version: number;

  setReadyToRender: (accessToken: String | null) => void;

  constructor(
    access_token: string,
    setReadyToRender: (accessToken: String | null) => void,
  ) {
    this.access_token = access_token;
    this.protocol_version = 15;
    this.setReadyToRender = setReadyToRender;
  }

  public async logout(): Promise<Result<any, ErrorResponse>> {
    const res = await this.post_json(`/api/auth/logout`, null);

    return AuthenticatedClient.handleResponse(res, [
      204,
      async () => Promise.resolve(),
    ]);
  }

  public async ch_pwd(
    updatePassword: UpdatePassword,
  ): Promise<Result<Data<LoginToken>, ErrorResponse>> {
    const res = await this.post_json(`/api/auth/ch_pwd`, updatePassword);

    return AuthenticatedClient.handleResponse(res, [200, async (r) => json(r)]);
  }

  // Gatherings methods

  public async get_gatherings(): Promise<
    Result<Slice<ListedGathering>, ErrorResponse>
  > {
    return this.get('/api/user/gatherings');
  }

  public async get_gathering(
    gathering_id: string,
  ): Promise<Result<Data<Gathering>, ErrorResponse>> {
    return this.get(
      `/api/user/gatherings/${gathering_id}?include=invitation,host_info,guest_exts_with_tags,pending_invites`,
    );
  }

  public async save_new_gathering(
    new_gathering: NewGathering,
  ): Promise<Result<NewGatheringId, ErrorResponse>> {
    const res = await this.post_json('/api/user/gatherings', new_gathering);

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [200, async (r) => json(r)]);
  }

  public async save_gathering(
    gathering_id: number,
    edited_gathering: EditedGathering,
  ): Promise<Result<void, ErrorResponse>> {
    const res = await this.patch_json(
      `/api/user/gatherings/${gathering_id}`,
      edited_gathering,
    );

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [204, async () => {}]);
  }

  public async update_gathering_guests(
    gathering_id: number,
    edit_gathering_guests: EditGatheringGuests,
  ): Promise<Result<void, ErrorResponse>> {
    const res = await this.patch_json(
      `/api/user/gatherings/${gathering_id}/guests`,
      edit_gathering_guests,
    );

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [204, async () => {}]);
  }

  // Guests methods

  public async get_guests(): Promise<Result<GuestResponse, ErrorResponse>> {
    return this.get('/api/user/guests?include=effective');
  }

  public async get_guest(
    guest_id: number,
  ): Promise<Result<Data<Guest>, ErrorResponse>> {
    return this.get(`/api/user/guests/${guest_id}`);
  }

  public async save_new_guest(
    new_guest: NewGuest,
  ): Promise<Result<NewGuestId, ErrorResponse>> {
    const res = await this.post_json('/api/user/guests', new_guest);

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [200, async (r) => json(r)]);
  }

  public async save_guest(
    guest_id: number,
    new_guest: EditedGuest,
  ): Promise<Result<void, ErrorResponse>> {
    const res = await this.patch_json(
      `/api/user/guests/${guest_id}`,
      new_guest,
    );

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [204, async () => {}]);
  }

  public async delete_guest(
    guest_id: number,
    uninvite: boolean,
  ): Promise<Result<void, ErrorResponse>> {
    const res = await this.delete(
      `/api/user/guests/${guest_id}?uninvite=${uninvite}`,
    );

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [204, async () => {}]);
  }

  // Tags methods

  public async get_tags(): Promise<Result<Slice<Tag>, ErrorResponse>> {
    return this.get('/api/user/tags');
  }

  public async save_new_tag(
    new_tag: NewTag,
  ): Promise<Result<NewTagId, ErrorResponse>> {
    const res = await this.post_json('/api/user/tags', new_tag);

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [200, async (r) => json(r)]);
  }

  public async save_tag(
    tag_id: number,
    edited_tag: EditedTag,
  ): Promise<Result<void, ErrorResponse>> {
    const res = await this.patch_json(`/api/user/tags/${tag_id}`, edited_tag);

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [204, async () => {}]);
  }

  public async delete_tag(
    tag_id: number,
  ): Promise<Result<void, ErrorResponse>> {
    const res = await this.delete(`/api/user/tags/${tag_id}`);

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [204, async () => {}]);
  }

  // Invitation methods

  public async get_invitations(
    status?: string,
  ): Promise<Result<Slice<Invitation>, ErrorResponse>> {
    if (status) {
      return this.get(`/api/user/invitations/?status=${status}`);
    }

    return this.get('/api/user/invitations');
  }

  public async get_invitation(
    invitation_id: number | string,
  ): Promise<Result<Data<Invitation>, ErrorResponse>> {
    const response: Result<Data<Invitation>, ErrorResponse> = await this.get(
      `/api/user/invitations/${invitation_id}`,
    );

    if (response.ok) {
      response.value.data.email_available = false;
    }

    return Promise.resolve(response);
  }

  // Gets an invitation without authentication
  // Only to be used when getting an invitation with auth returned 403
  public async get_invitation_unauthed(
    invitation_id: number | string,
  ): Promise<Result<Data<Invitation>, ErrorResponse>> {
    const res = await fetch(`/api/invitations/${invitation_id}`, {
      headers: {
        PeskyProtocolVersion: this.protocol_version.toString(),
      },
    });

    try {
      if (res.status === 200) {
        return Ok(await json(res));
      }
      return Err(await json<ErrorResponse>(res));
    } catch {
      return Err({ status_code: res.status, message: `Unrecognized response` });
    }
  }

  // Gets limited invitation info for cases where the invite is for another
  // email/user
  public async get_masked_invitation(
    invitation_code: string,
  ): Promise<Result<Data<Invitation>, ErrorResponse>> {
    return this.get(`/api/invitations/${invitation_code}`);
  }

  public async save_invitation_response(
    response: GuestInvitationResponse,
    gathering_id: number | string,
  ): Promise<Result<any, ErrorResponse>> {
    const res = await this.post_json(
      `/api/user/invitations/${gathering_id}`,
      response,
    );

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [
      204,
      async () => Promise.resolve(),
    ]);
  }

  // Profile methods

  public async get_profile(): Promise<
    Result<Data<UserProfile>, ErrorResponse>
  > {
    return this.get('/api/user/profile');
  }

  public async get_account(): Promise<
    Result<Data<UserAccount>, ErrorResponse>
  > {
    return this.get('/api/user/account');
  }

  public async save_account(
    edit_account: EditUserAccount,
  ): Promise<Result<any, ErrorResponse>> {
    const res = await this.patch_json(`/api/user/account`, edit_account);

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [
      204,
      async () => Promise.resolve(),
    ]);
  }

  // Contacts (email addresses) methods

  public async get_contacts(): Promise<
    Result<Slice<UserContact>, ErrorResponse>
  > {
    return this.get('/api/user/contacts');
  }

  public async save_contacts(
    contacts: UserContactsData,
    invitation_code?: string | null | undefined,
  ): Promise<Result<Slice<number>, ErrorResponse>> {
    if (invitation_code) {
      const res = await this.post_json(
        `/api/user/contacts?invitation_rid=${invitation_code}`,
        contacts,
      );
      return AuthenticatedClient.handleResponse(res, [
        200,
        async (r) => json(r),
      ]);
    }

    const res = await this.post_json('/api/user/contacts', contacts);

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [200, async (r) => json(r)]);
  }

  public async contacts_verify(
    rid: string,
  ): Promise<Result<void, ErrorResponse>> {
    const res = await this.post_json(`/api/user/contacts_verify/${rid}`, null);

    return AuthenticatedClient.handleResponse(res, [
      204,
      async () => Promise.resolve(),
    ]);
  }

  public async contacts_verify_request(
    email: string,
  ): Promise<Result<void, ErrorResponse>> {
    const res = await this.post_json(`/api/user/contacts_verify_request`, {
      email,
    });

    return AuthenticatedClient.handleResponse(res, [
      204,
      async () => Promise.resolve(),
    ]);
  }

  // Utilities

  private async post_json<V>(uri: string, val: V): Promise<Response> {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('PeskyProtocolVersion', this.protocol_version.toString());
    headers.append('Authorization', `Bearer ${this.access_token}`);

    return fetch(uri, {
      method: 'POST',
      headers,
      body: JSON.stringify(val),
    });
  }

  private async patch_json<V>(uri: string, val: V): Promise<Response> {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('PeskyProtocolVersion', this.protocol_version.toString());
    headers.append('Authorization', `Bearer ${this.access_token}`);

    return fetch(uri, {
      method: 'PATCH',
      headers,
      body: JSON.stringify(val),
    });
  }

  private async get<T>(uri: string): Promise<Result<T, ErrorResponse>> {
    const headers = new Headers();
    headers.append('PeskyProtocolVersion', this.protocol_version.toString());
    headers.append('Authorization', `Bearer ${this.access_token}`);

    const res = await fetch(uri, {
      headers,
    });

    if (res.status === 401) {
      this.setReadyToRender(null);
    }

    return AuthenticatedClient.handleResponse(res, [
      200,
      async (r) => json<T>(r),
    ]);
  }

  private async delete(uri: string): Promise<Response> {
    const headers = new Headers();
    headers.append('PeskyProtocolVersion', this.protocol_version.toString());
    headers.append('Authorization', `Bearer ${this.access_token}`);

    return fetch(uri, {
      method: 'DELETE',
      headers,
    });
  }

  private static async handleResponse<T>(
    res: Response,
    [success_code, handler]: [number, (res: Response) => Promise<T>],
  ): Promise<Result<T, ErrorResponse>> {
    try {
      if (res.status === success_code) {
        return Ok(await handler(res));
      }
      return Err(await json<ErrorResponse>(res));
    } catch {
      return Err({ status_code: res.status, message: `Unrecognized response` });
    }
  }
}
