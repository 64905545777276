import { useState, ChangeEventHandler, FocusEventHandler } from 'react';
import TextField from '@mui/material/TextField';

// Default max length in HTML
const DefaultMaxLength = 524288;

export type Props = {
  id: string;
  name?: string;
  label: string;
  value: string;
  required?: boolean;
  onChange: (val: string) => void;
  type?: string;
  maxLength?: number;
};

type FieldState = 'new' | 'edited' | 'edited-empty' | 'edited-invalid';

export default function EmailInput({
  id,
  name,
  label,
  value,
  required,
  onChange,
  type,
  maxLength,
}: Props) {
  const [fieldState, setFieldState] = useState<FieldState>('new');

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value);
    if (required) {
      setFieldState(e.target.value.trim() === '' ? 'edited-empty' : 'edited');
    } else {
      setFieldState('edited');
    }
  };

  const handleBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    if (
      e.target.value.trim() !== '' &&
      (e.target.value.includes(' ') || !e.target.value.includes('@'))
    ) {
      setFieldState('edited-invalid');
    }
  };

  const isError =
    fieldState === 'edited-empty' || fieldState === 'edited-invalid';

  return (
    <TextField
      id={id}
      name={name || id}
      variant="outlined"
      label={label}
      value={value}
      type={type}
      required={required}
      onChange={handleChange}
      onBlur={handleBlur}
      error={isError}
      helperText={isError ? `Please enter a valid email address` : ' '}
      inputProps={{ maxLength }}
      onKeyDown={(e: any) => {
        e.stopPropagation();
      }}
    />
  );
}

EmailInput.defaultProps = {
  required: true,
  type: undefined,
  maxLength: DefaultMaxLength,
  name: undefined,
};
