import React, { ChangeEvent, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  TextField,
  Typography,
  Skeleton,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

import moment from 'moment';

import InvitationCard, {
  InvitationCardPlaceholder,
} from '../../../components/InvitationCard';
import { MyHomeFilterSearch } from './action';
import stateReducer from './reducer';
import { INITIAL_STATE } from './state';
import { useRequiredAuthenticatedClient } from '../../../providers/AuthenticatedClientProvider';
import { ProfileSettings } from '../../../models/ProfileSettings';
import { Invitation } from '../../../models/Invitation';
import { HostGathering, InvitationGathering } from '../../../models/Gathering';
import GatheringInformation from '../../../components/GatheringInformation';
import GatheringManager from '../../../components/GatheringManager';

const profileSettings: ProfileSettings = {
  locale: 'en-US',
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

function MyHome() {
  const navigate = useNavigate();
  const client = useRequiredAuthenticatedClient();
  const [state, dispatch] = useReducer(stateReducer, INITIAL_STATE);
  const [hostGathering, setHostGathering] = useState<HostGathering | null>(
    null,
  );
  const [attendingGathering, setAttendingGathering] =
    useState<InvitationGathering | null>(null);

  useEffect(() => {
    async function load() {
      const invitations = await client.get_invitations();

      if (!invitations.ok) {
        dispatch({ type: 'error', error: invitations.error });
        return;
      }

      const gatherings = await client.get_gatherings();
      if (!gatherings.ok) {
        dispatch({ type: 'error', error: gatherings.error });
        return;
      }

      dispatch({
        type: 'loaded',
        invitations: invitations.value.data,
        gatherings: gatherings.value.data,
      });
    }
    load();
  }, [client, dispatch]);

  // use state for nextHostingGathering
  const nextHostedGathering = state.nextHostedGathering?.gatherings.find(
    (check_gathering) => check_gathering.hosting,
  );

  // use state for nextAttendingGathering
  const nextAttendingGathering = state.nextAttendingGathering?.gatherings.find(
    (check_gathering) => !check_gathering.hosting,
  );

  // use effect for fetching the specific invitation/gathering
  useEffect(() => {
    async function loadNext() {
      // fetch gathering/invititation
      if (nextHostedGathering && nextHostedGathering.hosting) {
        const { gathering } = nextHostedGathering;

        // fetch gathering
        const response = await client.get_gathering(gathering.id.toString());
        if (response.ok) {
          setHostGathering(response.value.data as HostGathering);
        } else {
          dispatch({ type: 'error', error: response.error });
          return;
        }
      }

      if (nextAttendingGathering && !nextAttendingGathering.hosting) {
        const { invitation } = nextAttendingGathering;

        // fetch invitation
        const response = await client.get_gathering(
          invitation.gathering_id.toString(),
        );
        if (response.ok) {
          setAttendingGathering(response.value.data as InvitationGathering);
        } else {
          dispatch({ type: 'error', error: response.error });
        }
      }
    }
    loadNext();
  }, [nextHostedGathering, nextAttendingGathering, client, dispatch]);

  if (!state.loaded) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" component="div">
              Your Invitations
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <TextField
              hiddenLabel
              fullWidth
              value=""
              variant="outlined"
              placeholder="Search"
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
              disabled
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            {[0, 1, 2, 3, 4].map((n) => (
              <Grid item xs={12} sm={6} lg={4} xl={3} key={`grid=${n}`}>
                <InvitationCardPlaceholder key={n} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" component="div">
              Next Gatherings
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card sx={{ height: '400px' }}>
            <CardContent>
              <Typography variant="h6">
                <Skeleton width={128} />
              </Typography>
              <Typography variant="h5">
                <Skeleton />
              </Typography>
              <Typography variant="body1" component="div">
                <Skeleton width={256} />
              </Typography>
              <Typography variant="body1" component="div">
                <Skeleton width={256} />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card sx={{ height: '400px' }}>
            <CardContent>
              <Typography variant="h6">
                <Skeleton width={128} />
              </Typography>
              <Typography variant="h5">
                <Skeleton />
              </Typography>
              <Typography variant="body1" component="div">
                <Skeleton width={256} />
              </Typography>
              <Typography variant="body1" component="div">
                <Skeleton width={256} />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  if (
    state.invitations.length === 0 &&
    state.nextAttendingGathering === undefined &&
    state.nextHostedGathering === undefined
  )
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" component="div">
              Your Invitations
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <TextField
              hiddenLabel
              fullWidth
              disabled
              value={state.filter.search}
              variant="outlined"
              placeholder="Search"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                dispatch(MyHomeFilterSearch(e.currentTarget.value))
              }
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center">
            <Typography variant="h5" component="div">
              Next Gatherings
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Box mx="auto">
            <Stack alignItems="center">
              <Typography variant="h6">
                You have no pending invitations or upcoming gatherings.
              </Typography>
              <Button
                onClick={() => navigate('/app/gatherings/add')}
                size="small"
                sx={{ textTransform: 'none' }}
              >
                <Typography variant="body1">
                  Host your own gathering!
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    );

  const nextHostedGatheringCard = () => {
    if (hostGathering !== null) {
      return (
        <Card>
          <CardActionArea
            onClick={() => navigate(`/app/gatherings/${hostGathering.id}`)}
          >
            <CardContent sx={{ padding: '20px' }}>
              <GatheringManager
                gathering={hostGathering}
                profile={profileSettings}
                client={client}
                onCancel={() => {}}
                disabled
              />
            </CardContent>
          </CardActionArea>
        </Card>
      );
    }
    return (
      <Card sx={{ height: '400px' }}>
        <CardContent>
          <Typography variant="body1">Loading...</Typography>
        </CardContent>
      </Card>
    );
  };

  const nextAttendingGatheringCard = () => {
    if (attendingGathering !== null) {
      return (
        <Card sx={{ height: '100%' }}>
          <CardActionArea
            onClick={() => navigate(`/app/gatherings/${attendingGathering.id}`)}
          >
            <CardContent sx={{ padding: '20px' }}>
              <GatheringInformation
                variant="accepted_invitation"
                title={attendingGathering.title}
                date={moment(attendingGathering.start_time)}
                endDate={
                  attendingGathering.end_time
                    ? moment(attendingGathering.end_time)
                    : null
                }
                place={attendingGathering.gathering_location}
                description={attendingGathering.description}
                hostName={`${attendingGathering.host_info.first_name} ${attendingGathering.host_info.last_name}`}
                profileSettings={profileSettings}
                gatheringStatus={attendingGathering.guest_constraints_satisfied}
                cancelled={attendingGathering.cancelled}
                cancellationNote={attendingGathering.cancellation_note}
                guests={attendingGathering.invitation.guests}
              />
            </CardContent>
          </CardActionArea>
        </Card>
      );
    }
    return (
      <Card sx={{ height: '400px' }}>
        <CardContent>
          <Typography variant="body1">Loading...</Typography>
        </CardContent>
      </Card>
    );
  };

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5" component="div">
            Your Invitations
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center">
          <TextField
            hiddenLabel
            fullWidth
            value={state.filter.search}
            variant="outlined"
            placeholder="Search"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              dispatch(MyHomeFilterSearch(e.currentTarget.value))
            }
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {state.invitations.length !== 0 ? (
          <Grid container spacing={3}>
            {state.filteredInvitations.map((invitation: Invitation) => (
              <Grid
                item
                xs={12}
                sm={6}
                lg={4}
                xl={3}
                key={`grid=${invitation.id}`}
              >
                <InvitationCard
                  key={invitation.id}
                  title={invitation.title}
                  date={moment(invitation.start_time)}
                  place={invitation.gathering_location}
                  hostName={`${invitation.host_first_name || ''} ${
                    invitation.host_last_name || ''
                  }`}
                  createdAt={invitation.created_at}
                  inviteDuration={invitation.invite_duration_minutes}
                  profileSettings={profileSettings}
                  onClick={() =>
                    navigate(`/app/gatherings/${invitation.gathering_id}`)
                  }
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="h6">You have no pending invitations.</Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5" component="div">
            Next Gatherings
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
        </Stack>
      </Grid>
      {state.nextAttendingGathering && (
        <Grid item xs={12} sm={6}>
          {nextAttendingGatheringCard()}
        </Grid>
      )}
      {state.nextHostedGathering && (
        <Grid item xs={12} sm={6}>
          {nextHostedGatheringCard()}
        </Grid>
      )}
      {!state.nextAttendingGathering && !state.nextHostedGathering && (
        <Grid item xs={12}>
          <Box mx="auto">
            <Stack alignItems="center">
              <Typography variant="h6">
                You have no upcoming gatherings.
              </Typography>
              <Button
                onClick={() => navigate('/app/gatherings/add')}
                size="small"
                sx={{ textTransform: 'none' }}
              >
                <Typography variant="body1">
                  Host your own gathering!
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}

export default MyHome;
