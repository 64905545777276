import * as React from 'react';
import Container from '@mui/material/Container';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';

import Avatar from '@mui/material/Avatar';
import MenuIcon from '@mui/icons-material/Menu';
import EventIcon from '@mui/icons-material/Event';
import GroupsIcon from '@mui/icons-material/Groups';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';

import logo from '../assets/logo.svg';
import logoLightMode from '../assets/logo-light-mode.svg';
import { useAccount } from '../contexts/AccountContext';
import { useDarkMode } from '../providers/ThemeProvider';
import Settings from './Settings';
import { useRequiredAuthenticatedClient } from '../providers/AuthenticatedClientProvider';

import { buildLogoutUrl } from '../config';

const smallScreenProp = 'md';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(!useMediaQuery(theme.breakpoints.down(smallScreenProp)) &&
    open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
}));

export default function PeskyNavigation() {
  const navigate = useNavigate();
  const theme = useTheme();
  const client = useRequiredAuthenticatedClient();
  const [profileAnchorEl, setProfileAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const profileMenuId = 'profile-menu';
  const { account } = useAccount();
  const [darkMode, setDarkMode] = useDarkMode();
  const smallScreen = useMediaQuery(theme.breakpoints.down(smallScreenProp));
  const [inviteNum, setInviteNum] = React.useState(0);

  function StartingIndex() {
    const sampleLocation = useLocation();

    switch (sampleLocation.pathname) {
      case '/app/':
        return 1;
      case '/app/home':
        return 2;
      case '/app/gatherings':
        return 3;
      case '/app/contacts':
        return 4;
      default:
        return 0;
    }
  }

  const startingLocation = StartingIndex();
  const [selectedIndex, setSelectedIndex] = React.useState(startingLocation);

  const reloadInvitations = React.useCallback(() => {
    async function load() {
      const invitations = await client.get_invitations('Pending');

      if (!invitations.ok) {
        return;
      }

      setInviteNum(invitations.value.data.length);
    }
    load();
  }, [client, setInviteNum]);

  React.useEffect(() => {
    reloadInvitations();
  }, [client, setInviteNum, selectedIndex, reloadInvitations]);

  // Profile menu (right side)
  const isProfileMenuOpen = Boolean(profileAnchorEl);

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleProfileMenuSignOut = () => {
    handleProfileMenuClose();
    navigate(buildLogoutUrl());
  };

  const firstName = account.some ? account.value.first_name : '';
  const lastName = account.some ? account.value.last_name : '';
  const letter = account.some ? account.value.first_name.substring(0, 1) : '';

  const renderProfileMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      id={profileMenuId}
      keepMounted
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem>
        <FormControlLabel
          control={
            <Switch
              checked={darkMode}
              onChange={() => setDarkMode(!darkMode)}
            />
          }
          label="Dark mode"
        />
      </MenuItem>
      <Divider />
      <Settings onOpen={handleProfileMenuClose} />
      <Divider />
      <MenuItem onClick={handleProfileMenuSignOut}>
        <ListItemIcon sx={{ paddingRight: '22px' }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText>Sign Out</ListItemText>
      </MenuItem>
    </Menu>
  );

  // Persistent app drawer
  // if small screen default to closed, otherwise default to open
  const [open, setOpen] = React.useState(!smallScreen);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const clickLogo = () => {
    setSelectedIndex(1);
    navigate('/app/');
  };

  const appBarContent = (
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerClose}
        edge="start"
        sx={{ mr: 2, ...(!open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <Button onClick={clickLogo} sx={{ ml: 2, textTransform: 'none' }}>
        <img
          src={darkMode ? logo : logoLightMode}
          className="App-logo"
          alt="logo"
        />
        <Box sx={{ mr: 2 }} />
        <Typography
          variant="h5"
          noWrap
          color="white"
          fontFamily={['Concert One', 'sans-serif'].join(',')}
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          Pesky Planet
        </Typography>
      </Button>
      <Box sx={{ flexGrow: 1 }} />
      <Button
        onClick={handleProfileMenuOpen}
        color="inherit"
        size="small"
        sx={{ mr: 0.5, pl: 1.5, pr: 1.5, textTransform: 'none' }}
      >
        <Typography
          variant="h6"
          display={{ xs: 'none', sm: 'block' }}
          sx={{ mr: 2 }}
        >
          {firstName}
        </Typography>
        <Avatar alt={`${firstName} ${lastName}`}>{letter}</Avatar>
      </Button>
    </Toolbar>
  );

  const handleNavigateHome = React.useCallback(() => {
    // If we are already on invitations screen, reload number of pending
    if (selectedIndex === 2) {
      reloadInvitations();
    }

    if (smallScreen) {
      handleDrawerClose();
    }
    setSelectedIndex(2);
    navigate('/app/home');
  }, [smallScreen, navigate, selectedIndex, reloadInvitations]);

  const handleNavigateGatherings = React.useCallback(() => {
    if (smallScreen) {
      handleDrawerClose();
    }
    setSelectedIndex(3);
    navigate('/app/gatherings');
  }, [smallScreen, navigate]);

  const handleNavigateOrbit = React.useCallback(() => {
    if (smallScreen) {
      handleDrawerClose();
    }
    setSelectedIndex(4);
    navigate('/app/contacts');
  }, [smallScreen, navigate]);

  const drawerContent = (
    <>
      <Toolbar />
      <Box height="100%">
        <List>
          <ListItem key="invitations" disablePadding>
            <ListItemButton
              onClick={handleNavigateHome}
              selected={selectedIndex === 2}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Home"
                primaryTypographyProps={{ variant: 'h6' }}
              />
              <Chip label={inviteNum} />
            </ListItemButton>
          </ListItem>
          <ListItem key="gatherings" disablePadding>
            <ListItemButton
              onClick={handleNavigateGatherings}
              selected={selectedIndex === 3}
            >
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText
                primary="Gatherings"
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="contacts" disablePadding>
            <ListItemButton
              onClick={handleNavigateOrbit}
              selected={selectedIndex === 4}
            >
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Contacts"
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );

  return (
    <Box height="100%">
      <AppBar
        sx={{
          position: 'fixed',
          width: '100%',
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        {appBarContent}
        {renderProfileMenu}
      </AppBar>
      <Drawer
        sx={{
          display: { xs: 'none', [smallScreenProp]: 'block' },
          width: drawerWidth,
          flexShrink: 1,
          border: 'none',
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRight: 'none',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        sx={{
          display: { xs: 'block', [smallScreenProp]: 'none' },
          width: drawerWidth,
          flexShrink: 1,
          border: 'none',
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRight: 'none',
          },
        }}
        variant={smallScreen ? 'temporary' : 'persistent'}
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      >
        {drawerContent}
      </Drawer>
      <Box pt={8} sx={{ flexGrow: 1, height: '100%' }}>
        <Main open={open}>
          <Container
            disableGutters
            maxWidth={false}
            sx={{ marginLeft: 0, height: '100%' }}
          >
            <Outlet />
          </Container>
        </Main>
      </Box>
    </Box>
  );
}
