// A card for displaying guest information to a host
// For displaying another guest's info to a guest, use OtherGuestCard

import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import TagChip from './TagChip';

import { Guest } from '../models/Guest';
import { Tag } from '../models/Tag';

export type Props = {
  guest: Guest;
  tags?: Tag[] | undefined; // List of all the host's orbit tags; used for formatting
  focusTags?: number[]; // IDs of tags to list first
  onClick?: () => void;
  suppressContactableWarning?: boolean;
  removable?: boolean;
  onRemove?: () => void;
};

type TagsProps = {
  guest: Guest;
  tags: Tag[];
  focusTags: number[];
};

function TagsDisplay({ guest, tags, focusTags }: TagsProps) {
  const [tagsExpanded, setTagsExpanded] = useState(false);
  const unfocused = guest.tags.filter((t) => !focusTags.includes(t));

  const focusGuestTags = focusTags
    .map((t) => tags.find((tag: Tag) => tag.id === t))
    .filter((tag): tag is Tag => tag !== undefined); // Filter out undefined values;

  const unfocusedGuestTags = unfocused
    .map((t) => tags.find((tag: Tag) => tag.id === t))
    .filter((tag): tag is Tag => tag !== undefined) // Filter out undefined values
    .sort((a, b) => a.tag.localeCompare(b.tag));

  const guestTags = [...focusGuestTags, ...unfocusedGuestTags];

  const tagChips = guestTags.map((tag: Tag) => {
    const returned: [key: number, chip: React.ReactElement] = [
      tag.id,
      <TagChip label={tag.tag} color={tag.color} margin={0.25} />,
    ];
    return returned;
  });

  const handleExpandTags = (event: React.MouseEvent<HTMLElement>) => {
    setTagsExpanded(true);
    event.stopPropagation();
  };

  return (
    <Grid item xs={12} md={8}>
      <Grid container direction="row" spacing={0.25} alignItems="center" pt={1}>
        {tagChips.slice(0, 5).map(([key, chip]) => (
          <Grid item key={key}>
            {chip}
          </Grid>
        ))}
        {!tagsExpanded && tagChips.length - 5 > 0 && (
          <IconButton onClick={handleExpandTags} sx={{ mt: 1, p: 0 }}>
            <Typography variant="caption" ml={1}>
              {`and ${tagChips.length - 5} more`}
            </Typography>
            <ExpandMoreIcon />
          </IconButton>
        )}
        {tagsExpanded &&
          tagChips.length - 5 > 0 &&
          tagChips.slice(5, tagChips.length).map(([key, chip]) => (
            <Grid item key={key}>
              {chip}
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
}

export default function GuestCard({
  guest,
  tags,
  focusTags = [],
  onClick,
  suppressContactableWarning,
  removable,
  onRemove,
}: Props) {
  const theme = useTheme();
  const fullName = `${guest.effective?.effective_first_name || ''} ${
    guest.effective?.effective_last_name || ''
  }`.trim();
  const letter = fullName.charAt(0).toUpperCase();

  return (
    <Card onClick={onClick}>
      <Box sx={{ m: 1, position: 'relative' }}>
        {!suppressContactableWarning && !guest.contactable && (
          <PriorityHighIcon
            color="warning"
            sx={{ position: 'absolute', ml: -1, mt: 2 }}
          />
        )}
        <Box ml={2}>
          <Grid container direction="row" alignItems="top" spacing={1}>
            <Grid item xs={12} md={tags ? 3 : undefined}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                {guest.linked_account_id ? (
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={
                      <CheckCircleIcon
                        style={{ fontSize: 16 }}
                        color="success"
                        sx={{
                          backgroundColor: theme.palette.background.default,
                          borderRadius: '50%',
                        }}
                      />
                    }
                  >
                    <Avatar alt={fullName}>{letter}</Avatar>
                  </Badge>
                ) : (
                  <Avatar alt={fullName}>{letter}</Avatar>
                )}
                <Typography
                  noWrap
                  sx={{
                    fontSize: {
                      xs: 'body1.fontSize', // Font size for xs screens
                      sm: 'h6.fontSize', // Font size for sm screens and up
                    },
                  }}
                >
                  {guest.effective?.effective_first_name}{' '}
                  {guest.effective?.effective_last_name}
                </Typography>
              </Stack>
            </Grid>
            {tags && (
              <TagsDisplay guest={guest} tags={tags} focusTags={focusTags} />
            )}
            {removable && (
              <Grid
                item
                xs={12}
                md={1}
                sx={{ position: 'absolute', top: 0, right: 0 }}
              >
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    if (onRemove) {
                      onRemove();
                    }
                  }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}

GuestCard.defaultProps = {
  tags: undefined,
  focusTags: [],
  onClick: () => {},
  suppressContactableWarning: false,
  removable: false,
  onRemove: () => {},
};

export function GuestCardPlaceholder() {
  return (
    <Card>
      <Box sx={{ m: 1 }}>
        <Box ml={2}>
          <Grid container direction="row" alignItems="top" spacing={1}>
            <Grid item xs={12} md={3}>
              <Stack direction="row" spacing={0.5} alignItems="center">
                <Skeleton variant="circular" width={40} height={40} />
                <Typography variant="h6" noWrap>
                  <Skeleton width={128} />
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} md={9}>
              <Grid
                container
                direction="row"
                spacing={0.25}
                alignItems="center"
                pt={1}
              >
                {[0, 1, 2, 3, 4].map((key) => (
                  <Grid item key={key}>
                    <Typography variant="body1">
                      <Skeleton
                        sx={{ borderRadius: 16, mr: 1 }}
                        width={48}
                        height={24}
                      />
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}
