import {
  Container,
  Stack,
  Typography,
  Paper,
  Box,
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';

import logo from '../assets/logo.svg';

export default function RegisterVerify() {
  const ExplainerBox = (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Box mb={1}>
        <Typography variant="h5">Welcome aboard!</Typography>
      </Box>
      <Typography variant="body1">
        Welcome to Pesky Planet, the easiest way to get people together.
      </Typography>
      <Box mt={2}>
        <Typography variant="h5">How it works</Typography>
      </Box>
      <List>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                height: '32px',
                width: '32px',
              }}
            >
              <Typography variant="h6" color="primary.contrastText">
                1
              </Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="body1">
              As a new member, start by creating your own gatherings and invite
              potential guests.
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                height: '32px',
                width: '32px',
              }}
            >
              <Typography variant="h6" color="primary.contrastText">
                2
              </Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="body1">
              Pesky Planet organizes it by sending out invitations randomly
              until the gathering has enough guests to proceed.
            </Typography>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              sx={{
                backgroundColor: 'primary.main',
                height: '32px',
                width: '32px',
              }}
            >
              <Typography variant="h6" color="primary.contrastText">
                3
              </Typography>
            </Avatar>
          </ListItemAvatar>
          <ListItemText>
            <Typography variant="body1">
              Enjoy delightful gatherings hassle-free!
            </Typography>
          </ListItemText>
        </ListItem>
      </List>
      <Box mt={2} mb={1}>
        <Typography variant="h5">What to do next</Typography>
      </Box>
      <Typography variant="body1">
        Explore your account dashboard to start organizing gatherings and manage
        your invitations effortlessly.
      </Typography>
    </Paper>
  );

  const renderRegistrationSuccess = (
    <Stack spacing={1}>
      <img src={logo} className="App-logo" alt="logo" />
      <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
        Registration Successful
      </Typography>
      {ExplainerBox}
    </Stack>
  );

  return (
    <Container>
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        {renderRegistrationSuccess}
      </Container>
    </Container>
  );
}
