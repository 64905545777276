import moment from 'moment';

import ErrorResponse from '../../../types/ErrorResponse';
import { Some, None } from '../../../types/Option';
import GuestConstraintsSatisfied from '../../../models/GuestConstraintsSatisfied';

import { FilterUpdate, MyGatheringsAction } from './action';
import { computeGroupedDisplayValues, groupGatherings } from './grouping';
import {
  FilterState,
  GatheringInvitation,
  GroupedGatherings,
  MyGatheringsState,
} from './state';

const sortGatheringsInvitations = (
  a: GatheringInvitation,
  b: GatheringInvitation,
) => {
  if (a.sortIndex < b.sortIndex) {
    return 1;
  }
  if (a.sortIndex > b.sortIndex) {
    return -1;
  }
  return 0;
};

function searchMatch(sourceText: string, search: string): boolean {
  if (search === '') {
    return true;
  }

  return (
    sourceText
      .split(' ')
      .find((part) => part.toLowerCase().startsWith(search.toLowerCase())) !==
    undefined
  );
}

function applyFilter(
  groupedGatheringsSet: GroupedGatherings[],
  filterState: FilterState,
): GroupedGatherings[] {
  return groupedGatheringsSet
    .map((group) => ({
      heading: group.heading,
      gatherings: group.gatherings.filter((g) => {
        if (g.hosting) {
          return (
            searchMatch(g.gathering.title, filterState.search) &&
            // Filter gathering by status
            ((filterState.pendingGatherings &&
              g.gathering.guest_constraints_satisfied ===
                GuestConstraintsSatisfied.NotMet) ||
              g.gathering.guest_constraints_satisfied ===
                GuestConstraintsSatisfied.Impossible ||
              (filterState.happeningGatherings &&
                g.gathering.guest_constraints_satisfied ===
                  GuestConstraintsSatisfied.Met) ||
              (filterState.cancelledGatherings && g.gathering.cancelled)) &&
            // Filter by hosted gathering toggle
            filterState.hostingGatherings
          );
        }

        return (
          searchMatch(g.invitation.title, filterState.search) &&
          // Filter invitation by gathering status
          ((filterState.pendingGatherings &&
            g.invitation.guest_constraints_satisfied ===
              GuestConstraintsSatisfied.NotMet) ||
            g.invitation.guest_constraints_satisfied ===
              GuestConstraintsSatisfied.Impossible ||
            (filterState.happeningGatherings &&
              g.invitation.guest_constraints_satisfied ===
                GuestConstraintsSatisfied.Met) ||
            (filterState.cancelledGatherings && g.invitation.cancelled)) &&
          // Filter invitation by accepted/declined
          ((filterState.acceptedInvitations &&
            g.invitation.invitation_status === 'Accepted') ||
            (filterState.declinedInvitations &&
              g.invitation.invitation_status === 'Declined'))
        );
      }),
    }))
    .filter((group) => group.gatherings.length > 0);
}

function updateFilterState(
  filterState: FilterState,
  update: FilterUpdate,
): FilterState {
  if (update.type === 'toggle') {
    return { ...filterState, ...update.update };
  }
  return { ...filterState, search: update.search };
}

export default function stateReducer(
  state: MyGatheringsState,
  action: MyGatheringsAction,
): MyGatheringsState {
  switch (action.type) {
    case 'loaded': {
      let pendingInvites = 0;
      const gatheringInvites: GatheringInvitation[] = [];
      action.invitations.forEach((invitation) => {
        if (invitation.invitation_status === 'Pending') {
          pendingInvites += 1;
        }

        gatheringInvites.push({
          hosting: false,
          sortIndex: invitation.start_time,
          invitation,
        });
      });

      action.gatherings.forEach((gathering) =>
        gatheringInvites.push({
          hosting: true,
          sortIndex: gathering.start_time,
          gathering,
        }),
      );
      gatheringInvites.sort(sortGatheringsInvitations);

      const gatheringInvitesUpcoming = gatheringInvites.filter(
        (g) => moment(g.sortIndex).endOf('day') >= moment().endOf('day'),
      );
      gatheringInvitesUpcoming.reverse();

      const groupedGatheringsUpcoming = groupGatherings(
        gatheringInvitesUpcoming,
        'future',
      );

      const gatheringInvitesPast = gatheringInvites.filter(
        (g) => moment(g.sortIndex).endOf('day') < moment().endOf('day'),
      );
      gatheringInvitesPast.reverse();
      const groupedGatheringsPast = groupGatherings(
        gatheringInvitesPast,
        'past',
      );

      const groupedGatherings = [
        ...groupedGatheringsPast,
        ...groupedGatheringsUpcoming,
      ];

      const computedGroupedDisplayGatherings =
        computeGroupedDisplayValues(groupedGatherings);

      return {
        ...state,
        loaded: true,
        pendingInvitesNum: pendingInvites,
        gatheringInvites,
        groupedGatherings,
        computedGroupedDisplayGatherings,
        error: None<ErrorResponse>(),
      };
    }
    case 'past-loaded': {
      // This is where further past events would be loaded.

      return { ...state };
    }
    case 'future-loaded': {
      // This is where further future events would be loaded.

      return { ...state };
    }
    case 'filter': {
      const { groupedGatherings, filter } = state;
      const updatedFilter = updateFilterState(filter, action.update);

      const filteredGatherings = applyFilter(groupedGatherings, updatedFilter);

      const computedGroupedDisplayGatherings =
        computeGroupedDisplayValues(filteredGatherings);

      return {
        ...state,
        filter: updatedFilter,
        computedGroupedDisplayGatherings,
      };
    }
    case 'error':
      return { ...state, loaded: true, error: Some(action.error) };
    default:
      return { ...state };
  }
}
