import { useState, useCallback, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  CircularProgress,
  Stack,
  Alert,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@mui/material';

import ErrorResponse from '../types/ErrorResponse';
import { Result } from '../types/Result';
import EmailInput from '../components/EmailInput';
import logo from '../assets/logo.svg';
import { useUnauthenticatedClient } from '../providers/UnauthenticatedClientProvider';
import ErrorDialog from '../components/ErrorDialog';

export default function ForgotPassword() {
  const [email, setEmail] = useState<string>('');
  const [pending, setPending] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [errorCode, setErrorCode] = useState(0);
  const navigate = useNavigate();
  const client = useUnauthenticatedClient();

  const handleSignIn = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      setPending(true);

      client
        .ch_pwd_request({
          primary_email: email.trim().toLowerCase(),
        })
        .then((result: Result<any, ErrorResponse>) => {
          if (result.ok) {
            setPending(false);
            setSubmitted(true);
          } else {
            setErrorMsg(result.error?.message || '');
            setPending(false);

            if (result.error.status_code === 403) {
              setErrorMsg('No account found for provided email');
            }

            if (
              result.error.status_code === 502 ||
              result.error.status_code === 504
            ) {
              setErrorCode(result.error.status_code);
            }
          }
        })
        .catch(() => {
          setErrorMsg('An unknown error has occurred');
        });
    },
    [email, client, setPending, setErrorCode],
  );

  const closeDialog = () => {
    setSubmitted(false);
    navigate('/');
  };

  const isFormComplete = useCallback(() => email.trim() !== '', [email]);

  const renderSuccess = (
    <Dialog
      open={submitted}
      onClose={(event: object, reason: string) => {
        // don't close if backdrop click
        if (reason === 'backdropClick') {
          return;
        }

        setSubmitted(false);
      }}
      disableEscapeKeyDown // Prevent closing on Escape key press
    >
      <DialogTitle>Request Successful</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <img src={logo} className="App-logo" alt="logo" />
          <Typography component="div">
            You’ve been emailed a password reset link.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} variant="contained">
          Home
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderSignIn = (
    <form onSubmit={handleSignIn}>
      <Stack spacing={1}>
        <Button onClick={() => navigate('/')} sx={{ textTransform: 'none' }}>
          <img src={logo} className="App-logo" alt="logo" />
        </Button>

        <Typography variant="h5" component="div">
          Forgot your Password?
        </Typography>
        {errorMsg !== '' && (
          <Alert severity="error">{errorMsg.toString()}</Alert>
        )}
        <Typography variant="body1" component="div">
          To reset your password, please enter the email address of your Pesky
          account.
        </Typography>
        <EmailInput
          id="email"
          label="Email"
          value={email}
          type="email"
          onChange={(val: string) => setEmail(val)}
        />
        {!pending && (
          <Button
            type="submit"
            disabled={!isFormComplete()}
            variant="contained"
          >
            Submit
          </Button>
        )}
        {pending && <CircularProgress />}
        <Button
          onClick={() => navigate('/auth/register')}
          sx={{ textTransform: 'none' }}
        >
          Don&apos;t have an account? Sign up
        </Button>
      </Stack>
    </form>
  );

  return (
    <Container>
      <Container maxWidth="xs" sx={{ mt: 4, mb: 4 }}>
        <ErrorDialog open={errorCode !== 0} code={errorCode} home />
        {renderSignIn}
        {renderSuccess}
      </Container>
    </Container>
  );
}
