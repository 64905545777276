import React, { useMemo, useState } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { paletteBase, paletteLightMode, paletteDarkMode } from './palletes';

interface ThemeConfiguration {
  darkMode: boolean;
  setDarkMode: (darkMode: boolean) => void;
}

const ThemeContext = React.createContext<ThemeConfiguration>({
  darkMode: false,
  setDarkMode: () => {},
});

export default function ThemeProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState(prefersDarkMode);

  const theme = useMemo(
    () =>
      createTheme({
        components: !darkMode
          ? {
              MuiAvatar: {
                styleOverrides: {
                  root: {
                    background: paletteLightMode.heliotropeGray,
                  },
                },
              },
              MuiButton: {
                styleOverrides: {
                  root: {
                    textTransform: 'none',
                  },
                },
              },
              MuiInputBase: {
                styleOverrides: {
                  root: {
                    background: '#fff',
                  },
                },
              },
              MuiTab: {
                styleOverrides: {
                  root: {
                    textTransform: 'none',
                    justifyContent: 'left',
                    minHeight: 'auto',
                  },
                },
              },
            }
          : {
              MuiButton: {
                styleOverrides: {
                  root: {
                    textTransform: 'none',
                  },
                },
              },
              MuiTab: {
                styleOverrides: {
                  root: {
                    textTransform: 'none',
                    justifyContent: 'left',
                    minHeight: 'auto',
                  },
                },
              },
            },
        palette: !darkMode
          ? {
              mode: 'light',
              primary: {
                main: paletteBase.cadmiumOrange,
                contrastText: paletteBase.snow,
              },
              secondary: {
                main: paletteLightMode.blue,
                contrastText: paletteBase.snow,
              },
              error: {
                main: paletteLightMode.frenchRaspberry,
                contrastText: paletteBase.snow,
              },
              success: {
                main: paletteLightMode.veridian,
              },
              background: {
                default: paletteBase.snow,
              },
            }
          : {
              mode: 'dark',
              primary: {
                main: paletteBase.cadmiumOrange,
                contrastText: paletteBase.snow,
              },
              secondary: {
                main: paletteDarkMode.starCommandBlue,
                contrastText: paletteBase.snow,
              },
              error: {
                main: paletteLightMode.frenchRaspberry,
                contrastText: paletteBase.snow,
              },
              success: {
                main: paletteDarkMode.cambridgeBlue,
              },
              background: {
                default: paletteDarkMode.jet,
              },
            },
        typography: {
          fontFamily: ['Lato', 'sans-serif'].join(','),
        },
      }),
    [darkMode],
  );

  const contextValue = useMemo(
    () => ({ darkMode, setDarkMode }),
    [darkMode, setDarkMode],
  );

  return (
    <ThemeContext.Provider value={contextValue}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}

export function useDarkMode() {
  const { darkMode, setDarkMode } = React.useContext(ThemeContext);
  return [
    darkMode,
    (mode: boolean) => {
      setDarkMode(mode);
    },
  ] as [boolean, (v: boolean) => void];
}
