import { Invitation } from '../../../models/Invitation';
import { ListedGathering } from '../../../models/ListedGathering';
import ErrorResponse from '../../../types/ErrorResponse';
import { Option, None } from '../../../types/Option';

export type GatheringInvitation =
  | { hosting: true; sortIndex: string; gathering: ListedGathering }
  | { hosting: false; sortIndex: string; invitation: Invitation };

export type GroupedGatherings = {
  heading: string;
  gatherings: GatheringInvitation[];
};

export type DisplayGathering = GatheringInvitation | 'placeholder' | 'blank';

export function isGatheringInvitation(
  gathering: DisplayGathering,
): gathering is GatheringInvitation {
  return (gathering as GatheringInvitation).sortIndex !== undefined;
}

export type FilterState = {
  search: string;
};

export type MyHomeState = {
  loaded: boolean;
  error: Option<ErrorResponse>;
  invitations: Invitation[];
  nextHostedGathering: GroupedGatherings | undefined;
  nextAttendingGathering: GroupedGatherings | undefined;
  filter: FilterState;
  filteredInvitations: Invitation[];
};

export const INITIAL_STATE: MyHomeState = {
  loaded: false,
  error: None<ErrorResponse>(),
  invitations: [],
  nextHostedGathering: undefined,
  nextAttendingGathering: undefined,
  filter: {
    search: '',
  },
  filteredInvitations: [],
};
