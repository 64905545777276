import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useSession } from '../contexts/Session';
import AuthenticatedClient from '../api/AuthenticatedClient';
import PeskyNavigationSkeleton from '../components/PeskyNavigationSkeleton';

interface AuthenticatedClientType {
  client: AuthenticatedClient | null;
}

const AuthenticatedClientContext = React.createContext<AuthenticatedClientType>(
  { client: null },
);

export function AuthenticatedClientProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { session, readyToRender, setReadyToRender } = useSession();
  const { pathname } = useLocation();

  const value = React.useMemo(
    () => ({
      client: session.accessToken
        ? new AuthenticatedClient(session.accessToken, setReadyToRender)
        : null,
    }),
    [session, setReadyToRender],
  );

  if (session.accessToken === null) {
    return <Navigate to="/auth/sign_in" />;
  }

  if (!pathname.startsWith('/auth/sign_out') && readyToRender === null) {
    return (
      <AuthenticatedClientContext.Provider value={value}>
        <PeskyNavigationSkeleton />
      </AuthenticatedClientContext.Provider>
    );
  }

  return (
    <AuthenticatedClientContext.Provider value={value}>
      {children}
    </AuthenticatedClientContext.Provider>
  );
}

export function useRequiredAuthenticatedClient() {
  const { client } = React.useContext(AuthenticatedClientContext);
  if (client === null) {
    throw Error(
      'An AuthenticatedClient is required. Wrap this element in a AuthenticatedClientProvider',
    );
  }
  return client;
}

export function useAuthenticatedClient() {
  const { client } = React.useContext(AuthenticatedClientContext);
  return client;
}
