import { Data } from '../types/Data';
import ErrorResponse from '../types/ErrorResponse';
import { Ok, Err, Result } from '../types/Result';
import { LoginToken } from '../models/LoginToken';

/// Gets us a type-safe json parse response
async function json<T>(res: Response): Promise<T> {
  const val: T = await res.json();
  return val;
}

export const PROTOCOL_VERSION = 12;

export class AuthClient {
  accessToken: string;

  refreshToken: string;

  constructor(accessToken: string, refreshToken: string) {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  }

  public async refresh_tokens(): Promise<Result<any, ErrorResponse>> {
    const headers = new Headers();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('PeskyProtocolVersion', PROTOCOL_VERSION.toString());
    headers.append('Authorization', `Bearer ${this.accessToken}`);

    const response = await fetch(`/api/auth/token`, {
      method: 'POST',
      body: JSON.stringify({ refresh_token: this.refreshToken }),
      headers,
    });

    try {
      if (response.status === 200) {
        const result: Data<LoginToken> = await json(response);
        return Ok(result.data);
      }
      return Err(await json<ErrorResponse>(response));
    } catch {
      return Err({
        status_code: response.status,
        message: `Unrecognized response`,
      });
    }
  }
}
