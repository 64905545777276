import { useState, useEffect, useReducer, useCallback } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  IconButton,
  Pagination,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import PersonAdd from '@mui/icons-material/PersonAdd';
import WarningIcon from '@mui/icons-material/Warning';

import { useRequiredAuthenticatedClient } from '../../providers/AuthenticatedClientProvider';

import ErrorResponse from '../../types/ErrorResponse';
import { Option, Some, None } from '../../types/Option';
import { Result } from '../../types/Result';
import { Guest } from '../../models/Guest';
import { Tag } from '../../models/Tag';

import ErrorDialog from '../../components/ErrorDialog';
import GuestCard, { GuestCardPlaceholder } from '../../components/GuestCard';
import GuestInfoDialog from '../../components/GuestInfoDialog';
import {
  SearchableMultiSelectProvider,
  SearchableMultiSelectMenu,
  Selectable,
  useSearchableMultiSelectDispatch,
} from '../../components/SearchableMultiSelect';
import ManageTagsDialog from '../../components/ManageTagsDialog';
import TagChip from '../../components/TagChip';

// A wrapper to make a tag selectable
class SelectableTag implements Selectable {
  tag: Tag;

  constructor(tag: Tag) {
    this.tag = tag;
  }

  display() {
    return (
      <TagChip key={this.tag.id} label={this.tag.tag} color={this.tag.color} />
    );
  }

  key() {
    return this.tag.id;
  }

  value() {
    return this.tag.tag;
  }
}

type ContactsState = {
  orbit: Guest[];
  tags: Tag[];
  selectedTags: Tag[];
  currentGuestTags: Tag[];
  searchText: Option<string>;
  searchResults: Guest[];
  tagMap: Map<number, Guest[]>;
  page: number;

  // remote-call related state
  loaded: boolean;
  error: Option<ErrorResponse>;
};

const initialState: ContactsState = {
  orbit: [],
  tags: [],
  selectedTags: [],
  currentGuestTags: [],
  searchText: None(),
  searchResults: [],
  tagMap: new Map<number, Guest[]>(),
  page: 1,
  loaded: false,
  error: None(),
};

type ContactsAction =
  | { type: 'loaded'; orbit: Guest[]; tags: Tag[] }
  | { type: 'reload'; orbit: Guest[]; tags: Tag[] }
  | { type: 'refreshTags'; tags: Tag[] }
  | { type: 'refreshGuestTags'; tags: Tag[] }
  | { type: 'search'; searchText: string }
  | { type: 'tagsSelected'; tags: Tag[] }
  | { type: 'tagRemoved'; tag: Tag }
  | { type: 'page'; page: number }
  | { type: 'error'; error: ErrorResponse };

function stateReducer(
  state: ContactsState,
  action: ContactsAction,
): ContactsState {
  function applySearch(searchText: string, tags: Tag[]): Guest[] {
    const { orbit, tagMap } = state;

    let searchResults: Guest[] = [];
    if (tags.length === 0) {
      searchResults = orbit;
    } else {
      searchResults = Array.from(
        tags.reduce((acc, tag) => {
          tagMap.get(tag.id)?.forEach((guest) => acc.add(guest));
          return acc;
        }, new Set<Guest>()),
      );
    }

    if (searchText !== '') {
      searchResults = searchResults.filter(
        (guest) =>
          guest.effective?.effective_first_name
            ?.toLowerCase()
            ?.startsWith(searchText.toLowerCase()) ||
          guest.effective?.effective_last_name
            ?.toLowerCase()
            ?.startsWith(searchText.toLowerCase()) ||
          `${guest.effective?.effective_first_name?.toLowerCase() || ''} ${
            guest.effective?.effective_last_name?.toLowerCase() || ''
          }`.startsWith(searchText.toLowerCase()),
      );
    }

    return searchResults;
  }

  switch (action.type) {
    case 'loaded': {
      const { orbit, tags } = action;
      const tagMap = new Map<number, Guest[]>();

      orbit.forEach((guest) => {
        guest.tags.forEach((tag) => {
          const tagGuests = tagMap.get(tag);
          tagMap.set(
            tag,
            tagGuests !== undefined ? [...tagGuests, guest] : [guest],
          );
        });
      });

      return {
        loaded: true,
        orbit,
        tags,
        selectedTags: [],
        currentGuestTags: [],
        searchResults: action.orbit,
        searchText: None(),
        tagMap,
        page: 1,
        error: None(),
      };
    }
    case 'reload': {
      const { orbit, tags } = action;
      const { searchText, selectedTags, searchResults } = state;
      const tagMap = new Map<number, Guest[]>();

      orbit.forEach((guest) => {
        guest.tags.forEach((tag) => {
          const tagGuests = tagMap.get(tag);
          tagMap.set(
            tag,
            tagGuests !== undefined ? [...tagGuests, guest] : [guest],
          );
        });
      });

      return {
        loaded: true,
        orbit,
        tags,
        selectedTags,
        currentGuestTags: [],
        searchResults,
        searchText,
        tagMap,
        page: 1,
        error: None(),
      };
    }
    case 'refreshTags':
      return { ...state, tags: action.tags };
    case 'refreshGuestTags':
      return { ...state, currentGuestTags: action.tags };
    case 'search': {
      const { searchText } = action;
      const { selectedTags } = state;

      const searchResults = applySearch(searchText, selectedTags);

      return {
        ...state,
        searchText: Some(searchText),
        searchResults,
        page: 1,
      };
    }
    case 'tagsSelected': {
      const { tags } = action;
      const { searchText } = state;
      const searchResults = applySearch(searchText.unwrapOr(''), tags);

      return {
        ...state,
        searchResults,
        selectedTags: tags,
        page: 1,
      };
    }
    case 'tagRemoved': {
      const { tag } = action;
      const { selectedTags, searchText } = state;

      const updatedTags = selectedTags.filter((t) => t.id !== tag.id);
      const searchResults = applySearch(searchText.unwrapOr(''), updatedTags);

      return {
        ...state,
        searchResults,
        selectedTags: updatedTags,
        page: 1,
      };
    }
    case 'page':
      return { ...state, page: action.page };
    case 'error':
      return { ...state, loaded: true, error: Some(action.error) };
    default:
      return { ...state };
  }
}

function ContactsFilterControlsInner({
  selectedTags,
  searchText,
  dispatch,
  disabled,
}: {
  selectedTags: Tag[];
  searchText: Option<string>;
  dispatch: (action: ContactsAction) => void;
  disabled: boolean;
}) {
  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => dispatch({ type: 'search', searchText: event.currentTarget.value });
  const searchableMultiSelectDispatch = useSearchableMultiSelectDispatch();

  return (
    <>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={8}>
          <TextField
            hiddenLabel
            fullWidth
            value={searchText.unwrapOr('')}
            variant="outlined"
            placeholder="Search"
            onChange={handleInputChange}
            disabled={disabled}
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SearchableMultiSelectMenu
            id="tag-select"
            label="Tags"
            disabled={disabled}
            onSelection={(selected: SelectableTag[]) => {
              dispatch({
                type: 'tagsSelected',
                tags: selected.map((s) => s.tag),
              });
            }}
            onEmpty="You currently have no tags"
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        {selectedTags.map((tag) => (
          <TagChip
            key={tag.id}
            label={tag.tag}
            color={tag.color}
            onDelete={() => {
              dispatch({ type: 'tagRemoved', tag });
              searchableMultiSelectDispatch({
                type: 'selection',
                key: tag.id,
                selected: false,
              });
            }}
            margin={0.25}
          />
        ))}
      </Box>
    </>
  );
}

function ContactsFilterControls({
  tags,
  selectedTags,
  searchText,
  dispatch,
  disabled = false,
}: {
  tags: Tag[];
  selectedTags: Tag[];
  searchText: Option<string>;
  dispatch: (action: ContactsAction) => void;
  disabled?: boolean;
}) {
  return (
    <SearchableMultiSelectProvider
      options={tags.map((t) => new SelectableTag(t))}
      selectedOptions={[]}
    >
      <ContactsFilterControlsInner
        selectedTags={selectedTags}
        searchText={searchText}
        dispatch={dispatch}
        disabled={disabled}
      />
    </SearchableMultiSelectProvider>
  );
}

ContactsFilterControls.defaultProps = {
  disabled: false,
};

export default function Contacts() {
  const client = useRequiredAuthenticatedClient();
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const [activeGuest, setActiveGuest] = useState<Guest | null>(null);
  const [guestToRemove, setGuestToRemove] = useState<Guest | null>(null);
  const [guestViewOpen, setGuestViewOpen] = useState<boolean>(false);

  const PAGE_SIZE = 20;

  // Setup

  useEffect(() => {
    async function load() {
      const orbitResult = await client.get_guests();
      if (!orbitResult.ok) {
        dispatch({ type: 'error', error: orbitResult.error });
        return;
      }

      const tagsResult = await client.get_tags();
      if (!tagsResult.ok) {
        dispatch({ type: 'error', error: tagsResult.error });
        return;
      }
      dispatch({
        type: 'loaded',
        orbit: orbitResult.value.data,
        tags: tagsResult.value.data,
      });
    }
    load();
  }, [client, dispatch]);

  // Handlers

  // Refresh tags after a tag update
  const refreshTags = useCallback(() => {
    async function reloadTags() {
      const tagsResult = await client.get_tags();
      if (!tagsResult.ok) {
        dispatch({ type: 'error', error: tagsResult.error });
        return;
      }
      dispatch({
        type: 'refreshTags',
        tags: tagsResult.value.data,
      });

      // Refresh selected tags
      if (state.selectedTags.length > 0) {
        dispatch({ type: 'tagsSelected', tags: state.selectedTags });
      }
    }
    reloadTags();
  }, [client, dispatch, state.selectedTags]);

  // Refresh tags after dynamically adding a new tag
  const refreshGuestTags = useCallback(
    (guestTags: number[]) => {
      async function reloadTags() {
        const tagsResult = await client.get_tags();
        if (!tagsResult.ok) {
          dispatch({ type: 'error', error: tagsResult.error });
          return;
        }
        dispatch({
          type: 'refreshTags',
          tags: tagsResult.value.data,
        });
        dispatch({
          type: 'refreshGuestTags',
          tags: tagsResult.value.data.filter((tag) =>
            guestTags.includes(tag.id),
          ),
        });
      }
      reloadTags();
    },
    [client, dispatch],
  );

  const reloadTagsAndGuests = useCallback(() => {
    async function reload() {
      const orbitResult = await client.get_guests();
      if (!orbitResult.ok) {
        dispatch({ type: 'error', error: orbitResult.error });
        return;
      }

      const tagsResult = await client.get_tags();
      if (!tagsResult.ok) {
        dispatch({ type: 'error', error: tagsResult.error });
        return;
      }
      dispatch({
        type: 'reload',
        orbit: orbitResult.value.data,
        tags: tagsResult.value.data,
      });
    }
    reload();
  }, [client, dispatch]);

  const closeGuestView = useCallback(
    (needReload: boolean, newGuest: Guest | null) => {
      async function reload() {
        const orbitResult = await client.get_guests();
        if (!orbitResult.ok) {
          dispatch({ type: 'error', error: orbitResult.error });
          return;
        }

        const tagsResult = await client.get_tags();
        if (!tagsResult.ok) {
          dispatch({ type: 'error', error: tagsResult.error });
          return;
        }
        dispatch({
          type: 'reload',
          orbit: orbitResult.value.data,
          tags: tagsResult.value.data,
        });

        if (newGuest) {
          setActiveGuest(newGuest);
          dispatch({
            type: 'refreshGuestTags',
            tags: tagsResult.value.data.filter((tag) =>
              newGuest.tags.includes(tag.id),
            ),
          });
        } else {
          setGuestViewOpen(false);
          setActiveGuest(null);
          dispatch({
            type: 'refreshGuestTags',
            tags: [],
          });
        }
      }
      if (needReload) {
        reload().then(() => {
          dispatch({
            type: 'search',
            searchText: state.searchText.unwrapOr(''),
          });
        });
      } else {
        setGuestViewOpen(false);
        setActiveGuest(null);
        dispatch({
          type: 'refreshGuestTags',
          tags: [],
        });
      }
    },
    [client, dispatch, setGuestViewOpen, state],
  );

  // Display guest details dialog on click
  const handleDisplayGuestDialog = (guest: Guest, tags: Tag[]) => {
    setActiveGuest(guest);
    dispatch({
      type: 'refreshGuestTags',
      tags: tags.filter((tag) => guest.tags.includes(tag.id)),
    });
    setGuestViewOpen(true);
  };

  const handleAddGuestDialog = () => {
    dispatch({
      type: 'refreshGuestTags',
      tags: [],
    });
    setActiveGuest(null);
    setGuestViewOpen(true);
  };

  const deleteGuest = useCallback(() => {
    async function reload() {
      const orbitResult = await client.get_guests();
      if (!orbitResult.ok) {
        dispatch({ type: 'error', error: orbitResult.error });
        return;
      }

      const tagsResult = await client.get_tags();
      if (!tagsResult.ok) {
        dispatch({ type: 'error', error: tagsResult.error });
        return;
      }
      dispatch({
        type: 'reload',
        orbit: orbitResult.value.data,
        tags: tagsResult.value.data,
      });
    }

    client
      .delete_guest(guestToRemove?.id || 0, true)
      .then((result: Result<void, ErrorResponse>) => {
        if (result.ok) {
          setGuestToRemove(null);
          reload().then(() => {
            dispatch({
              type: 'search',
              searchText: state.searchText.unwrapOr(''),
            });
          });
        } else {
          dispatch({ type: 'error', error: result.error });
        }
      })
      .catch(() => {
        dispatch({
          type: 'error',
          error: { status_code: 500, message: 'An unknown error has occurred' },
        });
      });
  }, [client, guestToRemove, dispatch, state]);

  const removalDialog = (
    <Dialog open={guestToRemove !== null}>
      <DialogTitle textAlign="center">
        <div>
          <WarningIcon />
        </div>
        <div>Remove guest?</div>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body1">
            {`Are you sure you want to remove ${
              guestToRemove?.effective?.effective_first_name || ''
            }?`}
          </Typography>
          <Typography variant="body2">
            If this guest is invited to upcoming gatherings, they will be
            uninvited.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            deleteGuest();
          }}
        >
          Remove Guest
        </Button>
        <Button onClick={() => setGuestToRemove(null)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  // Renderers

  const renderEmptyListContent = (
    <Box maxWidth={600} my={3} mx="auto">
      <Stack alignItems="center">
        <Typography variant="h6" component="div">
          Hey, you don&apos;t have any contacts at the moment.
        </Typography>
        <Button
          onClick={() => handleAddGuestDialog()}
          sx={{ textTransform: 'none' }}
          aria-label="Add Contact"
        >
          <Typography variant="body1">Add a few friends!</Typography>
        </Button>
      </Stack>
    </Box>
  );

  // Return

  if (!state.loaded) {
    return (
      <>
        <Stack direction="row" alignItems="center">
          <Typography variant="h5" component="div">
            Contacts
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton color="primary" disabled>
            <PersonAdd style={{ fontSize: 32 }} />
          </IconButton>
        </Stack>
        <Box mt={1} />
        <ContactsFilterControls
          key={0}
          disabled
          tags={[]}
          selectedTags={[]}
          searchText={None()}
          dispatch={dispatch}
        />
        <Box mt={2}>
          <Grid container>
            {[0, 1, 2, 3, 4, 5, 6, 7].map((key) => (
              <Grid item key={key} xs={12} pb={1}>
                <GuestCardPlaceholder />
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    );
  }

  // This endpoint should only return 400 or 500 error codes
  if (state.error.some) {
    return (
      <ErrorDialog
        open={state.error.some}
        code={
          state.error.some && state.error.value.status_code
            ? state.error.value.status_code
            : 0
        }
      />
    );
  }

  const { orbit, tags, searchResults, searchText, page, selectedTags } = state;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    dispatch({ type: 'page', page: value });
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Typography variant="h5" component="div">
          Contacts
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <ManageTagsDialog
          tags={tags}
          tagMap={state.tagMap}
          client={client}
          onError={(e: ErrorResponse) => dispatch({ type: 'error', error: e })}
          onTagAddSuccess={refreshTags}
          onTagUpdateSuccess={refreshTags}
          onTagDeleteSuccess={reloadTagsAndGuests}
        />
        <Tooltip title="Add Contact">
          <IconButton
            onClick={() => handleAddGuestDialog()}
            aria-label="Add Contact"
            color="primary"
          >
            <PersonAdd style={{ fontSize: 32 }} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Box mt={1} />
      <ContactsFilterControls
        key={1}
        tags={tags.sort((a, b) => a.tag.localeCompare(b.tag))}
        selectedTags={state.selectedTags}
        searchText={searchText}
        disabled={orbit.length === 0}
        dispatch={dispatch}
      />
      {searchResults.length < orbit.length && (
        <Typography variant="body2" mt={1}>
          {`Showing ${searchResults.length} of ${orbit.length} contact${
            orbit.length !== 1 ? 's' : ''
          } via ${searchText.some && searchText.value !== '' ? 'search' : ''}${
            searchText.some &&
            searchText.value !== '' &&
            selectedTags.length > 0
              ? ' and '
              : ''
          }${selectedTags.length > 0 ? 'tag filtering' : ''}`}
        </Typography>
      )}
      {searchResults.length === orbit.length && (
        <Typography variant="body2" mt={1}>
          {`Showing ${orbit.length} contact${orbit.length !== 1 ? 's' : ''}`}
        </Typography>
      )}
      <Box mt={2}>
        {orbit.length === 0 ? renderEmptyListContent : null}
        <Grid container>
          {searchResults
            .slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE)
            .map((guest: Guest) => (
              <Grid item key={guest.id} xs={12} pb={1}>
                <GuestCard
                  guest={guest}
                  focusTags={selectedTags.map((t) => t.id)}
                  tags={tags}
                  onClick={() => handleDisplayGuestDialog(guest, tags)}
                  removable
                  onRemove={() => setGuestToRemove(guest)}
                />
              </Grid>
            ))}
          {searchResults.length === 0 && orbit.length > 0 && (
            <Grid item key={1} xs={12}>
              <Typography variant="h6">No search results</Typography>
            </Grid>
          )}
        </Grid>
        {searchResults.length > PAGE_SIZE && (
          <Pagination
            count={Math.ceil(searchResults.length / PAGE_SIZE)}
            page={page}
            shape="rounded"
            onChange={handlePageChange}
            sx={{ mt: 2 }}
          />
        )}
      </Box>
      <GuestInfoDialog
        open={guestViewOpen}
        guest={activeGuest}
        orbitTags={tags}
        onClose={closeGuestView}
        currentTags={state.currentGuestTags}
        refreshGuestTags={refreshGuestTags}
      />
      {removalDialog}
    </>
  );
}
