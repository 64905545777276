import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Container,
  Stack,
  Alert,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';

import ErrorResponse from '../../types/ErrorResponse';
import { Result } from '../../types/Result';
import logo from '../../assets/logo.svg';
import { useAuthenticatedClient } from '../../providers/AuthenticatedClientProvider';
import { buildLoginUrl } from '../../config';

export default function ContactVerify() {
  const [pending, setPending] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [title, setTitle] = useState('Verifying Registration');
  const { code } = useParams() as { code: string };
  const navigate = useNavigate();
  const client = useAuthenticatedClient();

  useEffect(() => {
    async function contactVerify() {
      if (client) {
        client
          .contacts_verify(code)
          .then((result: Result<any, ErrorResponse>) => {
            if (result.ok) {
              navigate('/app/contacts_verify_success');
            } else if (result.error.status_code === 500) {
              setTitle(
                'Something went wrong. Unable to verify contact. Request a new verifiation email in settings and try again',
              );
              setPending(false);
            } else {
              setTitle(
                `${result.error?.message}. Unable to verify contact. Request a new verifiation email in settings and try again`,
              );
              setPending(false);
            }
          });
      } else {
        navigate(buildLoginUrl(`/contacts_verify/${code}`));
      }
    }
    contactVerify();
  }, [client, setTitle, setErrorMsg, code, navigate]);

  const renderContactVerify = (
    <Stack
      spacing={1}
      sx={{
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Button onClick={() => navigate('/')} sx={{ textTransform: 'none' }}>
        <img src={logo} className="App-logo" alt="logo" />
      </Button>
      <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
        {title}
      </Typography>
      {errorMsg !== '' && <Alert severity="error">{errorMsg.toString()}</Alert>}
      {pending && <CircularProgress />}
      {!pending && (
        <Button variant="contained" onClick={() => navigate('/')}>
          Home
        </Button>
      )}
    </Stack>
  );

  return (
    <Container>
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        {renderContactVerify}
      </Container>
    </Container>
  );
}
