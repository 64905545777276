import {
  useRef,
  useState,
  MutableRefObject,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import { Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import {
  SearchableMultiSelectProvider,
  SearchableMultiSelectMenu,
  Selectable,
  Action,
} from './SearchableMultiSelect';
import { Guest } from '../models/Guest';
import { Tag } from '../models/Tag';
import TagChip from './TagChip';
import GuestCard from './GuestCard';
import GuestInfoDialog from './GuestInfoDialog';

// A wrapper to make a tag selectable
class SelectableTag implements Selectable {
  tag: Tag;

  constructor(tag: Tag) {
    this.tag = tag;
  }

  display() {
    const { tag, color } = this.tag;
    return <TagChip key={tag} label={tag} color={color} />;
  }

  key() {
    return this.tag.id;
  }

  value() {
    return this.tag.tag;
  }
}

/* eslint max-classes-per-file: ["error", 2] */
// A wrapper to make a Guest selectable
export class SelectableGuest implements Selectable {
  guest: Guest;

  constructor(guest: Guest) {
    this.guest = guest;
  }

  display() {
    const lastName =
      this.guest?.effective?.effective_last_name || this.guest?.last_name || '';

    const firstName =
      this.guest?.effective?.effective_first_name ||
      this.guest?.first_name ||
      '';

    return (
      <>
        <Avatar
          alt={`${firstName} ${lastName}`}
          sx={{ height: '20px', width: '20px', fontSize: '1rem' }}
        >
          {firstName.charAt(0).toUpperCase()}
        </Avatar>
        <Typography variant="body1">
          &nbsp; {firstName} {lastName}
        </Typography>
      </>
    );
  }

  key() {
    return this.guest.id;
  }

  value() {
    const lastName =
      this.guest?.effective?.effective_last_name || this.guest?.last_name || '';

    const firstName =
      this.guest?.effective?.effective_first_name ||
      this.guest?.first_name ||
      '';
    return `${firstName || ''} ${lastName || ''}`;
  }
}

function TagsInner({
  orbit,
  setSelectedGuests,
  selectedSearchGuests,
  setNonContactableSelectedGuests,
  setSelectedTags,
}: {
  orbit: Guest[];
  setSelectedGuests: (guests: Guest[]) => void;
  selectedSearchGuests: SelectableGuest[];
  setNonContactableSelectedGuests: Dispatch<SetStateAction<Guest[]>>;
  setSelectedTags: Dispatch<SetStateAction<Tag[]>>;
}) {
  const onTagsChange = (values: SelectableTag[]) => {
    const newTags: Tag[] = [];
    values.forEach((value) => {
      // get all users that have `value.name` in their list of tags and are
      // not already in the `currentGuests` array
      newTags.push(value.tag);
    });
    const currentGuests: Guest[] = [];
    selectedSearchGuests.forEach((value) => {
      const selectedUser = orbit.find(
        (user) =>
          user.id === value.key() &&
          !currentGuests.some(
            (currentGuest: Guest) => currentGuest.id === user.id,
          ),
      );
      if (selectedUser) {
        currentGuests.push(selectedUser);
      }
    });

    newTags.forEach((value) => {
      // get all users that have `value.name` in their list of tags and are
      // not already in the `currentGuests` array
      const users = orbit.filter(
        (user) =>
          user.tags.includes(value.id) &&
          !currentGuests.some(
            (selectedGuest: Guest) => selectedGuest.id === user.id,
          ),
      );
      users.forEach((user) => currentGuests.push(user));
    });
    const nonContactableGuests = currentGuests.filter(
      (guest) => guest.contactable === false,
    );
    if (nonContactableGuests.length !== 0) {
      setNonContactableSelectedGuests(nonContactableGuests);
    } else {
      setNonContactableSelectedGuests([]);
    }
    setSelectedGuests(currentGuests);
    setSelectedTags(newTags);
  };

  return (
    <SearchableMultiSelectMenu
      id="tag-select"
      label="Add Tags"
      labelIcon={<AddIcon />}
      onSelection={onTagsChange}
      onEmpty="You currently have no tags"
    />
  );
}

function GuestsInner({
  orbit,
  setSelectedGuests,
  setNonContactableSelectedGuests,
  setSelectedSearchGuests,
  selectedTags,
}: {
  orbit: Guest[];
  setSelectedGuests: (guests: Guest[]) => void;
  setNonContactableSelectedGuests: Dispatch<SetStateAction<Guest[]>>;
  setSelectedSearchGuests: Dispatch<SetStateAction<SelectableGuest[]>>;
  selectedTags: Tag[];
}) {
  const onGuestChange = (values: SelectableGuest[]) => {
    const currentGuests: Guest[] = [];
    values.forEach((value) => {
      const selectedUser = orbit.find(
        (user) =>
          user.id === value.key() &&
          !currentGuests.some(
            (currentGuest: Guest) => currentGuest.id === user.id,
          ),
      );
      if (selectedUser) {
        currentGuests.push(selectedUser);
      }
    });

    selectedTags.forEach((value) => {
      // get all users that have `value.name` in their list of tags and are
      // not already in the `currentGuests` array
      const users = orbit.filter(
        (user) =>
          user.tags.includes(value.id) &&
          !currentGuests.some(
            (selectedGuest: Guest) => selectedGuest.id === user.id,
          ),
      );
      users.forEach((user) => currentGuests.push(user));
    });
    const nonContactableGuests = currentGuests.filter(
      (guest) => guest.contactable === false,
    );
    if (nonContactableGuests.length !== 0) {
      setNonContactableSelectedGuests(nonContactableGuests);
    } else {
      setNonContactableSelectedGuests([]);
    }
    setSelectedSearchGuests(values);
    setSelectedGuests(currentGuests);
  };

  return (
    <SearchableMultiSelectMenu
      id="guest-select"
      label="Add guests"
      labelIcon={<AddIcon />}
      onSelection={onGuestChange}
      onEmpty="You currently have no guests"
    />
  );
}

export type Props = {
  orbit: Guest[];
  orbitTags: Tag[];
  selectedGuests: Guest[];
  setSelectedGuests: (guests: Guest[]) => void;
  nonContactableSelectedGuests: Guest[];
  setNonContactableSelectedGuests: Dispatch<SetStateAction<Guest[]>>;
  customDispatchGuestRef?: MutableRefObject<Dispatch<
    Action<Selectable>
  > | null>;
  hideSummary?: boolean;
  onReload?: (newGuest: Guest) => void;
  onReloadTags?: (guestTags: number[]) => void;
  newGuestTags: Tag[];
};

function UsersAndTags(props: Props) {
  const {
    orbit,
    orbitTags,
    selectedGuests,
    setSelectedGuests,
    nonContactableSelectedGuests,
    setNonContactableSelectedGuests,
    customDispatchGuestRef,
    hideSummary,
    onReload,
    onReloadTags,
    newGuestTags,
  } = props;
  const dispatchTagRef = useRef<Dispatch<Action<Selectable>> | null>(null);
  let dispatchGuestRef = useRef<Dispatch<Action<Selectable>> | null>(null);
  if (customDispatchGuestRef) dispatchGuestRef = customDispatchGuestRef;

  const [selectedSearchGuests, setSelectedSearchGuests] = useState<
    SelectableGuest[]
  >([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [open, setOpen] = useState(false);
  const [guestViewOpen, setGuestViewOpen] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onTagDelete = (tag: Tag) => {
    const currentGuests: Guest[] = [];
    const updatedTags: Tag[] = selectedTags.filter((t) => t.tag !== tag.tag);
    selectedSearchGuests.forEach((value) => {
      const selectedUser = orbit.find(
        (user) =>
          user.id === value.key() &&
          !currentGuests.some(
            (currentGuest: Guest) => currentGuest.id === user.id,
          ),
      );
      if (selectedUser) {
        currentGuests.push(selectedUser);
      }
    });

    updatedTags.forEach((value) => {
      // get all users that have `value.tag` in their list of tags and are
      // not already in the `currentGuests` array
      const users = orbit.filter(
        (user) =>
          user.tags.includes(value.id) &&
          !currentGuests.some(
            (selectedGuest: Guest) => selectedGuest.id === user.id,
          ),
      );
      users.forEach((user) => currentGuests.push(user));
    });
    const nonContactableGuests = currentGuests.filter(
      (guest) => guest.contactable === false,
    );
    if (nonContactableGuests.length !== 0) {
      setNonContactableSelectedGuests(nonContactableGuests);
    } else {
      setNonContactableSelectedGuests([]);
    }
    setSelectedGuests(currentGuests);
    setSelectedTags(updatedTags);
  };

  const onGuestDelete = (delete_guest: SelectableGuest) => {
    const currentGuests: Guest[] = [];
    const updatedSearchGuests: SelectableGuest[] = selectedSearchGuests.filter(
      (g) => g.key() !== delete_guest.key(),
    );
    selectedSearchGuests.forEach((value) => {
      const selectedUser = orbit.find(
        (user) =>
          user.id === value.key() &&
          !currentGuests.some(
            (currentGuest: Guest) => currentGuest.id === user.id,
          ),
      );
      if (selectedUser) {
        currentGuests.push(selectedUser);
      }
    });

    selectedTags.forEach((value) => {
      // get all users that have `value.name` in their list of tags and are
      // not already in the `currentGuests` array
      const users = orbit.filter(
        (user) =>
          user.tags.includes(value.id) &&
          !currentGuests.some(
            (selectedGuest: Guest) => selectedGuest.id === user.id,
          ),
      );
      users.forEach((user) => currentGuests.push(user));
    });
    const nonContactableGuests = currentGuests.filter(
      (guest) => guest.contactable === false,
    );
    if (nonContactableGuests.length !== 0) {
      setNonContactableSelectedGuests(nonContactableGuests);
    } else {
      setNonContactableSelectedGuests([]);
    }
    setSelectedGuests(currentGuests);
    setSelectedSearchGuests(updatedSearchGuests);
  };

  const onCloseNewGuest = useCallback(
    (reload: boolean, newGuest: Guest | null) => {
      if (newGuest && onReload) {
        onReload(newGuest);
      }
      setGuestViewOpen(false);
    },
    [onReload, setGuestViewOpen],
  );

  return (
    <>
      <Dialog
        onClose={() => setOpen(false)}
        open={open}
        maxWidth="xs"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitle>{`Potential guests (${selectedGuests.length})`}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          p={2}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            overflowY: 'scroll',
            maxHeight: '600px',
          }}
        >
          <Grid container my={1}>
            {selectedGuests.map((guest) => (
              <Grid item key={guest.id} mb={1} xs={12}>
                <Box sx={{ position: 'relative' }}>
                  <GuestCard guest={guest} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Dialog>
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <SearchableMultiSelectProvider
            options={orbit.map((t) => new SelectableGuest(t))}
            selectedOptions={selectedGuests.map((t) => new SelectableGuest(t))}
            dispatchRef={dispatchGuestRef}
          >
            <GuestsInner
              orbit={orbit}
              setSelectedGuests={setSelectedGuests}
              setNonContactableSelectedGuests={setNonContactableSelectedGuests}
              setSelectedSearchGuests={setSelectedSearchGuests}
              selectedTags={selectedTags}
            />
          </SearchableMultiSelectProvider>
        </Grid>
        <Grid item>
          <SearchableMultiSelectProvider
            options={orbitTags.map((t) => new SelectableTag(t))}
            selectedOptions={[]}
            dispatchRef={dispatchTagRef}
          >
            <TagsInner
              orbit={orbit}
              setSelectedGuests={setSelectedGuests}
              selectedSearchGuests={selectedSearchGuests}
              setNonContactableSelectedGuests={setNonContactableSelectedGuests}
              setSelectedTags={setSelectedTags}
            />
          </SearchableMultiSelectProvider>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            fullWidth={false}
            sx={{ ml: { xs: '0px', md: '10px' } }}
            onClick={() => setGuestViewOpen(true)}
          >
            Add new Contact
          </Button>
        </Grid>
      </Grid>
      <GuestInfoDialog
        open={guestViewOpen}
        guest={null}
        orbitTags={orbitTags}
        onClose={onCloseNewGuest}
        currentTags={newGuestTags}
        refreshGuestTags={onReloadTags || (() => {})}
      />
      {!hideSummary && (
        <>
          {selectedGuests.length === 0 && (
            <Typography variant="body1">
              {selectedGuests.length} guests selected.
            </Typography>
          )}
          {selectedGuests.length > 0 && (
            <Box mt={2} key="summary">
              <>
                {selectedGuests.length === 1 && (
                  <Typography variant="body1" display="inline" key="one-guest">
                    {selectedGuests.length} guest selected &nbsp;
                  </Typography>
                )}
                {selectedGuests.length > 1 && (
                  <Typography
                    variant="body1"
                    display="inline"
                    key="multi-guest"
                  >
                    {selectedGuests.length} guests selected, including &nbsp;
                  </Typography>
                )}

                {selectedSearchGuests.map((guest) => (
                  <Chip
                    avatar={
                      <Avatar>{guest.value().charAt(0).toUpperCase()}</Avatar>
                    }
                    variant="outlined"
                    size="small"
                    key={guest.key()}
                    label={guest.value()}
                    onDelete={() => {
                      onGuestDelete(guest);
                      dispatchGuestRef.current?.({
                        type: 'selection',
                        key: guest.key(),
                        selected: false,
                      });
                    }}
                    sx={{ marginRight: '3px' }}
                  />
                ))}

                {selectedSearchGuests.length > 0 && selectedTags.length > 0 && (
                  <Typography
                    variant="body1"
                    display="inline"
                    key="multi-tag-1"
                  >
                    and all contacts tagged with &nbsp;
                  </Typography>
                )}
                {selectedSearchGuests.length === 0 && selectedTags.length > 0 && (
                  <Typography
                    variant="body1"
                    display="inline"
                    key="multi-tag-2"
                  >
                    all contacts tagged with &nbsp;
                  </Typography>
                )}
                {selectedTags.map((tag) => (
                  <TagChip
                    key={tag.id}
                    label={tag.tag}
                    color={tag.color}
                    onDelete={() => {
                      onTagDelete(tag);
                      dispatchTagRef.current?.({
                        type: 'selection',
                        key: tag.id,
                        selected: false,
                      });
                    }}
                    margin={0.25}
                  />
                ))}
                <Typography
                  variant="body1"
                  display="inline"
                  key="end-of-summary"
                >
                  . &nbsp;
                </Typography>
                {nonContactableSelectedGuests.length === 1 && (
                  <Typography
                    variant="body1"
                    display="inline"
                    key="noncontactable-1"
                  >
                    {nonContactableSelectedGuests.length} guest not contactable.
                  </Typography>
                )}
                {nonContactableSelectedGuests.length > 1 && (
                  <Typography
                    variant="body1"
                    display="inline"
                    key="noncontactable"
                  >
                    {nonContactableSelectedGuests.length} guests not
                    contactable.
                  </Typography>
                )}
                <Button
                  variant="text"
                  sx={{ padding: 0, minWidth: '20px', textTransform: 'none' }}
                  onClick={() => setOpen(true)}
                >
                  &nbsp; View full potential guest list
                </Button>
              </>
            </Box>
          )}
        </>
      )}
    </>
  );
}

UsersAndTags.defaultProps = {
  hideSummary: false,
  customDispatchGuestRef: null,
  onReload: () => {},
  onReloadTags: () => {},
};

export default UsersAndTags;
