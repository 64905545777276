import { useNavigate } from 'react-router-dom';

import { Button, Container, Dialog, Stack, Typography } from '@mui/material';

import { buildLogoutUrl } from '../config';
import logo from '../assets/logo.svg';

export type Props = {
  open: boolean;
  code: number;
  suppressRetry?: boolean;
  home?: boolean;
};

export default function ErrorDialog({
  open,
  code,
  suppressRetry,
  home,
}: Props) {
  const navigate = useNavigate();

  return (
    <Dialog open={open} fullScreen>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          style={{ marginBottom: '10px' }}
        />
        <Typography variant="h5">Oops, something went wrong.</Typography>
        <Typography variant="caption">{`Error code: ${String(
          code,
        )}`}</Typography>
        {code === 401 && (
          <Typography variant="body1">
            Attempting to fix. If this error persists, please logout and try
            again later.
          </Typography>
        )}
        {(code === 502 || code === 504) && (
          <Typography variant="body1">
            We are attempting to fix the issue. Please try again later.
          </Typography>
        )}
        {![401, 502, 504].includes(code) && (
          <Typography variant="body1">
            If this error persists, please try again later.
          </Typography>
        )}
        <Stack direction="row" spacing={2} my={2}>
          {!suppressRetry && <Button onClick={() => navigate(0)}>Retry</Button>}
          {!home && (
            <Button onClick={() => navigate(buildLogoutUrl())}>Log out</Button>
          )}
          {home && <Button onClick={() => navigate('/')}>Home</Button>}
        </Stack>
      </Container>
    </Dialog>
  );
}

ErrorDialog.defaultProps = { suppressRetry: true, home: false };
