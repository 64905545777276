import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useReducer,
  useState,
  Dispatch,
  useRef,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  Collapse,
  IconButton,
} from '@mui/material';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MessageIcon from '@mui/icons-material/Message';
import WarningIcon from '@mui/icons-material/Warning';

import moment from 'moment';

import { useRequiredAuthenticatedClient } from '../../providers/AuthenticatedClientProvider';
import ErrorResponse from '../../types/ErrorResponse';
import { Option, Some, None } from '../../types/Option';
import { Result } from '../../types/Result';

import { EditedGathering } from '../../models/EditedGathering';
import { GatheringGuest } from '../../models/GatheringGuest';
import { Guest } from '../../models/Guest';
import { Tag } from '../../models/Tag';

import ErrorDialog from '../../components/ErrorDialog';
import RequiredTextField from '../../components/RequiredTextField';
import UsersAndTags, { SelectableGuest } from '../../components/UsersAndTags';
import GatheringGuestCard from '../../components/GatheringGuestCard';
import GuestCard from '../../components/GuestCard';
import { Action, Selectable } from '../../components/SearchableMultiSelect';
import DurationPicker from '../../components/DurationPicker';

type DateTimePickerValue = moment.Moment | null | undefined;

type RemoveGuest = {
  guest: GatheringGuest;
  reason?: string;
};

type EditGatheringState = {
  orbit: Guest[];
  tags: Tag[];
  gathering: EditedGathering;
  gathering_guests: GatheringGuest[];
  startDate: DateTimePickerValue;
  startTime: DateTimePickerValue;
  endDate: DateTimePickerValue;
  endTime: DateTimePickerValue;
  requiredNumberOfGuestsType: string;
  expanded: boolean;
  potentialGuestNum: number;
  nonContactableGuestNum: number;
  starting_max: number;

  // remote-call related state
  loaded: boolean;
  error: Option<ErrorResponse>;
};

type EditGatheringAction =
  | {
      type: 'loaded';
      orbit: Guest[];
      tags: Tag[];
      gathering: EditedGathering;
      gathering_guests: GatheringGuest[];
      guestNum: number;
      nonContactableNum: number;
    }
  | { type: 'error'; error: ErrorResponse }
  | { type: 'update_gathering'; gathering: EditedGathering }
  | { type: 'change_guest_type'; guestType: string }
  | {
      type: 'update_date';
      startDate: DateTimePickerValue;
      endDate: DateTimePickerValue;
    }
  | {
      type: 'update_time';
      startTime: DateTimePickerValue;
      endTime: DateTimePickerValue;
    }
  | { type: 'expand'; expanded: boolean };

function stateReducer(
  state: EditGatheringState,
  action: EditGatheringAction,
): EditGatheringState {
  switch (action.type) {
    case 'loaded': {
      return {
        loaded: true,
        orbit: action.orbit,
        tags: action.tags,
        gathering: action.gathering,
        gathering_guests: action.gathering_guests,
        startDate: moment(action.gathering.start_time),
        startTime: moment(action.gathering.start_time),
        endDate: action.gathering.end_time
          ? moment(action.gathering.end_time)
          : moment(action.gathering.start_time).add(3, 'hour'),
        endTime: action.gathering.end_time
          ? moment(action.gathering.end_time)
          : moment(action.gathering.start_time).add(3, 'hour'),
        requiredNumberOfGuestsType:
          action.gathering.min_guests === action.gathering.max_guests
            ? 'exactly'
            : 'atLeast',
        expanded: action.gathering.end_time !== null,
        potentialGuestNum: action.guestNum,
        nonContactableGuestNum: action.nonContactableNum,
        starting_max: action.gathering.max_guests!,
        error: None(),
      };
    }
    case 'update_gathering':
      return { ...state, gathering: action.gathering };
    case 'change_guest_type':
      return { ...state, requiredNumberOfGuestsType: action.guestType };
    case 'update_date':
      return { ...state, startDate: action.startDate, endDate: action.endDate };
    case 'update_time':
      return { ...state, startTime: action.startTime, endTime: action.endTime };
    case 'expand':
      return { ...state, expanded: action.expanded };
    case 'error':
      return { ...state, loaded: true, error: Some(action.error) };
    default:
      return { ...state };
  }
}

const initialState: EditGatheringState = {
  orbit: [],
  tags: [],
  gathering: {},
  gathering_guests: [],
  startDate: moment(),
  startTime: moment(),
  endDate: moment(),
  endTime: moment(),
  requiredNumberOfGuestsType: 'atLeast',
  potentialGuestNum: 0,
  nonContactableGuestNum: 0,
  starting_max: 0,
  expanded: false,
  loaded: false,
  error: None(),
};

function EditGathering() {
  const navigate = useNavigate();
  const client = useRequiredAuthenticatedClient();
  const { gatheringId } = useParams() as { gatheringId: string };
  const [state, dispatch] = useReducer(stateReducer, initialState);

  const [editingGuests, setEditingGuests] = useState(false);
  const [currentGuest, setCurrentGuest] = useState<RemoveGuest | null>(null);
  const [newGuestTags, setNewGuestTags] = useState<Tag[]>([]);

  const [newGuests, setNewGuests] = useState<Guest[]>([]);
  const [removeGuests, setRemoveGuests] = useState<RemoveGuest[]>([]);
  const [nonContactableSelectedGuests, setNonContactableSelectedGuests] =
    useState<Guest[]>([]);

  const dispatchGuestRef = useRef<Dispatch<Action<Selectable>> | null>(null);

  const badEndTime =
    state
      .endDate!.clone()
      .hour(state.endTime!.hour())
      .minutes(state.endTime!.minutes()) <
    state
      .startDate!.clone()
      .hour(state.startTime!.hour())
      .minutes(state.startTime!.minutes());

  const [duration, setDuration] = useState('24');
  const [durationUnit, setDurationUnit] = useState('Hours');
  const [finalExpiration, setExpiration] = useState('');
  const [expirationUnit, setExpirationUnit] = useState('Hours');

  useEffect(() => {
    async function load() {
      const orbitResult = await client.get_guests();
      if (!orbitResult.ok) {
        dispatch({ type: 'error', error: orbitResult.error });
        return;
      }

      const tagsResult = await client.get_tags();
      if (!tagsResult.ok) {
        dispatch({ type: 'error', error: tagsResult.error });
        return;
      }

      const gatheringResult = await client.get_gathering(gatheringId);
      if (!gatheringResult.ok) {
        dispatch({ type: 'error', error: gatheringResult.error });
        return;
      }

      setDuration(
        gatheringResult.value.data.invite_duration_minutes > 1440
          ? (
              gatheringResult.value.data.invite_duration_minutes / 1440
            ).toString()
          : (
              gatheringResult.value.data.invite_duration_minutes / 60
            ).toString(),
      );

      setDurationUnit(
        gatheringResult.value.data.invite_duration_minutes > 1440
          ? 'Days'
          : 'Hours',
      );

      if (gatheringResult.value.data.final_invite_expiration_minutes) {
        setExpiration(
          gatheringResult.value.data.final_invite_expiration_minutes > 1440
            ? (
                gatheringResult.value.data.final_invite_expiration_minutes /
                1440
              ).toString()
            : (
                gatheringResult.value.data.final_invite_expiration_minutes / 60
              ).toString(),
        );

        setExpirationUnit(
          gatheringResult.value.data.final_invite_expiration_minutes > 1440
            ? 'Days'
            : 'Hours',
        );
      }
      dispatch({
        type: 'loaded',
        orbit: orbitResult.value.data,
        tags: tagsResult.value.data,
        gathering: {
          title: gatheringResult.value.data.title,
          start_time: gatheringResult.value.data.start_time,
          end_time: gatheringResult.value.data.end_time,
          description: gatheringResult.value.data.description,
          gathering_location: gatheringResult.value.data.gathering_location,
          min_guests: gatheringResult.value.data.min_guests,
          max_guests: gatheringResult.value.data.max_guests,
          invite_duration_minutes:
            gatheringResult.value.data.invite_duration_minutes,
          final_invite_expiration_minutes:
            gatheringResult.value.data.final_invite_expiration_minutes,
          cancelled: gatheringResult.value.data.cancelled,
        },
        gathering_guests: gatheringResult.value.data.guest_exts_with_tags || [],
        guestNum: gatheringResult.value.data.guest_exts_with_tags?.length || 0,
        nonContactableNum:
          gatheringResult.value.data.guest_exts_with_tags?.filter(
            (guest: GatheringGuest) => !guest.contactable,
          ).length || 0,
      });
    }
    load();
  }, [client, dispatch, gatheringId]);

  const reloadTags = useCallback(
    (tags: number[]) => {
      async function reload() {
        const tagsResult = await client.get_tags();
        if (!tagsResult.ok) {
          dispatch({ type: 'error', error: tagsResult.error });
          return;
        }

        dispatch({
          type: 'loaded',
          orbit: state.orbit,
          tags: tagsResult.value.data,
          gathering: state.gathering,
          gathering_guests: state.gathering_guests,
          guestNum: state.potentialGuestNum,
          nonContactableNum: state.nonContactableGuestNum,
        });
        setNewGuestTags(
          tagsResult.value.data.filter((tag) => tags.includes(tag.id)),
        );
      }
      reload();
    },
    [state, dispatch, client],
  );

  const reloadGuests = useCallback(
    (newGuest: Guest) => {
      async function reload() {
        const orbitResult = await client.get_guests();
        if (!orbitResult.ok) {
          dispatch({ type: 'error', error: orbitResult.error });
          return;
        }

        const tagsResult = await client.get_tags();
        if (!tagsResult.ok) {
          dispatch({ type: 'error', error: tagsResult.error });
          return;
        }

        dispatch({
          type: 'loaded',
          orbit: orbitResult.value.data,
          tags: tagsResult.value.data,
          gathering: state.gathering,
          gathering_guests: state.gathering_guests,
          guestNum: state.potentialGuestNum,
          nonContactableNum: state.nonContactableGuestNum,
        });

        setNewGuests([...newGuests, newGuest]);

        dispatchGuestRef.current?.({
          type: 'refreshOptions',
          options: orbitResult.value.data.map((t) => new SelectableGuest(t)),
        });

        dispatchGuestRef.current?.({
          type: 'refreshSelected',
          selectedOptions: [...newGuests, newGuest].map(
            (t) => new SelectableGuest(t),
          ),
        });
      }
      reload();
    },
    [client, state, dispatch, newGuests, setNewGuests],
  );

  const requiredGuestsDisplayString = useCallback(() => {
    let str = '';
    if (state.requiredNumberOfGuestsType === 'exactly') {
      str = `${
        state.gathering.min_guests !== undefined
          ? state.gathering.min_guests
          : '0'
      } guests`;
    } else {
      str = `${
        state.gathering.min_guests !== undefined
          ? state.gathering.min_guests
          : '0'
      }-${
        state.gathering.max_guests !== undefined
          ? state.gathering.max_guests
          : '0'
      } guests`;
    }
    return str;
  }, [state]);

  const submitForm = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      if (state.startDate && state.startTime) {
        const datetime = state.startDate
          .clone()
          .hour(state.startTime.hour())
          .minutes(state.startTime.minutes());

        let endtime = null;
        if (state.endDate && state.endTime) {
          endtime = state.endDate
            .clone()
            .hour(state.endTime.hour())
            .minutes(state.endTime.minutes());
        }

        const gathering = {
          ...state.gathering,
          start_time: datetime.format(),
          end_time: state.expanded && endtime ? endtime.format() : undefined,
          add_guests: newGuests.map((guest) => guest.id),
          remove_guests: removeGuests.map((remove) => ({
            gathering_guest_id: remove.guest.gathering_guest_id,
            reason: remove.reason,
          })),
        };

        client
          .save_gathering(parseInt(gatheringId, 10), gathering)
          .then((result: Result<void, ErrorResponse>) => {
            if (result.ok) {
              navigate('/app/congratulations_update/', {
                state: {
                  requiredGuests: requiredGuestsDisplayString(),
                  gatheringId: parseInt(gatheringId, 10),
                },
              });
            } else {
              dispatch({ type: 'error', error: result.error });
            }
          })
          .catch(() => {
            dispatch({
              type: 'error',
              error: { status_code: 0, message: 'Unknown error' },
            });
          });
      }
    },
    [
      client,
      navigate,
      requiredGuestsDisplayString,
      state,
      gatheringId,
      newGuests,
      removeGuests,
    ],
  );

  const isSendable = useCallback(() => {
    const guestChange =
      newGuests.filter((guest) => guest.contactable).length -
      removeGuests.filter((remove_guest) => remove_guest.guest.contactable)
        .length;

    const sendableGuests =
      state.potentialGuestNum - state.nonContactableGuestNum + guestChange;

    if (state.gathering.title?.trim() === '') {
      return false;
    }
    if (state.requiredNumberOfGuestsType === 'exactly') {
      return (
        state.gathering.min_guests !== undefined &&
        state.gathering.min_guests >= 1 &&
        state.gathering.min_guests <= sendableGuests
      );
    }

    if (state.expanded && badEndTime) {
      return false;
    }

    return (
      state.gathering.min_guests !== undefined &&
      state.gathering.max_guests !== undefined &&
      state.gathering.min_guests >= 1 &&
      state.gathering.min_guests <= sendableGuests &&
      state.gathering.min_guests < state.gathering.max_guests
    );
  }, [badEndTime, state, newGuests, removeGuests]);

  const remainingGuests = useCallback(() => {
    const guestChange =
      newGuests.filter((guest) => guest.contactable).length -
      removeGuests.filter((remove_guest) => remove_guest.guest.contactable)
        .length;
    const sendableGuests =
      state.potentialGuestNum - state.nonContactableGuestNum + guestChange;

    if (
      state.gathering.min_guests === undefined ||
      state.gathering.min_guests <= 1
    ) {
      return 0;
    }

    return state.gathering.min_guests - sendableGuests;
  }, [state, newGuests, removeGuests]);

  const otherGuests = state.orbit.filter(
    (guest1: Guest) =>
      !state.gathering_guests.some(
        (guest2: GatheringGuest) => guest2.orbit_guest_id === guest1.id,
      ),
  );

  const attemptRemoveGuest = (guest: GatheringGuest) => {
    if (guest.invitation_is_accepted) {
      setCurrentGuest({ guest });
    } else {
      setRemoveGuests([...removeGuests, { guest }]);
    }
  };

  const handleDurationChange = (newDuration: string) => {
    setDuration(newDuration);
    // convert duration time to minutes
    const durationMinutes =
      parseInt(newDuration, 10) * (durationUnit === 'Hours' ? 60 : 1440);
    const gathering = {
      ...state.gathering,
      invite_duration_minutes: durationMinutes,
    };
    dispatch({ type: 'update_gathering', gathering });
  };

  const handleDurationUnitChange = (newUnit: string) => {
    setDurationUnit(newUnit);

    // convert duration time to minutes
    const durationMinutes =
      parseInt(duration, 10) * (newUnit === 'Hours' ? 60 : 1440);
    const gathering = {
      ...state.gathering,
      invite_duration_minutes: durationMinutes,
    };
    dispatch({ type: 'update_gathering', gathering });
  };

  const handleExpirationChange = (newExpiration: string) => {
    setExpiration(newExpiration);
    // convert duration time to minutes
    const expirationMinutes =
      parseInt(newExpiration, 10) * (expirationUnit === 'Hours' ? 60 : 1440);
    const gathering = {
      ...state.gathering,
      final_invite_expiration_minutes: expirationMinutes,
    };
    dispatch({ type: 'update_gathering', gathering });
  };

  const handleExpirationUnitChange = (newUnit: string) => {
    setExpirationUnit(newUnit);

    // convert duration time to minutes
    const expirationMinutes =
      parseInt(finalExpiration, 10) * (newUnit === 'Hours' ? 60 : 1440);
    const gathering = {
      ...state.gathering,
      final_invite_expiration_minutes: expirationMinutes,
    };
    dispatch({ type: 'update_gathering', gathering });
  };

  const removalReasonDialog = (
    <Dialog open={currentGuest !== null}>
      <DialogTitle textAlign="center">
        <div>
          <WarningIcon />
        </div>
        <div>Uninvite guest who has already accepted?</div>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <Typography variant="body1">
            {`Are you sure you want to remove ${
              currentGuest?.guest.effective_first_name || ''
            }?`}
          </Typography>
          <Typography variant="body2">
            {`They will be notified they have been uninvited. Please provide a
              reason for the change that can be shared with
              ${currentGuest?.guest.effective_first_name || ''}.`}
          </Typography>
          <TextField
            variant="outlined"
            label="Reason"
            color="secondary"
            value={currentGuest?.reason || ''}
            onChange={(e) => {
              setCurrentGuest({
                guest: currentGuest!.guest,
                reason: e.target.value,
              });
            }}
            multiline
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setRemoveGuests([...removeGuests, currentGuest!]);
            setCurrentGuest(null);
          }}
        >
          Add Reason
        </Button>
        <Button onClick={() => setCurrentGuest(null)}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );

  const renderPotentialGuestsDialog = (
    <Collapse in={editingGuests} sx={{ maxWidth: { sm: '80%', xs: '100%' } }}>
      {removalReasonDialog}
      <Typography variant="h6" color="primary.main">
        Edit Guest List
      </Typography>
      <Box
        p={2}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '600px',
          overflow: 'hidden',
          overflowY: 'scroll',
        }}
      >
        <UsersAndTags
          orbit={otherGuests}
          orbitTags={state.tags}
          setSelectedGuests={setNewGuests}
          selectedGuests={newGuests}
          nonContactableSelectedGuests={nonContactableSelectedGuests}
          setNonContactableSelectedGuests={setNonContactableSelectedGuests}
          hideSummary
          customDispatchGuestRef={dispatchGuestRef}
          onReload={reloadGuests}
          onReloadTags={reloadTags}
          newGuestTags={newGuestTags}
        />
        <Grid container my={1}>
          {newGuests.length > 0 && (
            <Typography variant="body1">Add Guests</Typography>
          )}
          {newGuests.map((guest: Guest) => (
            <Grid item key={guest.id} mb={1} xs={12}>
              <Box sx={{ position: 'relative', width: '90%' }}>
                <GuestCard guest={guest} />
              </Box>
            </Grid>
          ))}
        </Grid>
        <Grid container my={1}>
          {removeGuests.length > 0 && (
            <Typography variant="body1">Remove Guests</Typography>
          )}
          {removeGuests.map((remove_guest: RemoveGuest) => (
            <Grid
              item
              key={remove_guest.guest.gathering_guest_id}
              mb={1}
              xs={12}
            >
              <Stack direction="row" alignItems="center">
                <Box sx={{ position: 'relative', width: '90%' }}>
                  <GatheringGuestCard
                    guest={remove_guest.guest}
                    addable
                    reAdd={() =>
                      setRemoveGuests(
                        removeGuests.filter(
                          (guest1) =>
                            guest1.guest.gathering_guest_id !==
                            remove_guest.guest.gathering_guest_id,
                        ),
                      )
                    }
                  />
                </Box>
                {remove_guest.reason && (
                  <Box sx={{ width: '5%', textAlign: 'right' }}>
                    {' '}
                    {/* Box for the icon */}
                    <IconButton onClick={() => setCurrentGuest(remove_guest)}>
                      <MessageIcon />
                    </IconButton>
                  </Box>
                )}
              </Stack>
            </Grid>
          ))}
        </Grid>
        <Divider variant="fullWidth" />
        <Grid container my={1}>
          <Typography variant="body1">Current Guests</Typography>
          {state.gathering_guests
            .filter(
              (guest1: GatheringGuest) =>
                !removeGuests.some(
                  (removed_guest: RemoveGuest) =>
                    removed_guest.guest.gathering_guest_id ===
                    guest1.gathering_guest_id,
                ),
            )
            .map((guest: GatheringGuest) => (
              <Grid item key={guest.gathering_guest_id} mb={1} xs={12}>
                <Box sx={{ position: 'relative', width: '90%' }}>
                  <GatheringGuestCard
                    guest={guest}
                    removable
                    onRemove={() => attemptRemoveGuest(guest)}
                  />
                </Box>
              </Grid>
            ))}
        </Grid>
      </Box>
    </Collapse>
  );

  if (!state.loaded) {
    // Gathering hasn't loaded yet
    return (
      <Container>
        <Typography variant="h5">Loading</Typography>
      </Container>
    );
  }

  return (
    <form onSubmit={submitForm}>
      <Container>
        <ErrorDialog
          open={state.error.some}
          code={
            state.error.some && state.error.value.status_code
              ? state.error.value.status_code
              : 0
          }
        />
        <Typography variant="h5">Edit Gathering</Typography>
        <Stack spacing={3}>
          <RequiredTextField
            id="title"
            label="Title"
            value={state.gathering.title || ''}
            onChange={(val: string) => {
              const gathering = { ...state.gathering, title: val };
              dispatch({ type: 'update_gathering', gathering });
            }}
          />
          <Stack spacing={0}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {/* eslint-disable react/jsx-props-no-spreading */}
              <Stack direction="row" spacing={1}>
                <DatePicker
                  label="Start Date"
                  value={state.startDate}
                  onChange={(date: moment.Moment | null | undefined) => {
                    dispatch({
                      type: 'update_date',
                      startDate: date,
                      endDate: !state.expanded ? date : state.endDate,
                    });
                  }}
                />
                <TimePicker
                  label="Start Time"
                  value={state.startTime}
                  minutesStep={5}
                  onChange={(time: moment.Moment | null | undefined) => {
                    dispatch({
                      type: 'update_time',
                      startTime: time,
                      endTime: !state.expanded ? time : state.endTime,
                    });
                  }}
                />
              </Stack>
              <Stack direction="row" alignItems="center" sx={{ marginTop: 0 }}>
                <Typography variant="body1" color="primary.main">
                  {state.expanded && 'Remove'} {!state.expanded && 'Add'} end
                  time (optional)
                </Typography>
                <IconButton
                  onClick={() =>
                    dispatch({ type: 'expand', expanded: !state.expanded })
                  }
                >
                  {state.expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              </Stack>
              {/* eslint-enable react/jsx-props-no-spreading */}
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {/* eslint-disable react/jsx-props-no-spreading */}

              <Stack direction="column" spacing={1}>
                <Collapse in={state.expanded}>
                  <Stack direction="row" spacing={1}>
                    <DatePicker
                      label="End Date"
                      value={state.endDate}
                      onChange={(date: moment.Moment | null | undefined) =>
                        dispatch({
                          type: 'update_date',
                          startDate: state.startDate,
                          endDate: date,
                        })
                      }
                    />
                    <TimePicker
                      label="End Time"
                      value={state.endTime}
                      minutesStep={5}
                      onChange={(time) =>
                        dispatch({
                          type: 'update_time',
                          startTime: state.startTime,
                          endTime: time,
                        })
                      }
                    />
                  </Stack>
                  {badEndTime && (
                    <Alert severity="warning">
                      End time must be after start time
                    </Alert>
                  )}
                </Collapse>
              </Stack>
              {/* eslint-enable react/jsx-props-no-spreading */}
            </LocalizationProvider>
          </Stack>
          <TextField
            variant="outlined"
            label="Location"
            value={state.gathering.gathering_location || ''}
            onChange={(e) => {
              const gathering = {
                ...state.gathering,
                gathering_location: e.target.value,
              };
              dispatch({ type: 'update_gathering', gathering });
            }}
            multiline
          />
          <TextField
            variant="outlined"
            label="Description"
            value={state.gathering.description || ''}
            onChange={(e) => {
              const gathering = {
                ...state.gathering,
                description: e.target.value,
              };
              dispatch({ type: 'update_gathering', gathering });
            }}
            multiline
          />
          <Stack spacing={0.7}>
            <DurationPicker
              inviteDuration={duration}
              setDuration={handleDurationChange}
              durationUnit={durationUnit}
              setDurationUnit={handleDurationUnitChange}
              finalExpiration={finalExpiration}
              setFinalExpiration={handleExpirationChange}
              expirationUnit={expirationUnit}
              setExpirationUnit={handleExpirationUnitChange}
            />
            <Typography variant="body1">
              How many guests would you like to attend this gathering?
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={state.requiredNumberOfGuestsType}
              name="radio-buttons-group"
              onChange={(e) => {
                dispatch({
                  type: 'change_guest_type',
                  guestType: e.target.value,
                });
              }}
            >
              <Stack spacing={1}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <FormControlLabel
                      value="atLeast"
                      control={<Radio />}
                      label="At least"
                      sx={{ mr: 1 }}
                    />
                    <OutlinedInput
                      size="small"
                      type="number"
                      value={
                        state.gathering.min_guests !== undefined
                          ? state.gathering.min_guests
                          : ''
                      }
                      onChange={(e) => {
                        const val = e.target.value;

                        if (val) {
                          const gathering = {
                            ...state.gathering,
                            min_guests: parseInt(val, 10),
                          };
                          dispatch({ type: 'update_gathering', gathering });
                        } else {
                          const gathering = {
                            ...state.gathering,
                            min_guests: undefined,
                          };
                          dispatch({ type: 'update_gathering', gathering });
                        }
                      }}
                      sx={{
                        width: '90px',
                        m: 0,
                      }}
                      disabled={state.requiredNumberOfGuestsType === 'exactly'}
                      inputProps={{
                        min: 1,
                        max:
                          state.gathering.max_guests !== undefined
                            ? state.gathering.max_guests - 1
                            : null,
                      }}
                      error={
                        state.gathering.min_guests === undefined ||
                        state.gathering.min_guests < 1
                      }
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    pl={{ xs: 6, sm: 0 }}
                  >
                    <InputLabel htmlFor="max-guests-input">
                      but no more than
                    </InputLabel>
                    <OutlinedInput
                      id="max-guests-input"
                      size="small"
                      type="number"
                      value={
                        state.gathering.max_guests !== undefined
                          ? state.gathering.max_guests
                          : ''
                      }
                      onChange={(e) => {
                        const max = parseInt(e.target.value, 10);
                        if (max) {
                          const gathering = {
                            ...state.gathering,
                            max_guests: max,
                          };
                          dispatch({ type: 'update_gathering', gathering });
                        } else {
                          const gathering = {
                            ...state.gathering,
                            max_guests: undefined,
                          };
                          dispatch({ type: 'update_gathering', gathering });
                        }
                      }}
                      sx={{
                        width: '90px',
                      }}
                      error={
                        (state.gathering.min_guests !== undefined &&
                          state.gathering.max_guests !== undefined &&
                          (state.gathering.min_guests < 1 ||
                            state.gathering.max_guests <
                              state.gathering.min_guests)) ||
                        state.gathering.min_guests === undefined ||
                        state.gathering.max_guests === undefined
                      }
                      disabled={state.requiredNumberOfGuestsType === 'exactly'}
                      inputProps={{
                        min:
                          state.gathering.min_guests !== undefined
                            ? state.gathering.min_guests + 1
                            : 2,
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <FormControlLabel
                    value="exactly"
                    control={<Radio />}
                    label="Exactly"
                    sx={{ mr: 1 }}
                  />
                  <OutlinedInput
                    size="small"
                    type="number"
                    inputProps={{ className: 'digitsOnly', min: 1 }}
                    value={
                      state.gathering.min_guests !== undefined
                        ? state.gathering.min_guests
                        : ''
                    }
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val) {
                        const gathering = {
                          ...state.gathering,
                          min_guests: parseInt(val, 10),
                          max_guests: parseInt(val, 10) + 1,
                        };
                        dispatch({ type: 'update_gathering', gathering });
                      } else {
                        const gathering = {
                          ...state.gathering,
                          min_guests: undefined,
                          max_guests: undefined,
                        };
                        dispatch({ type: 'update_gathering', gathering });
                      }
                    }}
                    sx={{
                      width: '90px',
                    }}
                    disabled={state.requiredNumberOfGuestsType === 'atLeast'}
                    error={
                      state.gathering.min_guests === undefined ||
                      state.gathering.min_guests < 1
                    }
                  />
                </Stack>
              </Stack>
            </RadioGroup>
          </Stack>
          {state.gathering.max_guests &&
            state.starting_max > state.gathering.max_guests && (
              <Alert severity="warning">
                {state.starting_max} people have already been invited. If you
                reduce the number of guest, you might receive more acceptances
                than anticipated.
              </Alert>
            )}
          {remainingGuests() > 1 && (
            <Typography variant="body1">
              You still need to add at least {remainingGuests()} more
              contactable guests or lower the gathering minimum to update this
              gathering.
            </Typography>
          )}

          {remainingGuests() === 1 && (
            <Typography variant="body1">
              You still need to add at least {remainingGuests()} more
              contactable guest or lower the gathering minimum to update this
              gathering.
            </Typography>
          )}
          {!editingGuests && (
            <Stack direction="row" alignItems="center" sx={{ marginTop: 0 }}>
              <Typography variant="h6" color="primary.main">
                Edit Guest List
              </Typography>
              <IconButton onClick={() => setEditingGuests(true)}>
                <ExpandMoreIcon />
              </IconButton>
            </Stack>
          )}
          {renderPotentialGuestsDialog}

          <Box textAlign="center" sx={{ pb: 4 }}>
            <Button
              variant="contained"
              type="submit"
              fullWidth={false}
              disabled={!isSendable()}
            >
              Save
            </Button>
            <Button
              variant="contained"
              fullWidth={false}
              sx={{ marginLeft: '10px' }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Box>
        </Stack>
      </Container>
    </form>
  );
}

export default EditGathering;
