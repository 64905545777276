import { useState, ChangeEventHandler } from 'react';
import TextField from '@mui/material/TextField';

// Default max length in HTML
const DefaultMaxLength = 524288;

export type Props = {
  id: string;
  label: string;
  value: string;
  impliedRequired?: boolean; // Implied required fields don't need the *
  onChange: (val: string) => void;
  type?: string;
  maxLength?: number;
};

type FieldState = 'new' | 'edited' | 'edited-empty';

export default function RequiredTextField({
  id,
  label,
  value,
  impliedRequired,
  onChange,
  type,
  maxLength,
}: Props) {
  const [fieldState, setFieldState] = useState<FieldState>('new');

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value);
    setFieldState(e.target.value.trim() === '' ? 'edited-empty' : 'edited');
  };

  const isError = fieldState === 'edited-empty';

  return (
    <TextField
      id={id}
      variant="outlined"
      label={label}
      value={value}
      type={type}
      required={!impliedRequired}
      onChange={handleChange}
      error={isError}
      helperText={isError ? `${label} must not be empty` : ' '}
      inputProps={{ maxLength }}
    />
  );
}

RequiredTextField.defaultProps = {
  impliedRequired: false,
  type: undefined,
  maxLength: DefaultMaxLength,
};
