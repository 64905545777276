import { useMemo, useState, ChangeEvent } from 'react';

import { useTheme } from '@mui/material/styles';

import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  Menu,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';

import AuthenticatedClient from '../api/AuthenticatedClient';
import ErrorResponse from '../types/ErrorResponse';
import { Guest } from '../models/Guest';
import { Tag } from '../models/Tag';
import { definedTagColors } from '../providers/palletes';

export type TagInfo = {
  tag: Tag;
  guestCount: number;
};

export type DeleteTagControlProps = {
  tagInfo: TagInfo;
  submitDelete: (tagId: number) => void;
};

function DeleteTagControl({ tagInfo, submitDelete }: DeleteTagControlProps) {
  const [open, setOpen] = useState(false);
  const { guestCount } = tagInfo;

  return (
    <>
      <IconButton size="small" onClick={() => setOpen(true)}>
        <DeleteIcon sx={{ fontSize: '16px', filter: 'brightness(42%)' }} />
      </IconButton>
      <Dialog open={open}>
        <DialogTitle textAlign="center">
          <WarningIcon />
        </DialogTitle>
        <DialogContent>
          <Stack spacing={1.5}>
            <Typography variant="h6">
              {`Are you sure you want to delete ${tagInfo.tag.tag}?`}
            </Typography>
            <Typography variant="body1">
              This action cannot be undone.
            </Typography>
            <Typography variant="caption">
              {`${
                guestCount === 1 ? '1 contact' : `${guestCount} contacts`
              } will have this tag removed.`}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={() => submitDelete(tagInfo.tag.id)}>
            Delete tag
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export type Props = {
  tags: Tag[];
  tagMap: Map<number, Guest[]>;
  client: AuthenticatedClient;
  onError: (error: ErrorResponse) => void;
  onTagAddSuccess: () => void;
  onTagUpdateSuccess: () => void;
  onTagDeleteSuccess: () => void;
};

export default function ManageTagsDialog({
  tags,
  tagMap,
  client,
  onError,
  onTagAddSuccess,
  onTagUpdateSuccess,
  onTagDeleteSuccess,
}: Props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [addTagOpen, setAddTagOpen] = useState(false);
  const [editingTagKey, setEditingTagKey] = useState<number | null>(null);
  const [editingTagName, setEditingTagName] = useState<string>('');
  const [editingTagColor, setEditingTagColor] = useState<string>('');
  const [colorSelectorAnchorEl, setColorSelectorAnchorEl] =
    useState<null | HTMLElement>(null);

  // Setup

  const tagsWithInfo: TagInfo[] = useMemo(() => {
    // Count the number of guests associated with each tag
    const tempMap = new Map();
    Array.from(tagMap.keys()).forEach((key) => {
      const v = tagMap.get(key);
      tempMap.set(key, v ? v.length : 0);
    });
    return tags.map((t: Tag) => ({
      tag: t,
      guestCount: tempMap.get(t.id) || 0,
    }));
  }, [tags, tagMap]);

  const existingTagNames: string[] = useMemo(
    // Create a list of existing tag names, excluding the one that is being edited
    () =>
      tags
        .filter((tag: Tag) => tag.id !== editingTagKey)
        .map((tag: Tag) => tag.tag.toLowerCase()),
    [editingTagKey, tags],
  );

  // Handlers

  const handleDialogClose = () => {
    setOpen(false);
    setAddTagOpen(false);
    setEditingTagKey(null);
    setEditingTagName('');
    setEditingTagColor('');
  };

  const startEdit = (key: number) => {
    setEditingTagKey(key);
    const tagToEdit = tags.find((t: Tag) => t.id === key);
    setEditingTagName(tagToEdit?.tag || '');
    setEditingTagColor(tagToEdit ? tagToEdit.color.replace('0x', '') : '#888');
  };

  const startAdd = () => {
    setEditingTagKey(-1);
    setEditingTagName('');
    setEditingTagColor(
      definedTagColors[Math.floor(Math.random() * definedTagColors.length)],
    );
    setAddTagOpen(true);
  };

  const handleColorSelectorOpen = (event: React.MouseEvent<HTMLElement>) => {
    setColorSelectorAnchorEl(event.currentTarget);
  };

  const handleColorSelectorClose = () => {
    setColorSelectorAnchorEl(null);
  };

  const handleUpdateEditingTagColor = (color: string) => {
    setEditingTagColor(color);
    handleColorSelectorClose();
  };

  const submitTagEdit = () => {
    async function tagUpdate() {
      if (editingTagKey && editingTagName && editingTagColor) {
        const res = await client.save_tag(editingTagKey, {
          tag: editingTagName,
          color: editingTagColor,
        });

        setEditingTagKey(null);

        if (!res.ok) {
          setOpen(false);
          onError(res.error);
        } else {
          onTagUpdateSuccess();
        }
      }
    }
    tagUpdate();
  };

  const submitTagDelete = (tagId: number) => {
    async function tagDelete() {
      const res = await client.delete_tag(tagId);

      if (!res.ok) {
        setOpen(false);
        onError(res.error);
      } else {
        onTagDeleteSuccess();
      }
    }
    tagDelete();
  };

  const submitTagAdd = () => {
    async function tagAdd() {
      if (editingTagName && editingTagColor) {
        const res = await client.save_new_tag({
          tag: editingTagName,
          color: editingTagColor,
        });

        if (!res.ok) {
          setOpen(false);
          onError(res.error);
        } else {
          onTagAddSuccess();
          startAdd();
        }
      }
    }
    tagAdd();
  };

  const cancelEdit = () => {
    setEditingTagKey(null);
    setEditingTagName('');
    setEditingTagColor('');
  };

  const cancelAdd = () => {
    setAddTagOpen(false);
    setEditingTagKey(null);
    setEditingTagName('');
    setEditingTagColor('');
  };

  function disableSubmit(): boolean {
    if (!editingTagName) return true;
    if (editingTagName.length === 0) return true;
    if (existingTagNames.includes(editingTagName.toLowerCase())) return true;
    return false;
  }

  function flagTagNameNotUnique(): boolean {
    if (!editingTagName) return false;
    if (existingTagNames.includes(editingTagName.toLowerCase())) return true;
    return false;
  }

  const isColorSelectorOpen = Boolean(colorSelectorAnchorEl);

  const setTagName = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (e.target.value.length < 40 && e.target.value !== ' ') {
      setEditingTagName(e.target.value);
    }
  };

  // Renderers

  const renderColorSelector = (
    <Menu
      anchorEl={colorSelectorAnchorEl}
      id="color-selector"
      keepMounted
      open={isColorSelectorOpen}
      onClose={handleColorSelectorClose}
      sx={{ border: 1, borderColor: '#888' }}
    >
      <Box width="calc(7 * 30px)">
        <Grid container columns={7} spacing={0.25}>
          {definedTagColors.map((color) => (
            <Grid item xs={1} key={color}>
              <IconButton
                onClick={() => handleUpdateEditingTagColor(color)}
                size="small"
              >
                {editingTagColor === color ? (
                  <Box
                    sx={{
                      height: '16px',
                      width: '16px',
                      borderRadius: '2px',
                      border: 1,
                      borderColor: 'primary.contrastText',
                      backgroundColor: color,
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      height: '16px',
                      width: '16px',
                      borderRadius: '2px',
                      backgroundColor: color,
                    }}
                  />
                )}
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Menu>
  );

  const renderTagInfo = (tagInfo: TagInfo) => (
    <Grid container minWidth={400} minHeight={40} alignItems="center">
      <Grid item xs={1}>
        <Box
          ml="5px"
          mt="1px"
          sx={{
            height: '16px',
            width: '16px',
            borderRadius: '2px',
            border: `1px ${theme.palette.primary.contrastText}`,
            backgroundColor: tagInfo.tag.color.replace('0x', ''),
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body1">{tagInfo.tag.tag}</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="caption">{`${tagInfo.guestCount} contact${
          tagInfo.guestCount === 1 ? '' : 's'
        }`}</Typography>
      </Grid>
      <Grid item xs={1}>
        {!editingTagKey && (
          <IconButton onClick={() => startEdit(tagInfo.tag.id)} size="small">
            <EditIcon sx={{ fontSize: '16px', filter: 'brightness(42%)' }} />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={1}>
        {!editingTagKey && (
          <DeleteTagControl
            tagInfo={tagInfo}
            submitDelete={() => submitTagDelete(tagInfo.tag.id)}
          />
        )}
      </Grid>
    </Grid>
  );

  const renderEditingTagInfo = (tagInfo: TagInfo) => (
    <Grid container minWidth={400} minHeight={40} alignItems="center">
      <Grid item xs={1}>
        <IconButton onClick={handleColorSelectorOpen} size="small">
          <Box
            sx={{
              height: '16px',
              width: '16px',
              borderRadius: '2px',
              border: 1,
              borderColor: '#888',
              backgroundColor: editingTagColor,
            }}
          />
        </IconButton>
      </Grid>
      <Grid item xs={6}>
        <TextField
          required
          id="tag-name"
          label="tag name"
          size="small"
          sx={{ mr: '8px' }}
          value={editingTagName}
          onChange={setTagName}
          error={flagTagNameNotUnique()}
          helperText={flagTagNameNotUnique() && 'This tag name already exists'}
        />
      </Grid>
      <Grid item xs={3}>
        <Typography variant="caption">{`${tagInfo.guestCount} contact${
          tagInfo.guestCount === 1 ? '' : 's'
        }`}</Typography>
      </Grid>
      <Grid item xs={1}>
        <IconButton
          onClick={submitTagEdit}
          size="small"
          disabled={disableSubmit()}
        >
          <CheckIcon sx={{ fontSize: '16px' }} />
        </IconButton>
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={cancelEdit} size="small">
          <CloseIcon sx={{ fontSize: '16px' }} />
        </IconButton>
      </Grid>
    </Grid>
  );

  const renderAddTag = (
    <>
      <Typography variant="body2" py={0.5}>
        Add a new tag:
      </Typography>
      <Grid container minWidth={400} minHeight={40} alignItems="center">
        <Grid item xs={1}>
          <IconButton onClick={handleColorSelectorOpen} size="small">
            <Box
              sx={{
                height: '16px',
                width: '16px',
                borderRadius: '2px',
                border: 1,
                borderColor: '#888',
                backgroundColor: editingTagColor,
              }}
            />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="new-tag"
            label="New tag"
            size="small"
            sx={{ mr: '8px' }}
            value={editingTagName}
            onChange={setTagName}
            error={flagTagNameNotUnique()}
            helperText={
              flagTagNameNotUnique() && 'This tag name already exists'
            }
          />
        </Grid>
        <Grid item xs={3}>
          {' '}
        </Grid>
        <Grid item xs={1}>
          <IconButton
            onClick={submitTagAdd}
            size="small"
            disabled={disableSubmit()}
          >
            <CheckIcon sx={{ fontSize: '16px' }} />
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={cancelAdd} size="small">
            <CloseIcon sx={{ fontSize: '16px' }} />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );

  // Return

  return (
    <>
      <Button onClick={() => setOpen(true)} size="small">
        Manage tags
      </Button>
      <Dialog open={open}>
        <DialogTitle>Tags</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleDialogClose}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Box px={3} pb={1}>
          <Collapse in={!addTagOpen}>
            <IconButton aria-label="Add tag" onClick={startAdd} size="small">
              <AddIcon />
              <Box px={1} />
              <Typography variant="body1">Add a new Tag</Typography>
            </IconButton>
          </Collapse>
          <Collapse in={addTagOpen} sx={{ mx: 2 }}>
            {renderAddTag}
          </Collapse>
        </Box>
        <DialogContent sx={{ maxHeight: '640px', py: 0.5 }}>
          {renderColorSelector}
          <List sx={{ py: 0 }}>
            {tagsWithInfo.map((tagInfo: TagInfo) => (
              <ListItem key={tagInfo.tag.id}>
                {(!editingTagKey || editingTagKey !== tagInfo.tag.id) &&
                  renderTagInfo(tagInfo)}
                {editingTagKey &&
                  editingTagKey === tagInfo.tag.id &&
                  renderEditingTagInfo(tagInfo)}
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}
