// Rust-like Option type
export type Option<T> =
  | { some: true; value: T; unwrapOr: (v: T) => T }
  | { some: false; unwrapOr: (v: T) => T };

export function Some<T>(value: T): Option<T> {
  return { some: true, value, unwrapOr: () => value };
}

export function None<T>(): Option<T> {
  return { some: false, unwrapOr: (v) => v };
}
