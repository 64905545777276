import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';

import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CelebrationIcon from '@mui/icons-material/Celebration';
import GroupsIcon from '@mui/icons-material/Groups';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import Carousel from 'react-material-ui-carousel';

import TitleIcon from '@mui/icons-material/Title';
import PlaceIcon from '@mui/icons-material/Place';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { buildLoginUrl, buildRegisterUrl } from './config';

import './App.css';

const imageExamples = [
  {
    // Board game
    image:
      'https://images.pexels.com/photos/4691567/pexels-photo-4691567.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
  },
  {
    // Wine night
    image:
      'https://images.pexels.com/photos/3171815/pexels-photo-3171815.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2',
  },
  {
    // Basketball
    image: 'https://images.pexels.com/photos/1080882/pexels-photo-1080882.jpeg',
  },
];

const ExplainerCard = styled(Card)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(4),
  background: theme.palette.background.default,
}));

const BackdropPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  borderRadius: 0,
}));

export default function Landing() {
  const theme = useTheme();
  const navigate = useNavigate();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const createAccountAction = () => navigate(buildRegisterUrl());

  const topMenuSection = (
    <Stack direction="row" spacing={2} mb={{ xs: 0, md: 1 }}>
      <Box sx={{ flexGrow: 1 }} />
      <Button
        color="secondary"
        variant="outlined"
        size="small"
        onClick={createAccountAction}
      >
        Create account
      </Button>
      <Button
        color="secondary"
        size="small"
        onClick={() => navigate(buildLoginUrl())}
      >
        Sign in
      </Button>
    </Stack>
  );

  const howItWorksSection = (
    <>
      <Typography variant="h4" align="center">
        How it works
      </Typography>
      <Grid container direction={{ xs: 'column', md: 'row' }}>
        <Grid item xs={4} p={2}>
          <ExplainerCard>
            <Stack spacing={2}>
              <Box margin="auto">
                <AppShortcutIcon fontSize="large" />
              </Box>
              <Typography variant="h5">You define a gathering</Typography>
              <Typography variant="body1">
                Start by entering a few details about the gathering you&apos;d
                like to host - the date and time, number of guests, and
                potential guest list - and click &quot;Send invitations.&quot;
              </Typography>
            </Stack>
          </ExplainerCard>
        </Grid>
        <Grid item xs={4} p={2}>
          <ExplainerCard>
            <Stack spacing={2}>
              <Box margin="auto">
                <AutoFixHighIcon fontSize="large" />
              </Box>
              <Typography variant="h5">Pesky Planet organizes it</Typography>
              <Typography variant="body1">
                Pesky Planet intelligently invites your guests until the number
                of guests you set have accepted. It sends you and your guests
                reminders. If one of your guests has to cancel, it automatically
                invites another guest to fill their spot.
              </Typography>
            </Stack>
          </ExplainerCard>
        </Grid>
        <Grid item xs={4} p={2}>
          <ExplainerCard>
            <Stack spacing={2}>
              <Box margin="auto">
                <CelebrationIcon fontSize="large" />
              </Box>
              <Typography variant="h5">You have a great time</Typography>
              <Typography variant="body1">
                Whether it&apos;s getting together more often or planning a more
                amazing gathering, Pesky Planet takes the hassle out of having
                fun so you can focus on, well, having fun!
              </Typography>
            </Stack>
          </ExplainerCard>
        </Grid>
      </Grid>
    </>
  );

  const exampleCards = [
    <Card
      key="boardgame"
      sx={{
        display: 'flex',
        width: '100%',
        backgroundColor: 'theme.palette.background.default',
      }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }}>
        <CardMedia
          component="img"
          image={imageExamples[0].image}
          alt="Board game"
          sx={{ width: { md: 240, sm: '100%' } }}
        />
        <CardContent
          sx={{ width: '100%', minHeight: { xs: 500, sm: 'auto', md: 'auto' } }}
        >
          <Stack spacing={1}>
            <Typography variant="h5" sx={{ borderBottom: '1px solid #ccc' }}>
              Game night
            </Typography>
            <Typography variant="body1">
              Have a board game that requires exactly five more people to play
              but not sure who can make it? No problem!
            </Typography>
            <Typography variant="body1">
              Just tell Pesky Planet when and where you want to meet, then add
              your &quot;game night&quot; guest list. Pesky Planet will randomly
              invite your friends until five of them have accepted!
            </Typography>
            <Box sx={{ height: 'flexGrow' }} />
            <Stack
              direction={smallScreen ? 'column' : 'row'}
              spacing={3}
              alignItems="center"
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <TitleIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">Twilight Imperium</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <PeopleIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">5 guests</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <AccessTimeIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">6:30 PM, Friday</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <PlaceIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">Fred&apos;s house</Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Stack>
    </Card>,
    <Card
      key="winetasting"
      sx={{
        display: 'flex',
        width: '100%',
        backgroundColor: 'theme.palette.background.default',
      }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }}>
        <CardContent sx={{ width: '100%' }}>
          <Stack>
            <Typography variant="h5" sx={{ borderBottom: '1px solid #ccc' }}>
              Wine tasting
            </Typography>
            <Typography variant="body1">
              It&apos;s time to host another wine tasting! In the past,
              you&apos;ve found that four to six guests is best, but
              coordinating the invitations for that group is a pain. Not with
              Pesky Planet!
            </Typography>
            <Typography variant="body1">
              Just tell Pesky Planet when and where you want to meet, then add
              your &quot;wine club&quot; guest list. Pesky Planet will randomly
              invite your friends until up to six of them have accepted. As long
              as four can make it, the tasting is on!
            </Typography>
            <Stack
              direction={smallScreen ? 'column' : 'row'}
              spacing={3}
              alignItems="center"
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <TitleIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">California reds!</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <PeopleIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">4-6 guests</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <AccessTimeIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">2:30 PM, Sunday</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <PlaceIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">Sam&apos;s house</Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
        <CardMedia
          component="img"
          image={imageExamples[1].image}
          alt="Wine tasting"
          sx={{ width: { md: 240, sm: '100%' } }}
        />
      </Stack>
    </Card>,
    <Card
      key="basketball"
      sx={{
        display: 'flex',
        width: '100%',
        backgroundColor: 'theme.palette.background.default',
      }}
    >
      <Stack direction={{ xs: 'column', md: 'row' }}>
        <CardMedia
          component="img"
          image={imageExamples[2].image}
          alt="Basketball"
          sx={{ width: { md: 240, sm: '100%' } }}
        />
        <CardContent sx={{ width: '100%' }}>
          <Stack spacing={1}>
            <Typography variant="h5" sx={{ borderBottom: '1px solid #ccc' }}>
              Basketball game
            </Typography>
            <Typography variant="body1">
              You have a lot of friends who play basketball. You like playing
              3-on-3 over lunch, but like to mix up who you play with as well.
              It&apos;d be more fun if you didn&apos;t have to pick who to
              invite!
            </Typography>
            <Typography variant="body1">
              Just tell Pesky Planet when and where you want to meet, then add
              your &quot;hoops&quot; guest list. Pesky Planet will randomly
              invite your friends until five of them have accepted.
            </Typography>
            <Stack
              direction={smallScreen ? 'column' : 'row'}
              spacing={3}
              alignItems="center"
            >
              <Stack direction="row" spacing={0.5} alignItems="center">
                <TitleIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">3 on 3</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <PeopleIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">5 guests</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <AccessTimeIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">12:30 PM, Tuesday</Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <PlaceIcon sx={{ color: 'primary.main' }} />
                <Typography variant="h6">Eastside Y</Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Stack>
    </Card>,
  ];

  const examplesSection = (
    <Stack spacing={2}>
      <Typography variant="h4">Organize a ...</Typography>
      {exampleCards}
    </Stack>
  );

  const examplesSectionSmallScreen = (
    <Stack spacing={2}>
      <Typography variant="h4">Organize a ...</Typography>
      <Carousel interval={20000}>{exampleCards}</Carousel>
    </Stack>
  );

  const featuresSection = (
    <Stack spacing={2} sx={{ pt: 4, pb: 4 }}>
      <Typography variant="h4">Pesky Planet makes things easy</Typography>
      <Stack direction="row" spacing={2}>
        <SettingsSuggestIcon fontSize="large" />
        <Box>
          <Typography variant="h5">No-hassle invitations</Typography>
          <Typography variant="h6">
            Once you define a gathering and click &quot;Send invitations,&quot;
            Pesky Planet begins sending invitations to your potential guests
            until you have the number of guests you want. Should one of your
            guests need to cancel, Pesky Planet will automatically fill their
            spot.
          </Typography>
        </Box>
      </Stack>
      <Stack direction="row" spacing={2}>
        <GroupsIcon fontSize="large" />
        <Box>
          <Typography variant="h5">Easy guest organization</Typography>
          <Typography variant="h6">
            Pesky Planet makes creating and organizing guest lists super simple.
            Build guests lists by shared interests, friend groups, and more.
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );

  const endCTASection = (
    <Grid container direction="row" alignItems="flex-end" sx={{ pb: 4 }}>
      <Grid item sm={6} xs={12}>
        <Stack direction="row" spacing={1} mt={1}>
          <Stack spacing={1} mt={1}>
            <Typography variant="h4">Start getting friends together</Typography>
            <Typography variant="body1">
              You&apos;ll be glad you did!
            </Typography>
            <Box pt={4}>
              <Button variant="contained" onClick={createAccountAction}>
                Create account to get started
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Grid>
      <Grid item sm={6} xs={12} sx={{ paddingTop: { sm: 0, xs: 2 } }}>
        <Stack>
          <Stack direction="row" spacing={1} mt={1}>
            <Box sx={{ flexGrow: 1 }} />
            <Typography variant="body2">
              Copyright &copy; 2023 Bitwise IO, Inc.
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} mt={1}>
            <Box sx={{ flexGrow: 1 }} />
            <Typography variant="body2">
              <Link href="/contact_us">Contact Us</Link>
            </Typography>
            <Typography variant="body2">
              <Link href="/privacy">Privacy Policy</Link>
            </Typography>
            <Typography variant="body2">
              <Link href="/terms">Terms of Service</Link>
            </Typography>
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );

  return (
    <Stack spacing={0} sx={{ pt: { xs: 0, md: 4 } }}>
      <Container sx={{ pt: { xs: 2, md: 4 }, pb: 4 }}>
        {topMenuSection}
        <Grid container direction="row">
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: { xs: 2, md: 4 } }} />
            <Box pr={2}>
              <Typography variant="h4">Pesky Planet</Typography>
              <Typography variant="h2">
                The easiest way to get people together.
              </Typography>
              <Box pt={2} pb={4}>
                <Button variant="contained" onClick={createAccountAction}>
                  Get started
                </Button>
              </Box>
              <Stack spacing={1}>
                <Typography variant="h6">
                  Pesky Planet handles organizing your gatherings for you so you
                  can get together more often and easily.
                </Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item sm={6}>
            <img
              alt="happy people"
              style={{ width: '100%' }}
              src="https://images.pexels.com/photos/7149156/pexels-photo-7149156.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            />
          </Grid>
        </Grid>
      </Container>
      <BackdropPaper
        sx={{
          background: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }}
      >
        <Container>{howItWorksSection}</Container>
      </BackdropPaper>
      <BackdropPaper
        sx={{
          background: theme.palette.primary.light,
        }}
      >
        <Container>
          {smallScreen ? examplesSectionSmallScreen : examplesSection}
        </Container>
      </BackdropPaper>
      <Container>{featuresSection}</Container>
      <BackdropPaper
        sx={{
          background: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
        }}
      >
        <Container>{endCTASection}</Container>
      </BackdropPaper>
    </Stack>
  );
}
