import {
  MouseEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
  MutableRefObject,
  Dispatch,
  ChangeEvent,
} from 'react';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import { Option, Some, None } from '../types/Option';

export interface Selectable {
  display: () => string | ReactNode;
  value: () => string;
  key: () => number;
}

type State<T extends Selectable> = {
  options: T[];
  visibleOptions: T[];
  selectedOptions: T[];
  searchText: Option<string>;
};

export type Action<T extends Selectable> =
  | { type: 'selection'; key: number; selected: boolean }
  | { type: 'search'; searchText: string }
  | { type: 'refreshOptions'; options: T[] }
  | { type: 'refreshSelected'; selectedOptions: T[] };

function reducer<T extends Selectable>(
  state: State<T>,
  action: Action<T>,
): State<T> {
  switch (action.type) {
    case 'selection': {
      const { key, selected } = action;
      const { options, selectedOptions } = state;

      const selectedKeys = new Set();
      selectedOptions.forEach((opt) => selectedKeys.add(opt.key()));

      if (!selected) {
        selectedKeys.delete(key);
      } else {
        selectedKeys.add(key);
      }

      const updatedSelectedOptions = Array.from(selectedKeys).map(
        (k) => options.find((opt) => opt.key() === k) as T,
      );

      return { ...state, selectedOptions: updatedSelectedOptions };
    }
    case 'search': {
      const { searchText } = action;
      const { options } = state;
      let newSearchText = Some(searchText);
      let visibleOptions: T[] = [];
      if (searchText === '') {
        visibleOptions = options;
        newSearchText = None();
      } else {
        visibleOptions = options.filter(
          (t) =>
            t
              .value()
              .split(' ')
              .find((part) =>
                part.toLowerCase().startsWith(searchText.toLowerCase()),
              ) !== undefined,
        );
      }
      return { ...state, visibleOptions, searchText: newSearchText };
    }
    case 'refreshOptions': {
      // Reapply search with new options
      const { searchText } = state;
      const { options } = action;
      let visibleOptions: T[] = [];
      if (searchText.some) {
        if (searchText.value === '') {
          visibleOptions = options;
        } else {
          visibleOptions = options.filter(
            (t) =>
              t
                .value()
                .split(' ')
                .find((part) =>
                  part.toLowerCase().startsWith(searchText.value.toLowerCase()),
                ) !== undefined,
          );
        }
      } else {
        visibleOptions = options;
      }
      return {
        ...state,
        options,
        visibleOptions,
      };
    }
    case 'refreshSelected': {
      const { options } = state;
      const { selectedOptions } = action;

      const selectedKeys = new Set();
      selectedOptions.forEach((opt) => selectedKeys.add(opt.key()));

      const updatedSelectedOptions = Array.from(selectedKeys).map(
        (k) => options.find((opt) => opt.key() === k) as T,
      );

      return { ...state, selectedOptions: updatedSelectedOptions };
    }
    default:
      return { ...state };
  }
}

const StateContext = createContext<State<any>>({} as State<any>);
const DispatchContext = createContext<(action: Action<Selectable>) => void>(
  () => {},
);

export function SearchableMultiSelectProvider<T extends Selectable>({
  options,
  children,
  selectedOptions,
  dispatchRef,
}: {
  options: T[];
  children: ReactNode;
  selectedOptions: T[];
  dispatchRef?: MutableRefObject<Dispatch<Action<Selectable>> | null>;
}) {
  const [state, dispatch] = useReducer(reducer, {
    options,
    visibleOptions: options,
    selectedOptions,
    searchText: None<string>(),
  });

  // We need to be able to set the dispatch for this intance
  /* eslint-disable no-param-reassign */
  useEffect(() => {
    if (dispatchRef) {
      dispatchRef!.current = dispatch;
    }
  });

  // Listen for changes in options
  useEffect(() => {
    dispatch({ type: 'refreshOptions', options });
  }, [options, dispatch]);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

SearchableMultiSelectProvider.defaultProps = {
  dispatchRef: null,
};

function useSearchableMultiSelectState<T extends Selectable>(): State<T> {
  const state = useContext(StateContext);
  return state as State<T>;
}

export function useSearchableMultiSelectDispatch() {
  const dispatch = useContext(DispatchContext);
  return dispatch;
}

const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

type SearchableMultiSelectMenuProps<T extends Selectable> = {
  id: string;
  label: string;
  onSelection: (selected: T[]) => void;
  onEmpty?: ReactNode;
  disabled?: boolean;
  handleNew?: (name: string) => void;
  handleNewDisplay?: (name: string) => string | ReactNode;
  labelIcon?: ReactNode;
  validSearch?: (e: any) => {};
};

export function SearchableMultiSelectMenu<T extends Selectable>(
  props: SearchableMultiSelectMenuProps<T>,
) {
  const {
    id,
    label,
    onSelection,
    onEmpty = '',
    disabled = false,
    handleNew = null,
    handleNewDisplay = null,
    labelIcon = <KeyboardArrowDownIcon />,
    validSearch = () => true,
  } = props;
  const { options, visibleOptions, selectedOptions, searchText } =
    useSearchableMultiSelectState<T>();
  const dispatch = useSearchableMultiSelectDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleChange = useCallback(
    (
      event: MouseEvent<HTMLElement>,
      index: number,
      currentlySelected: boolean,
    ) => {
      const selection = visibleOptions[index];
      dispatch({
        type: 'selection',
        key: selection.key(),
        selected: !currentlySelected,
      });
    },
    [dispatch, visibleOptions],
  );

  const previousSeletion = usePrevious(selectedOptions);
  useEffect(() => {
    if ((previousSeletion?.length || 0) !== selectedOptions.length) {
      onSelection(selectedOptions);
    }
  }, [onSelection, selectedOptions, previousSeletion]);

  const handleOpen = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl],
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault();
      if (!validSearch || validSearch(e)) {
        dispatch({ type: 'search', searchText: e.target.value });
      }
    },
    [dispatch, validSearch],
  );

  return (
    <div>
      <Button
        id={`${id}-button`}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disabled={disabled}
        onClick={handleOpen}
        endIcon={labelIcon}
      >
        {label}
      </Button>
      <Menu
        id={id}
        MenuListProps={{
          sx: {
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
            '& ul': { padding: 0 },
          },
          subheader: <li />,
          dense: true,
        }}
        autoFocus={false}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <ListSubheader color="inherit" sx={{ paddingTop: '5px' }}>
          <TextField
            size="small"
            fullWidth
            autoFocus
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchText.unwrapOr('')}
            onChange={handleSearch}
            onKeyDown={(e: any) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
            disabled={options.length === 0 && handleNew == null}
          />
        </ListSubheader>
        {handleNew && (
          <MenuItem
            key="add new"
            onClick={() => {
              handleNew(searchText.unwrapOr(''));
              dispatch({ type: 'search', searchText: '' });
            }}
            disabled={!searchText.some}
          >
            <AddIcon />
            <ListItem component="div">
              Add new
              {handleNewDisplay && searchText.some
                ? handleNewDisplay(searchText.unwrapOr(''))
                : searchText.unwrapOr('')}
            </ListItem>
          </MenuItem>
        )}
        {visibleOptions.map((variant: Selectable, index: number) => {
          const isSelected =
            selectedOptions.findIndex(
              (item: Selectable) => item.key() === variant.key(),
            ) >= 0;
          return (
            <MenuItem
              key={variant.key()}
              onClick={(event) => handleChange(event, index, isSelected)}
            >
              <Checkbox checked={isSelected} sx={{ p: { xs: 1, md: 0 } }} />
              <ListItem component="div">{variant.display()}</ListItem>
            </MenuItem>
          );
        })}
        {options.length === 0 ? <MenuItem disabled>{onEmpty}</MenuItem> : null}
      </Menu>
    </div>
  );
}

SearchableMultiSelectMenu.defaultProps = {
  onEmpty: '',
  disabled: false,
  handleNew: null,
  handleNewDisplay: null,
  labelIcon: <KeyboardArrowDownIcon />,
  validSearch: () => true,
};

type Props<T extends Selectable> = {
  id: string;
  label: string;
  options: T[];
  onSelection: (selected: T[]) => void;
  onEmpty?: ReactNode;
  disabled?: boolean;
};

export default function SearchableMultiSelect<T extends Selectable>(
  props: Props<T>,
) {
  const {
    id,
    label,
    options,
    onSelection,
    onEmpty = '',
    disabled = false,
  } = props;

  return (
    <SearchableMultiSelectProvider options={options} selectedOptions={[]}>
      <SearchableMultiSelectMenu
        id={id}
        label={label}
        onSelection={onSelection}
        onEmpty={onEmpty}
        disabled={disabled}
      />
    </SearchableMultiSelectProvider>
  );
}

SearchableMultiSelect.defaultProps = {
  onEmpty: '',
  disabled: false,
};
