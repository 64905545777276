import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom';

function CongratulationsUpdate() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Container>
      <h2 className="Title">Congratulations!</h2>
      <p>
        Your gathering has been updated! Here&apos;s what&apos;s going to happen
        next.
        <br />
        <br />
        We will notify your guests of the changes. If your changes included
        updating the number of guests or who is invited new invitations will be
        sent out. We&apos;ll randomly select guests so{' '}
        {location.state.requiredGuests} have invites.
      </p>
      <Button
        variant="contained"
        fullWidth={false}
        onClick={() =>
          navigate(`/app/gatherings/${location.state.gatheringId}`)
        }
        sx={{ margin: '2rem 0rem' }}
      >
        Back to gathering
      </Button>
    </Container>
  );
}

export default CongratulationsUpdate;
