// A card for displaying gathering guest information to a host

import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import TagChip from './TagChip';

import { GatheringGuest } from '../models/GatheringGuest';
import { Tag } from '../models/Tag';

export type Props = {
  guest: GatheringGuest;
  tags?: Tag[] | undefined; // List of all the host's orbit tags; used for formatting
  onClick?: () => void;
  removable?: boolean;
  addable?: boolean;
  onRemove?: () => void;
  reAdd?: () => void;
};

type TagsProps = {
  guest: GatheringGuest;
  tags: Tag[];
};
function TagsDisplay({ guest, tags }: TagsProps) {
  const [tagsExpanded, setTagsExpanded] = useState(false);
  const guestTags = tags.filter((tag: Tag) => guest.tags.includes(tag.id));
  const tagChips = guestTags.map((tag: Tag) => {
    const returned: [key: number, chip: React.ReactElement] = [
      tag.id,
      <TagChip label={tag.tag} color={tag.color} />,
    ];
    return returned;
  });

  const handleExpandTags = (event: React.MouseEvent<HTMLElement>) => {
    setTagsExpanded(true);
    event.stopPropagation();
  };

  return (
    <>
      {tagChips.slice(0, 5).map(([key, chip]) => (
        <Grid item key={key}>
          {chip}
        </Grid>
      ))}
      {!tagsExpanded && tagChips.length - 5 > 0 && (
        <IconButton onClick={handleExpandTags} sx={{ mt: 1, p: 0 }}>
          <Typography variant="caption" ml={1}>
            {`and ${tagChips.length - 5} more`}
          </Typography>
          <ExpandMoreIcon />
        </IconButton>
      )}
      {tagsExpanded &&
        tagChips.length - 5 > 0 &&
        tagChips.slice(5, tagChips.length).map(([key, chip]) => (
          <Grid item key={key}>
            {chip}
          </Grid>
        ))}
    </>
  );
}

export default function GuestCard({
  guest,
  tags,
  onClick,
  addable,
  removable,
  onRemove,
  reAdd,
}: Props) {
  const theme = useTheme();
  const fullName =
    `${guest.effective_first_name} ${guest.effective_last_name}`.trim();
  const letter = fullName.charAt(0).toUpperCase();

  return (
    <Card onClick={onClick}>
      <Box sx={{ m: 1 }}>
        {!guest.contactable && (
          <PriorityHighIcon
            color="warning"
            sx={{ position: 'absolute', ml: -1, mt: 2 }}
          />
        )}
        <Box ml={2}>
          <Grid container direction="row" alignItems="center" spacing={1}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={0.5} mb={0.5} alignItems="center">
                <div
                  style={{
                    position: 'relative',
                    marginRight: '8px',
                  }}
                >
                  {guest.linked_account_id ? (
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <CheckCircleIcon
                          style={{ fontSize: 16 }}
                          color="success"
                          sx={{
                            backgroundColor: theme.palette.background.default,
                            borderRadius: '50%',
                          }}
                        />
                      }
                    >
                      <Avatar alt={fullName}>{letter}</Avatar>
                    </Badge>
                  ) : (
                    <Avatar alt={fullName}>{letter}</Avatar>
                  )}
                </div>
                <Typography
                  noWrap
                  sx={{
                    fontSize: {
                      xs: 'body1.fontSize', // Font size for xs screens
                      sm: 'h6.fontSize', // Font size for sm screens and up
                    },
                  }}
                >
                  {guest.effective_first_name} {guest.effective_last_name}
                </Typography>
                {removable && (
                  <>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={onRemove}>
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </>
                )}
                {addable && (
                  <>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton onClick={reAdd}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </>
                )}
              </Stack>
            </Grid>
            {tags && <TagsDisplay guest={guest} tags={tags} />}
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}

GuestCard.defaultProps = {
  tags: undefined,
  onClick: () => {},
  removable: false,
  addable: false,
  reAdd: () => {},
  onRemove: () => {},
};
