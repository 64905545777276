// This component is meant to display a  invitation when there are on a screen.
// For displaying an invitation when there is only one on the screen, use
// Gathering.

import { useTheme } from '@mui/material/styles';

import moment from 'moment';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import { CardActionArea } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlaceIcon from '@mui/icons-material/Place';

import { ProfileSettings } from '../models/ProfileSettings';
import { useAccount } from '../contexts/AccountContext';
import InvitationExpiring from './InvitationExpiring';

export type InvitationProps = {
  date: moment.Moment | null | undefined;
  title: string;
  place?: string;
  hostName: string;
  createdAt: string;
  inviteDuration: number;
  profileSettings: ProfileSettings;
  onClick?: () => void;
};

export default function InvitationCard(props: InvitationProps) {
  const {
    date,
    title,
    place = '',
    hostName,
    createdAt,
    inviteDuration,
    profileSettings,
    onClick,
  } = props;
  const { timezone } = useAccount();

  const theme = useTheme();
  const displayDate = date || moment().add(1, 'day').startOf('hour');
  const localized = displayDate.locale(profileSettings.locale).tz(timezone);
  const dayOfWeek = localized.format('dddd');
  const yearMonthDay = localized.format('MMM D, YYYY');
  const hoursMinutes = localized.format('LT');

  return (
    <Card
      sx={{ width: '100%', height: '200px' }}
      style={{ border: `2px solid ${theme.palette.primary.main}` }}
    >
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Stack>
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              sx={{ width: '100%', paddingTop: 1 }}
            >
              {' '}
              <Typography variant="h6" component="div">
                You&apos;re invited!{' '}
                <span role="img" aria-label="celebrate">
                  🎉
                </span>
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <InvitationExpiring
                createdAt={createdAt}
                inviteDuration={inviteDuration}
              />
            </Stack>

            <Typography variant="h5" component="div">
              {title}
            </Typography>
            <time dateTime={displayDate.format()}>
              <Stack direction="row" spacing={1}>
                <Typography variant="body1" component="div">
                  {dayOfWeek}
                </Typography>
                <Typography variant="body1" component="div">
                  {yearMonthDay}
                </Typography>
                <Typography variant="body1" component="div">
                  {hoursMinutes}
                </Typography>
              </Stack>
            </time>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 0.5, sm: 3 }}
            >
              <>
                <Stack direction="row" spacing={0.5}>
                  <AccountCircleIcon fontSize="small" />
                  <Typography variant="body1" component="div" fontWeight="bold">
                    {hostName}
                  </Typography>
                </Stack>
                {place && (
                  <Stack direction="row" spacing={0.5} width={{ xs: 150 }}>
                    <PlaceIcon fontSize="small" />
                    <Typography variant="body1" component="div" noWrap>
                      {place}
                    </Typography>
                  </Stack>
                )}
              </>
            </Stack>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

InvitationCard.defaultProps = {
  place: '',
  onClick: () => {},
};

export function InvitationCardPlaceholder() {
  return (
    <Card sx={{ width: '100%', height: '170px' }}>
      <CardContent>
        <Typography variant="h6">
          <Skeleton width={128} />
        </Typography>
        <Typography variant="h5">
          <Skeleton />
        </Typography>
        <Typography variant="body1" component="div">
          <Skeleton width={256} />
        </Typography>
        <Typography variant="body1" component="div">
          <Skeleton width={256} />
        </Typography>
      </CardContent>
    </Card>
  );
}
