import { useState } from 'react';

import { Alert, IconButton, Stack, TextField, Typography } from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

import PasswordInput, { validPasswordDescription } from '../PasswordInput';

type Props = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  onChange: ({
    currentPassword,
    newPassword,
    confirmPassword,
  }: {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => void;
  successMsg: string;
  errorMsg: string;
};

export default function ManagePassword({
  currentPassword,
  newPassword,
  confirmPassword,
  onChange,
  successMsg,
  errorMsg,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <Stack spacing={2} mr="auto" ml="auto">
      {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
      {successMsg && <Alert severity="success">{successMsg}</Alert>}
      <Typography variant="body1" fontWeight="bold">
        Update Password
      </Typography>
      {validPasswordDescription}
      <TextField
        fullWidth
        label="Current Password"
        type={showPassword ? 'text' : 'password'}
        value={currentPassword}
        onChange={(e) => {
          onChange({
            currentPassword: e.target.value,
            newPassword,
            confirmPassword,
          });
        }}
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ paddingBottom: '20px' }}
        onKeyDown={(e: any) => {
          e.stopPropagation();
        }}
      />
      <PasswordInput
        id="password"
        label="New Password"
        value={newPassword}
        onChange={(val: string) => {
          onChange({
            currentPassword,
            newPassword: val,
            confirmPassword,
          });
        }}
        required
      />
      <PasswordInput
        id="confirm_password"
        label="Confirm Password"
        value={confirmPassword}
        onChange={(val: string) => {
          onChange({
            currentPassword,
            newPassword,
            confirmPassword: val,
          });
        }}
        required
        error_string={
          confirmPassword !== newPassword ? 'Passwords must match' : ''
        }
      />
    </Stack>
  );
}
