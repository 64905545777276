import {
  BrowserRouter,
  Outlet,
  Navigate,
  Routes,
  Route,
} from 'react-router-dom';

import './App.css';
import { SessionProvider } from './contexts/Session';
import Landing from './Landing';
import { AuthenticatedClientProvider } from './providers/AuthenticatedClientProvider';
import { UnauthenticatedClientProvider } from './providers/UnauthenticatedClientProvider';
import { AccountProvider } from './contexts/AccountContext';
import AddGathering from './views/user/AddGathering';
import EditGathering from './views/user/EditGathering';
import Congratulations from './views/Congratulations';
import CongratulationsUpdate from './views/CongratulationsUpdate';
import GatheringDisplay from './views/user/GatheringDisplay';
import InvitationDisplay from './views/user/InvitationDisplay';
import VisitorInvitationDisplay from './views/visitor/VisitorInvitationDisplay';
import VisitorGatheringDisplay from './views/visitor/VisitorGatheringDisplay';
import MyGatherings from './views/user/MyGatherings';
import MyHome from './views/user/MyHome';
import ContactVerify from './views/user/ContactVerify';
import ContactVerifySuccess from './views/user/ContactVerifySuccess';
import SignIn from './views/SignIn';
import ForgotPassword from './views/ForgotPassword';
import ChangePassword from './views/ChangePassword';
import Register from './views/Register';
import RegisterVerify from './views/RegisterVerify';
import RegistrationSuccess from './views/RegistrationSuccess';
import NotFound from './views/NotFound';
import ContactUs from './views/ContactUs';
import PrivacyPolicy from './views/PrivacyPolicy';
import TermsOfService from './views/TermsOfService';
import PeskyNavigation from './components/AppNavigation';
import SignOut from './components/SignOut';
import Contacts from './views/user/Contacts';
import LandingThemeProvider from './providers/LandingThemeProvider';
import ThemeProvider from './providers/ThemeProvider';

function AppInner() {
  return (
    <div className="App">
      <main>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <LandingThemeProvider>
                  <UnauthenticatedClientProvider>
                    <Outlet />
                  </UnauthenticatedClientProvider>
                </LandingThemeProvider>
              }
            >
              <Route index element={<Landing />} />
              <Route path="/contact_us" element={<ContactUs />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsOfService />} />
              <Route path="/auth/" element={<Outlet />}>
                <Route path="/auth/sign_in" element={<SignIn />} />
                <Route path="/auth/forgot_pwd" element={<ForgotPassword />} />
                <Route path="/auth/ch_pwd" element={<ChangePassword />} />
                <Route
                  path="/auth/sign_out"
                  element={
                    <AuthenticatedClientProvider>
                      <SignOut />
                    </AuthenticatedClientProvider>
                  }
                />
                <Route path="/auth/register" element={<Register />} />
                <Route
                  path="/auth/register_verify/:code"
                  element={<RegisterVerify />}
                />
              </Route>
              <Route
                path="/invitations/:invitationCode"
                element={<VisitorInvitationDisplay />}
              />
              <Route
                path="/gatherings/:gatheringId"
                element={<VisitorGatheringDisplay />}
              />
            </Route>
            <Route
              path="/contacts_verify/:code"
              element={
                <ThemeProvider>
                  <AuthenticatedClientProvider>
                    <ContactVerify />
                  </AuthenticatedClientProvider>
                </ThemeProvider>
              }
            />
            <Route
              path="/app/"
              element={
                <ThemeProvider>
                  <AuthenticatedClientProvider>
                    <AccountProvider>
                      <PeskyNavigation />
                    </AccountProvider>
                  </AuthenticatedClientProvider>
                </ThemeProvider>
              }
            >
              <Route index element={<Navigate to="/app/home" replace />} />
              <Route path="/app/gatherings" element={<MyGatherings />} />
              <Route path="/app/gatherings/add" element={<AddGathering />} />
              <Route
                path="/app/congratulations"
                element={<Congratulations />}
              />
              <Route
                path="/app/congratulations_update"
                element={<CongratulationsUpdate />}
              />
              <Route
                path="/app/gatherings/:gatheringId"
                element={<GatheringDisplay />}
              />
              <Route
                path="/app/gatherings/:gatheringId/edit"
                element={<EditGathering />}
              />
              <Route path="/app/home" element={<MyHome />} />
              <Route
                path="/app/invitations/:invitationIdOrCode"
                element={<InvitationDisplay />}
              />
              <Route path="/app/contacts" element={<Contacts />} />
              <Route
                path="/app/registration_success"
                element={<RegistrationSuccess />}
              />
              <Route
                path="/app/contacts_verify_success"
                element={<ContactVerifySuccess />}
              />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </main>
    </div>
  );
}

function App() {
  return (
    <SessionProvider>
      <AppInner />
    </SessionProvider>
  );
}

export default App;
