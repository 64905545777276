// This component is only used in the Orbit screen
// It encapsulates the complexity in displaying, editing, and adding guest information
import {
  SyntheticEvent,
  useCallback,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useRef,
  MutableRefObject,
  ReactNode,
  ChangeEvent,
} from 'react';

import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Avatar,
} from '@mui/material';

import {
  Close as CloseIcon,
  Email as EmailIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';

import { Guest } from '../models/Guest';
import { Tag, NewTagId } from '../models/Tag';
import RequiredTextField from './RequiredTextField';
import EmailInput from './EmailInput';
import TagChip from './TagChip';
import PeskyButton from './Button';
import {
  SearchableMultiSelectProvider,
  SearchableMultiSelectMenu,
  Selectable,
  Action,
} from './SearchableMultiSelect';
import { useRequiredAuthenticatedClient } from '../providers/AuthenticatedClientProvider';
import { NewGuestId } from '../models/NewGuest';
import { Result } from '../types/Result';
import { Data } from '../types/Data';
import ErrorResponse from '../types/ErrorResponse';
import { definedTagColors } from '../providers/palletes';

const inputMaxLength = 256;

// A wrapper to make a tag selectable
class SelectableTag implements Selectable {
  tag: Tag;

  constructor(tag: Tag) {
    this.tag = tag;
  }

  display() {
    const { tag, color } = this.tag;
    return <TagChip key={tag} label={tag} color={color} />;
  }

  key() {
    return this.tag.id;
  }

  value() {
    return this.tag.tag;
  }
}

function EditableTagsInner({
  setGuestTags,
  handleNew,
  handleNewDisplay,
}: {
  setGuestTags: Dispatch<SetStateAction<number[]>>;
  handleNew: (name: string) => void;
  handleNewDisplay: (name: string) => ReactNode;
}) {
  return (
    <SearchableMultiSelectMenu
      id="tag-select"
      label="Add Tags"
      labelIcon={<AddIcon />}
      onSelection={(selected: SelectableTag[]) => {
        setGuestTags(selected.map((s) => s.tag.id));
      }}
      handleNew={handleNew}
      handleNewDisplay={handleNewDisplay}
      validSearch={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
        e.target.value.length < 40 && e.target.value !== ' '
      }
    />
  );
}

function EditableTags({
  setGuestTags,
  orbitTags,
  currentTags,
  dispatchTagRef,
  handleNew,
  handleNewDisplay,
}: {
  setGuestTags: Dispatch<SetStateAction<number[]>>;
  orbitTags: Tag[];
  currentTags: Tag[];
  dispatchTagRef: MutableRefObject<Dispatch<Action<Selectable>> | null>;
  handleNew: (name: string) => void;
  handleNewDisplay: (name: string) => ReactNode;
}) {
  return (
    <SearchableMultiSelectProvider
      options={orbitTags.map((t) => new SelectableTag(t))}
      selectedOptions={currentTags.map((t) => new SelectableTag(t))}
      dispatchRef={dispatchTagRef}
    >
      <EditableTagsInner
        setGuestTags={setGuestTags}
        handleNew={handleNew}
        handleNewDisplay={handleNewDisplay}
      />
    </SearchableMultiSelectProvider>
  );
}

export type Props = {
  open: boolean;
  guest: Guest | null;
  orbitTags: Tag[];
  onClose: (reload: boolean, newGuest: Guest | null) => void;
  currentTags: Tag[];
  refreshGuestTags: (guestTags: number[]) => void;
};

export default function GuestInfoDialog({
  open,
  guest,
  orbitTags,
  onClose,
  currentTags,
  refreshGuestTags,
}: Props) {
  const [infoEditable, setInfoEditable] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>(
    guest?.effective?.effective_first_name || guest?.first_name || '',
  );
  const [lastName, setLastName] = useState<string>(
    guest?.effective?.effective_last_name || guest?.last_name || '',
  );
  const [email, setEmail] = useState<string>(guest?.email || '');
  const [guestTags, setGuestTags] = useState<number[]>(guest?.tags || []);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [needReload, setReload] = useState<boolean>(false);
  const [contactable, setContactable] = useState<boolean>(
    guest?.contactable || false,
  );
  const dispatchTagRef = useRef<Dispatch<Action<Selectable>> | null>(null);
  const [newTagColor, setNewTagColor] = useState<string>(
    definedTagColors[Math.floor(Math.random() * definedTagColors.length)],
  );
  const [deleteExpanded, setDeleteExpanded] = useState(false);
  const removeContactRef = useRef<HTMLDivElement | null>(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const client = useRequiredAuthenticatedClient();

  // Update guest info when the active guest changes
  useEffect(() => {
    setFirstName(
      guest?.effective?.effective_first_name || guest?.first_name || '',
    );
    setLastName(
      guest?.effective?.effective_last_name || guest?.last_name || '' || '',
    );
    setEmail(guest?.email || '');
    setContactable(guest?.contactable || false);
    setGuestTags(guest?.tags || []);
    setErrorMsg('');
  }, [guest]);

  // Listen for changes in selectedOptions
  useEffect(() => {
    dispatchTagRef.current?.({
      type: 'refreshSelected',
      selectedOptions: currentTags.map((t) => new SelectableTag(t)),
    });
  }, [currentTags, dispatchTagRef]);

  const handleGuestDialogClose = useCallback(
    (reload: boolean, newGuest: Guest | null) => {
      onClose(reload, newGuest);
      setFirstName('');
      setLastName('');
      setEmail('');
      setContactable(false);
      setGuestTags([]);
      setErrorMsg('');
      setInfoEditable(false);
      setReload(false);
      setDeleteExpanded(false);
    },
    [
      onClose,
      setFirstName,
      setLastName,
      setEmail,
      setGuestTags,
      setErrorMsg,
      setInfoEditable,
      setReload,
      setDeleteExpanded,
    ],
  );

  const renderDisplayTags = (values: number[]) =>
    orbitTags
      .filter((tag: Tag) => values.includes(tag.id))
      .map((tag: Tag) => (
        <TagChip key={tag.id} label={tag.tag} color={tag.color} margin={0.25} />
      ));

  const handleSubmit = useCallback(() => {
    if (email !== guest?.email) {
      setContactable(true);
    }

    client
      .save_guest(guest?.id || 0, {
        id: guest?.id || undefined,
        host_id: guest?.host_id || undefined,
        first_name: !guest?.linked_account_id ? firstName : undefined,
        last_name: !guest?.linked_account_id ? lastName : undefined,
        email: email !== '' ? email : undefined,
        contactable: email !== guest?.email ? true : contactable,
        linked_account_id: guest?.linked_account_id || undefined,
        tags: guestTags,
      })
      .then((result: Result<void, ErrorResponse>) => {
        if (result.ok) {
          setInfoEditable(false);
          setReload(true);
          setErrorMsg('');
        } else {
          setErrorMsg(result.error?.message || '');
        }
      })
      .catch(() => {
        setErrorMsg('An unknown error has occurred');
      });
  }, [
    client,
    firstName,
    lastName,
    email,
    guestTags,
    guest,
    setReload,
    contactable,
    setContactable,
  ]);

  const deleteGuest = useCallback(() => {
    client
      .delete_guest(guest?.id || 0, true)
      .then((result: Result<void, ErrorResponse>) => {
        if (result.ok) {
          handleGuestDialogClose(true, null);
        } else {
          setErrorMsg(result.error?.message || '');
        }
      })
      .catch(() => {
        setErrorMsg('An unknown error has occurred');
      });
  }, [client, guest, handleGuestDialogClose]);

  const handleSubmitNewGuest = useCallback(
    (event: SyntheticEvent) => {
      event.preventDefault();
      client
        .save_new_guest({
          first_name: firstName,
          last_name: lastName,
          email: email !== '' ? email : undefined,
          tags: guestTags,
        })
        .then((save_result: Result<NewGuestId, ErrorResponse>) => {
          if (save_result.ok) {
            client
              .get_guest(save_result.value.data.orbit_guest_id)
              .then((result: Result<Data<Guest>, ErrorResponse>) => {
                if (result.ok) {
                  handleGuestDialogClose(true, result.value.data);
                }
              })
              .catch(() => {
                setErrorMsg('An unknown error has occurred');
              });
          } else {
            setErrorMsg(save_result.error?.message || '');
          }
        })
        .catch(() => {
          setErrorMsg('An unknown error has occurred');
        });
    },
    [client, firstName, lastName, email, guestTags, handleGuestDialogClose],
  );

  const handleNewDisplay = (name: string) => (
    <TagChip key={name} label={name} color={newTagColor} margin={0.25} />
  );

  const handleNew = (name: string) => {
    if (orbitTags.some((tag) => tag.tag.toLowerCase() === name.toLowerCase())) {
      setErrorMsg(`Tag with name '${name}' already exists`);
      return;
    }

    client
      .save_new_tag({
        tag: name,
        color: newTagColor,
      })
      .then((result: Result<NewTagId, ErrorResponse>) => {
        if (!result.ok) {
          setErrorMsg(result.error.message);
        } else {
          guestTags.push(result.value.data.orbit_tag_id);
          refreshGuestTags(guestTags);
          setNewTagColor(
            definedTagColors[
              Math.floor(Math.random() * definedTagColors.length)
            ],
          );
        }
      })
      .catch(() => {
        setErrorMsg('An unknown error has occurred');
      });
  };

  // Add a new guest
  if (guest === null) {
    return (
      <Dialog open={open} fullWidth maxWidth="xs" fullScreen={fullScreen}>
        <DialogContent>
          <form onSubmit={handleSubmitNewGuest}>
            <Collapse in={errorMsg !== ''}>
              <Alert severity="error">{errorMsg}</Alert>
            </Collapse>
            <Stack direction="row" mb={2}>
              <Typography variant="h5">Add a contact</Typography>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton
                onClick={() => handleGuestDialogClose(needReload, null)}
              >
                <CloseIcon style={{ fontSize: 24 }} />
              </IconButton>
            </Stack>
            <Stack spacing={1}>
              <RequiredTextField
                id="first-name"
                label="First name"
                value={firstName}
                onChange={(val: string) => setFirstName(val)}
                maxLength={inputMaxLength}
              />
              <RequiredTextField
                id="last-name"
                label="Last name"
                value={lastName}
                onChange={(val: string) => setLastName(val)}
                maxLength={inputMaxLength}
              />

              <EmailInput
                id="email"
                label="Email"
                value={email || ''}
                onChange={(val: string) => setEmail(val)}
                maxLength={inputMaxLength}
                required={false}
              />
            </Stack>
            <Box pt={2}>
              <Grid container spacing={1}>
                <Grid item key="tag-select">
                  <EditableTags
                    setGuestTags={setGuestTags}
                    orbitTags={orbitTags}
                    currentTags={currentTags}
                    dispatchTagRef={dispatchTagRef}
                    handleNew={handleNew}
                    handleNewDisplay={handleNewDisplay}
                  />
                </Grid>
                <Grid item xs={12} sm={8} key="tag-list">
                  {renderDisplayTags(guestTags)}
                </Grid>
              </Grid>
              <Box p={1} />
              <Stack direction="row">
                <Box sx={{ flexGrow: 1 }} />
                <Button
                  variant="contained"
                  disabled={
                    firstName === '' ||
                    lastName === '' ||
                    (email && !email.includes('@')) ||
                    email.includes(' ')
                  }
                  type="submit"
                  onClick={handleSubmitNewGuest}
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    );
  }

  const displayTags = (
    <>
      <Stack spacing={2}>
        <Typography variant="body1" fontWeight="bold" pb="5px">
          Tags
        </Typography>
      </Stack>
      {guestTags.length > 0 ? (
        renderDisplayTags(guestTags)
      ) : (
        <Typography variant="caption">
          No tags are associated with this user.
        </Typography>
      )}
    </>
  );

  const handleCancelEditableGuestInfo = () => {
    setFirstName(
      guest?.effective?.effective_first_name || guest?.first_name || '',
    );
    setLastName(
      guest?.effective?.effective_last_name || guest?.last_name || '',
    );
    setEmail(guest?.effective?.effective_email || guest?.email || '');
    setGuestTags(guest?.tags || []);
    refreshGuestTags(guest?.tags || []);
    setContactable(guest?.contactable || false);
    setInfoEditable(false);
    setErrorMsg('');
  };

  const displayProfileInfo = (
    <Stack>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
      >
        <Avatar
          alt={`${firstName} ${lastName}`}
          sx={{
            height: '80px',
            width: '80px',
            fontSize: '56px',
            marginRight: '20px',
          }}
        >
          {firstName !== '' ? firstName.substring(0, 1) : null}
        </Avatar>
        <Typography variant="h5">{`${firstName} ${lastName}`}</Typography>
      </Stack>
    </Stack>
  );

  const displayGuestInfo = (
    <>
      {!contactable && email && (
        <Alert severity="warning">
          Unable to contact {guest.email}. Update with correct email.
        </Alert>
      )}
      {!contactable && !email && <Alert severity="warning">Add an email</Alert>}
      <Box pt={1} />
      <Stack spacing={1}>
        <Card sx={{ minWidth: 275 }}>
          <CardHeader
            subheader="Visible to gathering guests"
            sx={{ paddingBottom: 0 }}
          />
          <CardContent>
            <Typography variant="body1" fontWeight="bold">
              Name
            </Typography>
            <Typography variant="body1">{`${firstName} ${lastName}`}</Typography>
          </CardContent>
        </Card>

        <Card sx={{ minWidth: 275 }}>
          <CardHeader
            subheader="Visible only to you"
            sx={{ paddingBottom: 0 }}
          />
          <CardContent>
            <Typography variant="body1" fontWeight="bold">
              Email
            </Typography>
            <Stack direction="row" spacing={2}>
              <EmailIcon />
              <Typography variant="body1">
                {email || 'No email configured'}
              </Typography>
            </Stack>

            <Box pt={2} />
            {displayTags}
          </CardContent>
        </Card>
      </Stack>
    </>
  );

  const editableGuestInfo = (
    <>
      <Card sx={{ minWidth: 275 }}>
        <CardHeader
          subheader="Visible to gathering guests"
          sx={{ paddingBottom: 0 }}
        />
        <CardContent>
          <Stack spacing={2}>
            <RequiredTextField
              id="first-name"
              label="First name"
              value={firstName}
              onChange={(val: string) => setFirstName(val)}
              maxLength={inputMaxLength}
            />
            <RequiredTextField
              id="last-name"
              label="Last name"
              value={lastName}
              onChange={(val: string) => setLastName(val)}
              maxLength={inputMaxLength}
            />
          </Stack>
        </CardContent>
      </Card>
      <Box pt={2} />
      <Card sx={{ minWidth: 275 }}>
        <CardHeader subheader="Visible only to you" sx={{ paddingBottom: 0 }} />
        <CardContent>
          <Stack spacing={2}>
            <EmailInput
              id="email"
              label="Email"
              value={email || ''}
              onChange={(val: string) => setEmail(val)}
              maxLength={inputMaxLength}
            />
            <div>
              {infoEditable ? (
                <Grid container spacing={1}>
                  <Grid item key="tag-select">
                    <EditableTags
                      setGuestTags={setGuestTags}
                      orbitTags={orbitTags}
                      currentTags={currentTags}
                      dispatchTagRef={dispatchTagRef}
                      handleNew={handleNew}
                      handleNewDisplay={handleNewDisplay}
                    />
                  </Grid>
                  <Grid item xs={12} sm={8} key="tag-list">
                    {renderDisplayTags(guestTags)}
                  </Grid>
                </Grid>
              ) : (
                displayTags
              )}
            </div>
          </Stack>
        </CardContent>
      </Card>
    </>
  );

  const PreviousHostInfo = (
    <Stack spacing={1}>
      {guest.first_name && guest.last_name && (
        <>
          <Typography variant="body1" fontWeight="bold">
            Name
          </Typography>
          <Typography variant="body1">{`${guest.first_name} ${guest.last_name}`}</Typography>
        </>
      )}
      {email && (
        <>
          <Typography variant="body1" fontWeight="bold">
            Email
          </Typography>
          <Stack direction="row" spacing={2}>
            <EmailIcon />
            <Typography variant="body1">{email}</Typography>
          </Stack>
        </>
      )}
    </Stack>
  );

  return (
    <Dialog open={open} fullWidth maxWidth="sm" fullScreen={fullScreen}>
      <DialogContent>
        <Collapse in={errorMsg !== ''}>
          <Alert severity="error">{errorMsg}</Alert>
        </Collapse>
        <Stack spacing={2}>
          <Stack direction="row">
            <Typography variant="h6" fontWeight="bold">
              Contact
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            {guest && !infoEditable && (
              <Stack direction="row" marginRight="20px">
                <Box sx={{ flexGrow: 1 }} />
                <IconButton
                  onClick={() => {
                    setDeleteExpanded(false);
                    setInfoEditable(true);
                  }}
                  sx={{ padding: '0px' }}
                >
                  <EditIcon style={{ fontSize: 24 }} />
                </IconButton>
              </Stack>
            )}
            <IconButton
              onClick={() => handleGuestDialogClose(needReload, null)}
              sx={{ padding: '0px' }}
            >
              <CloseIcon style={{ fontSize: 24 }} />
            </IconButton>
          </Stack>
          {guest.linked_account_id && (
            <>
              <Card sx={{ minWidth: 275, margin: '20px' }}>
                <CardHeader
                  subheader={`${firstName}'s profile`}
                  sx={{ paddingBottom: 0 }}
                />
                <CardContent>{displayProfileInfo}</CardContent>
              </Card>
              {infoEditable && (
                <Card sx={{ minWidth: 275, margin: '20px' }}>
                  <CardHeader
                    subheader="You previously entered"
                    sx={{ paddingBottom: 0 }}
                  />
                  <CardContent>{PreviousHostInfo}</CardContent>
                </Card>
              )}
              <Card sx={{ minWidth: 275 }}>
                <CardHeader
                  subheader="Visible only to you"
                  sx={{ paddingBottom: 0 }}
                />
                <CardContent>
                  <div>
                    {infoEditable ? (
                      <Grid container spacing={1}>
                        <Grid item key="tag-select">
                          <EditableTags
                            setGuestTags={setGuestTags}
                            orbitTags={orbitTags}
                            currentTags={currentTags}
                            dispatchTagRef={dispatchTagRef}
                            handleNew={handleNew}
                            handleNewDisplay={handleNewDisplay}
                          />
                        </Grid>
                        <Grid item xs={12} sm={8} key="tag-list">
                          {renderDisplayTags(guestTags)}
                        </Grid>
                      </Grid>
                    ) : (
                      displayTags
                    )}
                  </div>
                </CardContent>
              </Card>
              {guest && infoEditable && (
                <Stack direction="row">
                  <Box sx={{ flexGrow: 1 }} />
                  <PeskyButton
                    variant="secondary"
                    onClick={handleCancelEditableGuestInfo}
                  >
                    Cancel
                  </PeskyButton>
                  <Box p="2px" />
                  <PeskyButton variant="primary" onClick={handleSubmit}>
                    Save
                  </PeskyButton>
                </Stack>
              )}
            </>
          )}

          {!guest.linked_account_id && (
            <div>
              {infoEditable && !guest.linked_account_id
                ? editableGuestInfo
                : displayGuestInfo}
              <Box pt={2} />
              {guest && infoEditable && (
                <Stack direction="row">
                  <Box sx={{ flexGrow: 1 }} />
                  <Button
                    variant="outlined"
                    onClick={handleCancelEditableGuestInfo}
                  >
                    Cancel
                  </Button>
                  <Box p="2px" />
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={
                      firstName === '' ||
                      lastName === '' ||
                      email === '' ||
                      !email.includes('@') ||
                      email.includes(' ')
                    }
                  >
                    Save
                  </Button>
                </Stack>
              )}
            </div>
          )}
          {!infoEditable && (
            <Stack direction="column">
              <Stack direction="row">
                {!deleteExpanded && (
                  <PeskyButton
                    variant="secondary"
                    onClick={() => setDeleteExpanded(true)}
                  >
                    Remove Guest
                  </PeskyButton>
                )}
                <Box sx={{ flexGrow: 1 }} />
              </Stack>
              <Collapse
                in={deleteExpanded}
                onEntered={() => {
                  if (removeContactRef.current) {
                    removeContactRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'end',
                    });
                  }
                }}
              >
                <Card sx={{ minWidth: 275 }}>
                  <DialogTitle textAlign="center">
                    <div>
                      <WarningIcon />
                    </div>
                    <div>Remove guest?</div>
                  </DialogTitle>
                  <DialogContent>
                    <Stack spacing={2}>
                      <Typography variant="body1">
                        {`Are you sure you want to remove ${firstName || ''}?`}
                      </Typography>
                      <Typography variant="body2">
                        If this guest is invited to upcoming gatherings, they
                        will be uninvited.
                      </Typography>
                    </Stack>
                  </DialogContent>
                  <DialogActions>
                    <PeskyButton
                      variant="secondary"
                      onClick={() => deleteGuest()}
                    >
                      Remove Guest
                    </PeskyButton>
                    <PeskyButton
                      variant="primary"
                      onClick={() => setDeleteExpanded(false)}
                    >
                      Cancel
                    </PeskyButton>
                  </DialogActions>
                </Card>
              </Collapse>
              <div ref={removeContactRef} />
            </Stack>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
