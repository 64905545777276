import { useEffect, useState, useCallback } from 'react';
import * as React from 'react';

import {
  Box,
  Drawer,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Tab,
  Tabs,
} from '@mui/material';

import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import CloseIcon from '@mui/icons-material/Close';
import KeyIcon from '@mui/icons-material/Key';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { useAccount } from '../../contexts/AccountContext';
import { UserEmail } from '../../models/Account';
import { Option, Some, None } from '../../types/Option';
import { useRequiredAuthenticatedClient } from '../../providers/AuthenticatedClientProvider';
import { Result } from '../../types/Result';
import { Data } from '../../types/Data';
import ErrorResponse from '../../types/ErrorResponse';
import { useSession } from '../../contexts/Session';
import { LoginToken } from '../../models/LoginToken';

import ContactInformationContent from './ContactInformationContent';
import ManagePassword from './ManagePassword';
import ProfileContent from './ProfileContent';
import SettingsDialogHeader from './SettingsDialogHeader';
import DialogSaveBar from './SettingsDialogSaveBar';
import TabPanel from './TabPanel';

const drawerWidth = 191;

type Props = {
  open: boolean;
  onClose: () => void;
};

const tabs = [
  {
    key: 'profile',
    header: 'Profile',
    icon: <AccountCircle />,
  },
  {
    key: 'contacts',
    header: 'Contact Information',
    icon: <MailIcon />,
  },
  {
    key: 'password',
    header: 'Password',
    icon: <KeyIcon />,
  },
];

type SavedProfile = {
  firstName: string;
  lastName: string;
  primaryEmail: string;
  emails: UserEmail[];
  timezone: string;
};

type UpdatePassword = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export default function SettingsDialog({ open, onClose }: Props) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { setAuthTokens } = useSession();
  const [editingEmails, setEditingEmails] = useState(false);
  const { account, updateAccount } = useAccount();
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [formPassword, setFormPassword] = useState<UpdatePassword>({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openSettings, setOpenSettings] = useState(!fullScreen);
  const client = useRequiredAuthenticatedClient();

  const handleBackSettings = useCallback(() => {
    setOpenSettings(true);
  }, []);

  const handleClose = useCallback(() => {
    onClose();
    setValue(0);
    setOpenSettings(false);
    setErrorMsg('');
    setSuccessMsg('');
  }, [onClose]);

  const { primary_email: primaryEmail, emails } = account.some
    ? account.value
    : {
        primary_email: null,
        emails: null,
      };

  const otherEmails = emails
    ? emails.filter((email) => email.email !== primaryEmail)
    : null;

  const [newEmails, setNewEmails] = useState(otherEmails);

  const savedProfileOpt: Option<SavedProfile> = account.some
    ? Some({
        firstName: account.value.first_name,
        lastName: account.value.last_name,
        primaryEmail: account.value.primary_email,
        emails: account.value.emails,
        timezone: account.value.timezone,
      })
    : None();

  const [formProfileOpt, setFormProfile] = useState(savedProfileOpt);

  useEffect(() => {
    if (formProfileOpt.some === false && savedProfileOpt.some === true) {
      setFormProfile(savedProfileOpt);
    }
  }, [savedProfileOpt, formProfileOpt]);

  if (!formProfileOpt.some || !savedProfileOpt.some) {
    return <Box />;
  }

  const formProfile = formProfileOpt.value;
  const savedProfile = savedProfileOpt.value;

  const onCancelClick = () => {
    formProfile.firstName = savedProfile.firstName;
    formProfile.lastName = savedProfile.lastName;
    formProfile.timezone = savedProfile.timezone;
    setFormProfile(Some(formProfile));
    setFormPassword({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
    setErrorMsg('');
    setSuccessMsg('');
    setNewEmails(otherEmails);
    setEditingEmails(false);
  };

  const onSaveClick = () => {
    // if on password tab, save new password
    if (value === 2) {
      // update the password
      client
        .ch_pwd({
          primary_email: savedProfile.primaryEmail,
          old_password: formPassword.currentPassword,
          new_password: formPassword.newPassword,
        })
        .then((result: Result<Data<LoginToken>, ErrorResponse>) => {
          if (result.ok) {
            // update login tokens in session
            setAuthTokens({
              accessToken: result.value.data.access_token,
              accessTokenExpiration: result.value.data.access_token_expiration,
              refreshToken: result.value.data.refresh_token,
              refreshTokenExpiration:
                result.value.data.refresh_token_expiration,
            });
            setFormPassword({
              currentPassword: '',
              newPassword: '',
              confirmPassword: '',
            });
            setSuccessMsg('Password updated');
          } else {
            setErrorMsg(result.error.message);
          }
        });
    } else {
      // the other tabs use save account changes
      client
        .save_account({
          first_name: formProfile.firstName.trim(),
          last_name: formProfile.lastName.trim(),
          emails: newEmails
            ? [
                formProfile.primaryEmail,
                ...newEmails.map((user_email) => user_email.email),
              ]
            : undefined,
          timezone: formProfile.timezone,
        })
        .then((result: Result<any, ErrorResponse>) => {
          if (result.ok) {
            updateAccount();
            setErrorMsg('');
            setEditingEmails(false);
            setSuccessMsg('Account updated');
          } else {
            setErrorMsg(result.error.message);
          }
        })
        .catch(() => {
          setErrorMsg('An Unknown error occurred');
        });
    }
  };

  const onRequestContactVerify = (email: string) => {
    client
      .contacts_verify_request(email)
      .then((result: Result<any, ErrorResponse>) => {
        if (result.ok) {
          setErrorMsg('');
          setSuccessMsg('Contact verification request sent');
        } else {
          setErrorMsg(result.error.message);
        }
      })
      .catch(() => {
        setErrorMsg('An Unknown error occurred');
      });
  };

  const handleTabChange = (event: React.SyntheticEvent, v: number) => {
    // clear any changes on that page before switching
    onCancelClick();
    setValue(v);
    setOpenSettings(false);
    setSuccessMsg('');
  };

  function checkConditions(
    page: number,
    profile: typeof formProfile,
    emailsA: UserEmail[] | null,
    emailsB: UserEmail[] | null,
    checkPassword: typeof formPassword,
  ) {
    switch (value) {
      case 0:
        return (
          profile.firstName !== '' &&
          profile.lastName !== '' &&
          profile.timezone !== ''
        );
      case 1:
        return emailsA !== emailsB;
      case 2:
        return (
          checkPassword.confirmPassword !== '' &&
          checkPassword.newPassword !== '' &&
          checkPassword.currentPassword !== '' &&
          checkPassword.newPassword === checkPassword.confirmPassword
        );
      // Add more cases if needed
      default:
        return false;
    }
  }

  const formReady = checkConditions(
    value,
    formProfile,
    newEmails,
    otherEmails,
    formPassword,
  );

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: { width: '100%', height: { xs: '100%', sm: '80%' } },
      }}
      fullScreen={fullScreen}
    >
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          display: { xs: 'block', sm: 'flex' },
        }}
      >
        <Drawer
          sx={{
            display: { xs: 'block', sm: 'none', width: '100%' },
            width: drawerWidth,
            flexShrink: 1,
            zIndex: 9999,
          }}
          PaperProps={{
            style: {
              position: 'absolute',
              backgroundColor: theme.palette.background.default,
              width: '100%',
            },
          }}
          open={openSettings}
          variant="persistent"
        >
          <Stack direction="row">
            <DialogTitle textAlign="center">
              <div>Settings</div>
            </DialogTitle>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              color="inherit"
              aria-label="settings open"
              onClick={() => setOpenSettings(false)}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabChange}
            sx={{ borderRight: 1, borderColor: 'divider', flexShrink: 0 }}
            centered={false}
          >
            <Tab
              icon={<AccountCircle />}
              iconPosition="start"
              label="Profile"
            />
            <Tab
              icon={<MailIcon />}
              iconPosition="start"
              label="Contact Information"
            />
            <Tab icon={<KeyIcon />} iconPosition="start" label="Password" />
          </Tabs>
        </Drawer>
        <Stack direction="column">
          <DialogTitle
            sx={{
              paddingTop: { sm: '10px' },
              paddingLeft: { sm: '10px' },
              display: { xs: 'none', sm: 'block' },
            }}
          >
            Settings
          </DialogTitle>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabChange}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              flexShrink: 0,
              display: { xs: 'none', sm: 'block' },
              width: drawerWidth,
            }}
          >
            <Tab
              icon={<AccountCircle />}
              iconPosition="start"
              label="Profile"
            />
            <Tab
              icon={<MailIcon />}
              iconPosition="start"
              label="Contact Information"
            />
            <Tab icon={<KeyIcon />} iconPosition="start" label="Password" />
          </Tabs>
        </Stack>
        <Stack
          direction="column"
          height="100%"
          sx={{ flexGrow: 1 }}
          bgcolor="background.paper"
        >
          <SettingsDialogHeader
            hidden={!openSettings}
            headerText={tabs[value].header}
            onBackClick={handleBackSettings}
            onCloseClick={handleClose}
          />
          <Box sx={{ flexGrow: 1 }}>
            <TabPanel value={value} index={0}>
              <ProfileContent
                firstName={formProfile.firstName}
                lastName={formProfile.lastName}
                timezone={formProfile.timezone}
                onChange={({ firstName, lastName, timezone }) => {
                  formProfile.firstName = firstName;
                  formProfile.lastName = lastName;
                  formProfile.timezone = timezone;
                  setFormProfile(Some(formProfile));
                }}
                errorMsg={errorMsg}
                successMsg={successMsg}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ContactInformationContent
                primaryEmail={primaryEmail}
                otherEmails={newEmails}
                onChange={(updatedEmails) => {
                  setNewEmails(updatedEmails);
                  setEditingEmails(true);
                }}
                onRequestContactVerify={onRequestContactVerify}
                successMsg={successMsg}
                errorMsg={errorMsg}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ManagePassword
                currentPassword={formPassword.currentPassword}
                newPassword={formPassword.newPassword}
                confirmPassword={formPassword.confirmPassword}
                onChange={({
                  currentPassword,
                  newPassword,
                  confirmPassword,
                }) => {
                  setFormPassword({
                    currentPassword,
                    newPassword,
                    confirmPassword,
                  });
                }}
                successMsg={successMsg}
                errorMsg={errorMsg}
              />
            </TabPanel>
          </Box>
          <DialogSaveBar
            onCancelClick={onCancelClick}
            onSaveClick={onSaveClick}
            saveDisabled={!formReady}
            hidden={
              formProfile.firstName === savedProfile.firstName &&
              formProfile.lastName === savedProfile.lastName &&
              formProfile.timezone === savedProfile.timezone &&
              !editingEmails &&
              !formPassword.newPassword &&
              !formPassword.currentPassword
            }
          />
        </Stack>
      </Box>
    </Dialog>
  );
}
