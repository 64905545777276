import moment from 'moment';

import { GatheringInvitation, GroupedGatherings } from './state';

export default function futureGroupGatherings(
  gatheringInvites: GatheringInvitation[],
): GroupedGatherings[] {
  const groupedGatherings: GroupedGatherings[] = [];
  let cur: GroupedGatherings = { heading: '', gatherings: [] };
  // Group upcoming gatherings and invitations under headers
  // Groups:
  //   Today
  //   Tomorrow
  //   Weekday - each day with gathering for next 2-5 days
  //   Month Year - each month with gathering for remaining gatherings
  //
  cur = { heading: 'Today', gatherings: [] };
  let i = 0;
  // Gatherings today
  while (i < gatheringInvites.length) {
    if (
      moment(gatheringInvites[i].sortIndex).endOf('day').format('MMMDDYYYY') ===
      moment().endOf('day').format('MMMDDYYYY')
    ) {
      // Gathering is today
      cur.gatherings.push(gatheringInvites[i]);
      i += 1;
    } else {
      if (cur.gatherings.length > 0) {
        groupedGatherings.push(cur);
      }
      break;
    }

    // if we have reached the end of the gatherings add current group
    if (i === gatheringInvites.length) {
      groupedGatherings.push(cur);
    }
  }
  // Gatherings tomorrow
  cur = { heading: 'Tomorrow', gatherings: [] };
  while (i < gatheringInvites.length) {
    if (
      moment(gatheringInvites[i].sortIndex).endOf('day').format('MMMDDYYYY') ===
      moment().add(1, 'day').endOf('day').format('MMMDDYYYY')
    ) {
      cur.gatherings.push(gatheringInvites[i]);
      i += 1;
    } else {
      if (cur.gatherings.length > 0) {
        groupedGatherings.push(cur);
      }
      break;
    }

    // if we have reached the end of the gatherings add current group
    if (i === gatheringInvites.length) {
      groupedGatherings.push(cur);
    }
  }
  // Gatherings in the next 2 to 5 days
  let j: number;
  for (j = 2; j <= 5; j += 1) {
    // Check each of the 2nd to 5th day
    const curDay = moment().add(j, 'day').endOf('day');
    cur = {
      heading: curDay.format('dddd'),
      gatherings: [],
    };
    while (i < gatheringInvites.length) {
      if (
        moment(gatheringInvites[i].sortIndex)
          .endOf('day')
          .format('MMMDDYYYY') === curDay.format('MMMDDYYYY')
      ) {
        // Gathering is on the day being checked
        cur.gatherings.push(gatheringInvites[i]);
        i += 1;
      } else {
        if (cur.gatherings.length > 0) {
          // Add a group if the group has gatherings
          groupedGatherings.push(cur);
        }
        break;
      }

      // if we have reached the end of the gatherings add current group
      if (i === gatheringInvites.length) {
        groupedGatherings.push(cur);
      }
    }
  }
  // Remaining gatherings - group by month and year
  cur = { heading: '', gatherings: [] };
  while (i < gatheringInvites.length) {
    if (cur.heading === '') {
      // Current group has been reset
      // Create a new group
      cur.heading = moment(gatheringInvites[i].sortIndex).format('MMMM YYYY');
    }
    if (
      moment(gatheringInvites[i].sortIndex).format('MMMM YYYY') === cur.heading
    ) {
      // Add to current group if in the same month and year
      cur.gatherings.push(gatheringInvites[i]);
      i += 1;
    } else {
      groupedGatherings.push(cur);
      // Reset current grouped gatherings
      cur = { heading: '', gatherings: [] };
    }

    // if we have reached the end of the gatherings add current group
    if (i === gatheringInvites.length) {
      groupedGatherings.push(cur);
    }
  }

  return groupedGatherings;
}
