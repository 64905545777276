import { Container, Stack, Typography, Paper, Box } from '@mui/material';

import logo from '../../assets/logo.svg';

export default function ContactVerifySuccess() {
  const ExplainerBox = (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Box mb={1}>
        <Typography variant="h5">Success</Typography>
      </Box>
      <Typography variant="body1">
        Your new email was successfully verified.
      </Typography>
      <Box mt={2}>
        <Typography variant="h5">How it works</Typography>
      </Box>
      <Typography variant="body1">
        Other users can add your new email to their contacts and it will
        automatically link to your account.
      </Typography>
      <Box mt={2} mb={1}>
        <Typography variant="h5">What to do next</Typography>
      </Box>
      <Typography variant="body1">
        Visit your settings to edit your contacts.
      </Typography>
    </Paper>
  );

  const renderRegistrationSuccess = (
    <Stack spacing={1}>
      <img src={logo} className="App-logo" alt="logo" />
      <Typography variant="h5" component="div" sx={{ textAlign: 'center' }}>
        Verification Successful
      </Typography>
      {ExplainerBox}
    </Stack>
  );

  return (
    <Container>
      <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
        {renderRegistrationSuccess}
      </Container>
    </Container>
  );
}
