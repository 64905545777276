import { Invitation } from '../../../models/Invitation';
import { ListedGathering } from '../../../models/ListedGathering';
import ErrorResponse from '../../../types/ErrorResponse';

export type FilterUpdate = { type: 'search'; search: string };

export type MyHomeAction =
  | {
      type: 'loaded';
      invitations: Invitation[];
      gatherings: ListedGathering[];
    }
  | {
      type: 'filter';
      update: FilterUpdate;
    }
  | { type: 'error'; error: ErrorResponse };

export const MyHomeFilterSearch = (search: string): MyHomeAction => ({
  type: 'filter',
  update: { type: 'search', search },
});
