import * as React from 'react';
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Drawer,
  Divider,
  FormControlLabel,
  Toolbar,
  List,
  Typography,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Skeleton,
} from '@mui/material';

import useMediaQuery from '@mui/material/useMediaQuery';

import {
  Menu as MenuIcon,
  Event as EventIcon,
  Groups as GroupsIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';

import logo from '../assets/logo.svg';
import logoLightMode from '../assets/logo-light-mode.svg';
import { useDarkMode } from '../providers/ThemeProvider';
import LoadingPage from './LoadingPage';

import { buildLogoutUrl } from '../config';

const smallScreenProp = 'md';
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(!useMediaQuery(theme.breakpoints.down(smallScreenProp)) &&
    open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `${drawerWidth}px`,
    }),
}));

export default function PeskyNavigationSkeleton() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [profileAnchorEl, setProfileAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const profileMenuId = 'profile-menu';
  const [darkMode, setDarkMode] = useDarkMode();
  const smallScreen = useMediaQuery(theme.breakpoints.down(smallScreenProp));
  const [delayed, setDelayed] = React.useState(true);

  function StartingIndex() {
    const sampleLocation = useLocation();

    switch (sampleLocation.pathname) {
      case '/app/':
        return 1;
      case '/app/gatherings':
        return 2;
      case '/app/contacts':
        return 3;
      default:
        return 0;
    }
  }

  React.useEffect(() => {
    const timeout = setTimeout(() => setDelayed(false), 500);
    return () => clearTimeout(timeout);
  }, []);

  const startingLocation = StartingIndex();
  const [selectedIndex, setSelectedIndex] = React.useState(startingLocation);

  // Profile menu (right side)
  const isProfileMenuOpen = Boolean(profileAnchorEl);

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileAnchorEl(event.currentTarget);
  };
  const handleProfileMenuSignOut = () => {
    handleProfileMenuClose();
    navigate(buildLogoutUrl());
  };

  const renderProfileMenu = (
    <Menu
      anchorEl={profileAnchorEl}
      id={profileMenuId}
      keepMounted
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem>
        <FormControlLabel
          control={
            <Switch
              checked={darkMode}
              onChange={() => setDarkMode(!darkMode)}
            />
          }
          label="Dark mode"
        />
      </MenuItem>
      <Divider />
      <Divider />
      <MenuItem onClick={handleProfileMenuSignOut}>
        <ListItemIcon sx={{ paddingRight: '22px' }}>
          <LogoutIcon />
        </ListItemIcon>
        <ListItemText>Sign Out</ListItemText>
      </MenuItem>
    </Menu>
  );

  // Persistent app drawer
  // if small screen default to closed, otherwise default to open
  const [open, setOpen] = React.useState(!smallScreen);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const clickLogo = () => {
    setSelectedIndex(1);
    navigate('/app/');
  };

  const appBarContent = (
    <Toolbar>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerOpen}
        edge="start"
        sx={{ mr: 2, ...(open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={handleDrawerClose}
        edge="start"
        sx={{ mr: 2, ...(!open && { display: 'none' }) }}
      >
        <MenuIcon />
      </IconButton>
      <Button onClick={clickLogo} sx={{ ml: 2, textTransform: 'none' }}>
        <img
          src={darkMode ? logo : logoLightMode}
          className="App-logo"
          alt="logo"
        />
        <Box sx={{ mr: 2 }} />
        <Typography
          variant="h5"
          noWrap
          color="white"
          fontFamily={['Concert One', 'sans-serif'].join(',')}
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          Pesky Planet
        </Typography>
      </Button>
      <Box sx={{ flexGrow: 1 }} />
      <Button
        onClick={handleProfileMenuOpen}
        color="inherit"
        size="small"
        sx={{ mr: 0.5, pl: 1.5, pr: 1.5, textTransform: 'none' }}
      >
        <Typography
          variant="h6"
          display={{ xs: 'none', sm: 'block' }}
          sx={{ mr: 2 }}
        >
          <Skeleton width={128} />
        </Typography>
        <Avatar>
          <Skeleton width={1} />
        </Avatar>
      </Button>
    </Toolbar>
  );

  const drawerContent = (
    <>
      <Toolbar />
      <Box height="100%">
        <List>
          <ListItem key="gatherings" disablePadding>
            <ListItemButton selected={selectedIndex === 2}>
              <ListItemIcon>
                <EventIcon />
              </ListItemIcon>
              <ListItemText
                primary="Gatherings"
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="contacts" disablePadding>
            <ListItemButton selected={selectedIndex === 3}>
              <ListItemIcon>
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Contacts"
                primaryTypographyProps={{ variant: 'h6' }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );

  return (
    <Box height="100%">
      <AppBar
        sx={{
          position: 'fixed',
          width: '100%',
          zIndex: theme.zIndex.drawer + 1,
        }}
      >
        {appBarContent}
        {renderProfileMenu}
      </AppBar>
      <Drawer
        sx={{
          display: { xs: 'none', [smallScreenProp]: 'block' },
          width: drawerWidth,
          flexShrink: 1,
          border: 'none',
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRight: 'none',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {drawerContent}
      </Drawer>
      <Drawer
        sx={{
          display: { xs: 'block', [smallScreenProp]: 'none' },
          width: drawerWidth,
          flexShrink: 1,
          border: 'none',
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            borderRight: 'none',
          },
        }}
        variant={smallScreen ? 'temporary' : 'persistent'}
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      >
        {drawerContent}
      </Drawer>
      <Box pt={8} sx={{ flexGrow: 1, height: '100%' }}>
        <Main open={open}>
          <Container
            disableGutters
            maxWidth={false}
            sx={{ marginLeft: 0, height: '100%' }}
          >
            {!delayed && <LoadingPage />}
          </Container>
        </Main>
      </Box>
    </Box>
  );
}
