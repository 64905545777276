import { Box, Stack, Typography } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleIcon from '@mui/icons-material/Schedule';
import WarningIcon from '@mui/icons-material/Warning';

import GuestConstraintsSatisfied from '../models/GuestConstraintsSatisfied';

export type GatheringStatusProps = {
  status: GuestConstraintsSatisfied;
  cancelled?: boolean;
  short?: boolean;
};

export default function GatheringStatus({
  status,
  cancelled,
  short,
}: GatheringStatusProps) {
  if (short) {
    if (cancelled) {
      return (
        <Stack direction="row" spacing={0.5}>
          <CancelIcon fontSize="medium" sx={{ color: 'error.main', mr: 0.5 }} />
          <Typography variant="body1">Cancelled</Typography>
        </Stack>
      );
    }

    switch (status) {
      case GuestConstraintsSatisfied.Met:
        return (
          <Stack direction="row">
            <CheckIcon
              fontSize="medium"
              sx={{ color: 'success.main', mr: 0.5 }}
            />
            <Typography variant="body1">Happening</Typography>
          </Stack>
        );
      case GuestConstraintsSatisfied.NotMet:
        return (
          <Stack direction="row" spacing={0.5}>
            <ScheduleIcon
              fontSize="medium"
              sx={{ color: 'info.main', mr: 0.5 }}
            />
            <Typography variant="body1">Pending</Typography>
          </Stack>
        );
      default:
        return (
          <Stack direction="row" spacing={0.5}>
            <WarningIcon
              fontSize="medium"
              sx={{ color: 'warning.main', mr: 0.5 }}
            />
            <Typography variant="body1">Pending</Typography>
          </Stack>
        );
    }
  }

  if (cancelled) {
    return (
      <Box
        bgcolor="error.main"
        px={1}
        sx={{ borderRadius: 2, width: 'fit-content' }}
      >
        <Stack direction="row" spacing={0.5}>
          <CancelIcon
            fontSize="medium"
            sx={{ color: 'primary.contrastText', mr: 0.5 }}
          />
          <Typography variant="body1" sx={{ color: 'primary.contrastText' }}>
            This gathering has been cancelled
          </Typography>
        </Stack>
      </Box>
    );
  }

  switch (status) {
    case GuestConstraintsSatisfied.Met:
      return (
        <Box
          bgcolor="success.main"
          px={1}
          sx={{ borderRadius: 2, width: 'fit-content' }}
        >
          <Stack direction="row">
            <CheckIcon
              fontSize="medium"
              sx={{ color: 'background.default', mr: 0.5 }}
            />
            <Typography variant="body1" sx={{ color: 'background.default' }}>
              This gathering has received enough responses to occur
            </Typography>
          </Stack>
        </Box>
      );
    case GuestConstraintsSatisfied.NotMet:
      return (
        <Box
          bgcolor="info.main"
          px={1}
          sx={{ borderRadius: 2, width: 'fit-content' }}
        >
          <Stack direction="row" spacing={0.5}>
            <ScheduleIcon
              fontSize="medium"
              sx={{ color: 'background.default', mr: 0.5 }}
            />
            <Typography variant="body1" sx={{ color: 'background.default' }}>
              This gathering still needs a few more acceptances to occur
            </Typography>
          </Stack>
        </Box>
      );
    default:
      return (
        <Box
          bgcolor="warning.main"
          px={1}
          sx={{ borderRadius: 2, width: 'fit-content' }}
        >
          <Stack direction="row" spacing={0.5}>
            <WarningIcon
              fontSize="medium"
              sx={{ color: 'primary.contrastText', mr: 0.5 }}
            />
            <Typography variant="body1" sx={{ color: 'primary.contrastText' }}>
              This gathering may not reach its minimum guest requirement
            </Typography>
          </Stack>
        </Box>
      );
  }
}

GatheringStatus.defaultProps = { short: false, cancelled: false };
