import { Invitation } from '../../../models/Invitation';
import { ListedGathering } from '../../../models/ListedGathering';
import ErrorResponse from '../../../types/ErrorResponse';

export type FilterToggle =
  | 'pendingGatherings'
  | 'happeningGatherings'
  | 'cancelledGatherings'
  | 'acceptedInvitations'
  | 'declinedInvitations'
  | 'hostingGatherings';

export type FilterUpdate =
  | { type: 'toggle'; update: { [k: string]: boolean } }
  | { type: 'search'; search: string };

export type MyGatheringsAction =
  | {
      type: 'loaded';
      invitations: Invitation[];
      gatherings: ListedGathering[];
    }
  | {
      type: 'past-loaded';
      invitations: Invitation[];
      gatherings: ListedGathering[];
    }
  | {
      type: 'future-loaded';
      invitations: Invitation[];
      gatherings: ListedGathering[];
    }
  | {
      type: 'filter';
      update: FilterUpdate;
    }
  | { type: 'error'; error: ErrorResponse };

export const MyGatheringsFilterToggle = (update: {
  [k: string]: boolean;
}): MyGatheringsAction => ({
  type: 'filter',
  update: { type: 'toggle', update },
});

export const MyGatheringsFilterSearch = (
  search: string,
): MyGatheringsAction => ({
  type: 'filter',
  update: { type: 'search', search },
});
