import { Box, Button, Divider, Stack } from '@mui/material';

type Props = {
  hidden: boolean;
  onCancelClick: () => void;
  onSaveClick: () => void;
  saveDisabled: boolean;
};

export default function DialogSaveBar({
  hidden,
  onCancelClick,
  onSaveClick,
  saveDisabled,
}: Props) {
  return (
    <Box hidden={hidden}>
      <Divider />
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={2}
        sx={{ padding: '8px', paddingRight: '16px' }}
      >
        <Button onClick={onCancelClick}>Cancel</Button>
        <Button
          variant="contained"
          disabled={saveDisabled}
          onClick={onSaveClick}
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}
