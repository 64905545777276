import { useNavigate } from 'react-router-dom';
import { Button, Container, Stack, Typography } from '@mui/material';

import { buildLogoutUrl } from '../config';

type NavButtonProps = { nav: 'back' | 'home' };

function NavButton({ nav }: NavButtonProps) {
  const navigate = useNavigate();

  switch (nav) {
    case 'back':
      return (
        <Button
          variant="outlined"
          onClick={() => {
            navigate(-1);
          }}
        >
          Go back
        </Button>
      );
    default:
      return (
        <Button
          variant="outlined"
          onClick={() => {
            navigate('/');
          }}
        >
          Home
        </Button>
      );
  }
}

function variantToString(variant: string) {
  switch (variant) {
    case 'invite':
      return 'invitation';
    case 'gathering':
      return 'gathering';
    case 'orbit':
      return 'guest list';
    default:
      return null;
  }
}

export type ApiErrorProps = {
  errorCode: number | undefined;
  variant: 'invite' | 'gathering' | 'orbit';
  nav: 'back' | 'home';
};

function ApiError(props: ApiErrorProps) {
  const { errorCode, variant, nav } = props;
  const navigate = useNavigate();

  switch (errorCode) {
    case 404:
      return (
        <Container>
          <Typography variant="h5" component="div">
            We couldn&apos;t find your {variantToString(variant)}.
          </Typography>
          <Typography variant="h6" component="div">
            We looked everywhere, but it seems to have wandered off...
          </Typography>
          <Typography variant="body1" component="div">
            [404 invitation placeholder]
          </Typography>
          <NavButton nav={nav} />
        </Container>
      );
    case 403:
      return (
        <Container>
          <Typography variant="h5" component="div">
            This {variant === 'invite' ? 'invitation' : 'gathering'} is for
            someone else
          </Typography>
          <Typography variant="h6" component="div">
            If you think this{' '}
            {variant === 'invite' ? 'invitation' : 'gathering'} is for you, you
            may need to log out an log back in with a different account.
          </Typography>
          <Typography variant="body1" component="div">
            [403 invitation placeholder]
          </Typography>
          <Stack direction="row" spacing={2}>
            <NavButton nav={nav} />
            <Button
              variant="outlined"
              onClick={() => navigate(buildLogoutUrl())}
            >
              Sign out
            </Button>
          </Stack>
        </Container>
      );
    default:
      return (
        <Container>
          <Typography variant="h5" component="div">
            Something has gone wrong.
          </Typography>
          <Typography variant="body1" component="div">
            [400/500 invitation placeholder]
          </Typography>
          <NavButton nav={nav} />
        </Container>
      );
  }
}

export default ApiError;
