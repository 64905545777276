import React from 'react';
import Chip from '@mui/material/Chip';

// Set font color to black/white based on background color
function setFontColor(bgColor: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?(?:0x)?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex = bgColor.replace(
    shorthandRegex,
    (m, r, g, b) => r + r + g + g + b + b,
  );

  const result = /^#?(?:0x)?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (result) {
    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return r + g + b > (3 * 256) / 2 ? '#000' : '#fff';
  }
  return null;
}

export type Props = {
  label: string;
  color: string;
  onDelete?: () => void;
  margin?: number;
};
export default function TagChip(props: Props) {
  const { label, color, onDelete, margin } = props;
  const normalizedColor = color.replace('0x', '');
  return (
    <Chip
      label={label}
      key={label}
      onDelete={onDelete}
      size="small"
      sx={{
        backgroundColor: normalizedColor,
        color: setFontColor(normalizedColor),
        m: margin,
      }}
    />
  );
}

TagChip.defaultProps = { onDelete: undefined, margin: 0 };
