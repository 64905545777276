// This component is meant to display a gathering or invitation when there is
// only one on the screen.
// For displaying multiple gatherings or invitations on a screen, use
// GatheringCard.

import { useState } from 'react';
import { useTheme } from '@mui/material/styles';

import moment from 'moment-timezone';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PlaceIcon from '@mui/icons-material/Place';

import { ProfileSettings } from '../models/ProfileSettings';
import GuestConstraintsSatisfied from '../models/GuestConstraintsSatisfied';
import GatheringStatus from './GatheringStatus';
import { useAccount } from '../contexts/AccountContext';
import { AcceptedGuest } from '../models/Gathering';

export type Props = {
  variant: 'host' | 'pending_invitation' | 'accepted_invitation' | 'masked';
  date: moment.Moment | null | undefined;
  endDate?: moment.Moment | null | undefined;
  title: string;
  place: string | undefined;
  description?: string;
  hostName: string;
  profileSettings: ProfileSettings;
  gatheringStatus: GuestConstraintsSatisfied;
  cancelled: boolean;
  cancellationNote: undefined | string;
  inviteDurationMin?: number;
  finalInviteExpirationMinutes?: undefined | number;
  guests?: AcceptedGuest[] | null;
};

type DescriptionProps = {
  description: string;
  truncateLength: number;
};

function Description({ description, truncateLength }: DescriptionProps) {
  const [expanded, setExpanded] = useState(false);
  const trimmedDescription = description.trim();

  if (trimmedDescription.length <= truncateLength) {
    return (
      <Typography variant="body1" component="p" whiteSpace="pre-wrap">
        {trimmedDescription}
      </Typography>
    );
  }

  if (expanded) {
    return (
      <Typography variant="body1" component="p" whiteSpace="pre-wrap">
        {trimmedDescription}
        <Button onClick={() => setExpanded(false)} size="small" sx={{ ml: 1 }}>
          <Typography variant="caption">collapse</Typography>
        </Button>
      </Typography>
    );
  }
  return (
    <Typography variant="body1" component="p" whiteSpace="pre-wrap">
      {`${description.trim().substring(0, truncateLength)} ...`}
      <Button onClick={() => setExpanded(true)} size="small" sx={{ ml: 1 }}>
        <Typography variant="caption">read more</Typography>
      </Button>
    </Typography>
  );
}

export default function GatheringInformation(props: Props) {
  const {
    variant,
    date,
    endDate,
    title,
    place,
    description = '',
    hostName,
    profileSettings,
    gatheringStatus,
    cancelled,
    cancellationNote,
    inviteDurationMin,
    finalInviteExpirationMinutes,
    guests,
  } = props;

  const theme = useTheme();
  const { timezone } = useAccount();

  const { locale } = profileSettings;

  const displayDate = date || moment().add(1, 'day').startOf('hour');
  const localized = displayDate.locale(locale).tz(timezone);
  const dayOfWeek = localized.format('dddd');
  const yearMonthDay = localized.format('MMM D, YYYY');
  const hoursMinutes = localized.format('LT');

  const endDisplayDate = endDate || moment().add(1, 'day').startOf('hour');
  const endLocalized = endDisplayDate.locale(locale).tz(timezone);
  const endDayOfWeek = endLocalized.format('dddd');
  const endYearMonthDay = endLocalized.format('MMM D, YYYY');
  const endHoursMinutes = endLocalized.format('LT');

  const displayGuests = guests && (
    <Box sx={{ mt: 1, mb: 2, paddingBottom: 2 }}>
      <Typography variant="h6">Current guest list:</Typography>
      {guests.length > 0 && (
        <Grid container spacing={1} mt={1}>
          {guests.map((guest: AcceptedGuest) => (
            <Grid item key={guest.gathering_guest_id} xs={12}>
              <Box>
                <Stack direction="row" spacing={2} ml={1.5} alignItems="center">
                  <Avatar
                    alt={`${guest.first_name} ${guest.last_name}`}
                    sx={{ fontSize: 14, height: 24, width: 24 }}
                  >
                    {guest.first_name.substring(0, 1)}
                  </Avatar>
                  <Typography variant="body1">
                    {`${guest.first_name} ${guest.last_name}`}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
      {guests.length === 0 && (
        <Typography variant="body2">Be the first to accept!</Typography>
      )}
    </Box>
  );

  return (
    <Stack spacing={1.5}>
      <Stack>
        <Typography variant="h5" component="div">
          {title}
        </Typography>
        <time dateTime={displayDate.format()}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
            <Stack direction="row" spacing={1}>
              <Typography variant="body1" component="div">
                {variant !== 'masked' && dayOfWeek}
              </Typography>
              <Typography variant="body1" component="div">
                {yearMonthDay}
              </Typography>
              <Typography variant="body1" component="div">
                {variant !== 'masked' && hoursMinutes}
              </Typography>
            </Stack>
            {endDate && (
              <Stack direction="row" spacing={1}>
                <Typography variant="body1" component="div">
                  until {variant !== 'masked' && endDayOfWeek}
                </Typography>
                <Typography variant="body1" component="div">
                  {endYearMonthDay}
                </Typography>
                <Typography variant="body1" component="div">
                  {variant !== 'masked' && endHoursMinutes}
                </Typography>
              </Stack>
            )}
          </Stack>
        </time>
      </Stack>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={{ xs: 0.5, sm: 4 }}
      >
        <Stack direction="row" spacing={0.5}>
          <AccountCircleIcon fontSize="small" />
          <Typography variant="body1" component="div" fontWeight="bold">
            {variant === 'host' ? "You're hosting" : hostName}
          </Typography>
        </Stack>
        {place && (
          <Stack direction="row" spacing={0.5}>
            <PlaceIcon fontSize="small" />
            <Typography variant="body1" whiteSpace="pre-wrap">
              {place}
            </Typography>
          </Stack>
        )}
      </Stack>
      <GatheringStatus status={gatheringStatus} cancelled={cancelled} />
      {(variant === 'host' || variant === 'accepted_invitation') &&
        cancelled &&
        cancellationNote && (
          <Box
            width="100%"
            p={1}
            sx={{
              backgroundColor: `${theme.palette.primary.contrastText}20`,
            }}
          >
            <Typography variant="h6" display="flex" justifyContent="center">
              {variant === 'accepted_invitation' && `A note from ${hostName}:`}
              {variant === 'host' && `Your cancellation note:`}
            </Typography>
            <Typography
              variant="subtitle1"
              display="flex"
              justifyContent="center"
            >
              {cancellationNote}
            </Typography>
          </Box>
        )}
      <Box p={2} sx={{ maxWidth: '900px' }}>
        <Description description={description} truncateLength={800} />
      </Box>
      {variant === 'host' && inviteDurationMin && (
        <Typography variant="body1">
          Guest Invitation are valid for{' '}
          {inviteDurationMin > 1440
            ? (inviteDurationMin / 1440).toString()
            : (inviteDurationMin / 60).toString()}{' '}
          {inviteDurationMin > 1440 ? 'Days' : 'Hours'}.
        </Typography>
      )}
      {variant === 'host' && finalInviteExpirationMinutes && (
        <Typography variant="body1">
          Final invite will expire{' '}
          {finalInviteExpirationMinutes > 1440
            ? (finalInviteExpirationMinutes / 1440).toString()
            : (finalInviteExpirationMinutes / 60).toString()}{' '}
          {finalInviteExpirationMinutes > 1440 ? 'Days' : 'Hours'} before
          gathering.
        </Typography>
      )}
      {displayGuests}
    </Stack>
  );
}

GatheringInformation.defaultProps = {
  description: '',
  endDate: undefined,
  inviteDurationMin: 0,
  finalInviteExpirationMinutes: undefined,
  guests: null,
};
