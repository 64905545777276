import { Data } from '../types/Data';
import ErrorResponse from '../types/ErrorResponse';
import { Invitation } from '../models/Invitation';
import { Register } from '../models/Register';
import { Login } from '../models/Login';
import { LoginToken } from '../models/LoginToken';
import InvitationClient from './InvitationClient';
import { Ok, Err, Result } from '../types/Result';
import { Slice } from '../types/Slice';
import { GuestInvitationResponse } from './GuestInvitationResponse';
import {
  UpdatePassword,
  ForgotPasswordRequest,
} from '../models/UpdatePassword';

/// Gets us a type-safe json parse response
async function json<T>(res: Response): Promise<T> {
  const val: T = await res.json();
  return val;
}

export default class UnauthenticatedClient implements InvitationClient {
  protocol_version: number;

  constructor() {
    this.protocol_version = 15;
  }

  public async register(
    registration: Register,
    invitation_code?: string | null | undefined,
  ): Promise<Result<any, ErrorResponse>> {
    if (invitation_code) {
      const res = await this.post_json(
        `/api/auth/register?invitations_rid=${invitation_code}`,
        registration,
      );
      return UnauthenticatedClient.handleResponse(res, [
        200,
        async (r) => json(r),
      ]);
    }
    const res = await this.post_json(`/api/auth/register`, registration);
    return UnauthenticatedClient.handleResponse(res, [
      204,
      async () => Promise.resolve(),
    ]);
  }

  public async register_verify(
    rid: string,
  ): Promise<Result<Data<LoginToken>, ErrorResponse>> {
    const res = await this.post_json(`/api/auth/register_verify/${rid}`, null);

    return UnauthenticatedClient.handleResponse(res, [
      200,
      async (r) => json(r),
    ]);
  }

  public async login(
    login: Login,
  ): Promise<Result<Data<LoginToken>, ErrorResponse>> {
    const res = await this.post_json(`/api/auth/login`, login);

    return UnauthenticatedClient.handleResponse(res, [
      200,
      async (r) => json(r),
    ]);
  }

  public async ch_pwd(
    updatePassword: UpdatePassword,
    notificationRid: string,
  ): Promise<Result<Data<LoginToken>, ErrorResponse>> {
    const res = await this.post_json(
      `/api/auth/ch_pwd?notification_rid=${notificationRid}`,
      updatePassword,
    );

    return UnauthenticatedClient.handleResponse(res, [
      200,
      async (r) => json(r),
    ]);
  }

  public async ch_pwd_request(
    forgotPasswordRequest: ForgotPasswordRequest,
  ): Promise<Result<any, ErrorResponse>> {
    const res = await this.post_json(
      `/api/auth/ch_pwd_request`,
      forgotPasswordRequest,
    );

    return UnauthenticatedClient.handleResponse(res, [
      204,
      async () => Promise.resolve(),
    ]);
  }

  public async get_invitations(): Promise<
    Result<Slice<Invitation>, ErrorResponse>
  > {
    // work-around for interface implementations that don't use member variables
    // Basically identity(x) => x
    ((x: any) => x)(this.protocol_version);
    return Promise.resolve(Ok({ data: [] }));
  }

  public async get_invitation(
    invitation_id: number | string,
  ): Promise<Result<Data<Invitation>, ErrorResponse>> {
    return this.get(`/api/invitations/${invitation_id}`);
  }

  public async save_invitation_response(
    response: GuestInvitationResponse,
    invitation_code: number | string,
  ): Promise<Result<any, ErrorResponse>> {
    const res = await this.post_json(
      `/api/invitations/${invitation_code}`,
      response,
    );

    return UnauthenticatedClient.handleResponse(res, [
      204,
      async () => Promise.resolve(),
    ]);
  }

  private async post_json<V>(uri: string, val: V): Promise<Response> {
    return fetch(uri, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        PeskyProtocolVersion: this.protocol_version.toString(),
      },
      body: JSON.stringify(val),
    });
  }

  private async get<T>(uri: string): Promise<Result<T, ErrorResponse>> {
    const res = await fetch(uri, {
      headers: {
        PeskyProtocolVersion: this.protocol_version.toString(),
      },
    });

    return UnauthenticatedClient.handleResponse(res, [
      200,
      async (r) => json<T>(r),
    ]);
  }

  private static async handleResponse<T>(
    res: Response,
    [success_code, handler]: [number, (res: Response) => Promise<T>],
  ): Promise<Result<T, ErrorResponse>> {
    try {
      if (res.status === success_code) {
        return Ok(await handler(res));
      }

      return Err(await json<ErrorResponse>(res));
    } catch {
      return Err({ status_code: res.status, message: `Unrecognized response` });
    }
  }
}
