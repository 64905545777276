import { useState, useCallback } from 'react';

import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings';

import SettingsDialog from './SettingsDialog';

type Props = {
  onOpen: () => void;
};

export default function Settings({ onOpen }: Props) {
  const [open, setOpen] = useState(false);

  const onMenuClick = useCallback(() => {
    setOpen(true);
    onOpen();
  }, [onOpen]);

  const onDialogClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <MenuItem onClick={onMenuClick}>
        <ListItemIcon sx={{ paddingRight: '22px' }}>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText>Settings</ListItemText>
      </MenuItem>
      <SettingsDialog open={open} onClose={onDialogClose} />
    </>
  );
}
