import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
  headerText: string;
  hidden: boolean;
  onBackClick: () => void;
  onCloseClick: () => void;
};

export default function DialogHeader({
  headerText,
  hidden,
  onBackClick,
  onCloseClick,
}: Props) {
  return (
    <>
      <Grid container spacing={0} direction="row">
        <Grid item xs={1} sx={{ display: { xs: 'block', sm: 'none' } }}>
          <Stack direction="row">
            <IconButton
              color="inherit"
              aria-label="settings back"
              onClick={onBackClick}
              sx={{
                display: { xs: 'block', sm: 'none' },
                paddingTop: '16px',
                paddingLeft: '16px',
                paddingRight: '0px',
              }}
              hidden={hidden}
            >
              <ArrowBackIosNewIcon />
            </IconButton>
          </Stack>
        </Grid>
        <Grid item xs={10} sm={11}>
          <Typography
            variant="h6"
            textAlign={{ xs: 'center', sm: 'left' }}
            sx={{
              paddingTop: '10px',
              paddingLeft: { sm: '16px' },
            }}
          >
            {headerText}
          </Typography>
        </Grid>
        <Grid item xs={1} alignItems="left">
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              color="inherit"
              aria-label="settings open"
              onClick={onCloseClick}
              sx={{ padding: '10px', paddingTop: '15px' }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}
