import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useNavigate, useLocation } from 'react-router-dom';

function Congratulations() {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Container>
      <h2 className="Title">Congratulations!</h2>
      <p>
        Invitations are on their way. Here&apos;s what&apos;s going to happen
        next.
        <br />
        <br />
        We&apos;ll randomly select {location.state.requiredGuests} of your
        potential guests and send them an invite. If all of them accept, your
        gathering is complete! If any of those folks can&apos;t make it,
        we&apos;ll continue to randomly invite potential guests until your
        gathering is complete.
        <br />
        <br />
        While invitations to guests are going out, you&apos;ll only be able to
        see those guests who have accepted. Ocassionally, a guest will decline
        and provide a reason, in which case you&apos;ll get a notification.
      </p>
      <Button
        variant="contained"
        fullWidth={false}
        onClick={() =>
          navigate(`/app/gatherings/${location.state.gatheringId}`)
        }
        sx={{ margin: '2rem 0rem' }}
      >
        Back to gathering
      </Button>
    </Container>
  );
}

export default Congratulations;
